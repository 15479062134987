import { DataTableV2, FilterBar, TextEllipsis, TextInput, Tooltip } from '@dynatrace/strato-components-preview';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors } from '@dynatrace/strato-design-tokens';
import { useNavigate, useParams } from 'react-router-dom';
import { CopyIcon, EditIcon, ExternalLinkIcon, XmarkIcon } from '@dynatrace/strato-icons';
import { format, parseISO } from 'date-fns';
import { ProjectService } from '../../../../services/ProjectService';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../../utils/Notifications';
import { LoggingService } from '../../../../services/LoggingService';

const VerticalLine = styled.div`
  background-color: ${Colors.Border.Neutral.Default};
  width: 2px;
  height: 24px;
  vertical-align: middle;
  align-content: center;
`;

const RequestsUsers = ({ projectRequestsApiData, handleNavigationPath, openRequestReminderModal }: any) => {
  // eslint-disable-next-line
  const [copiedRequestId, setCopiedRequestId] = useState<number | null>(null);
  const { RequestId, ProjectId } = useParams();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState<string>('');
  // eslint-disable-next-line
  const [selectedRowsInfo, setSelectedRowsInfo] = useState<any>([]);

  const filterData = (data: any[], query: string) => {
    if (!query) return data;
    return data.filter((item) => {
      return Object.values(item).some(
        (value) => typeof value === 'string' && value.toLowerCase().includes(query.toLowerCase()),
      );
    });
  };

  function showProjectDetails(requestData: any) {
    navigate(`/projects/project/${ProjectId}/request/${RequestId}/people/${requestData.id}`);
  }

  const filteredAssignedUsers = filterData(
    projectRequestsApiData?.filter((item: any) => item.projectRequestId === Number(RequestId))[0]?.assignedList || [],
    searchQuery,
  );

  const handleCopy = async (projectRequestUrl: string, rowId: number) => {
    const baseUrl = window.location.origin;
    const fullUrl = `${baseUrl}/upload/${projectRequestUrl}`;
    await navigator.clipboard.writeText(fullUrl);
    setCopiedRequestId(rowId);
    setTimeout(() => setCopiedRequestId(null), 2000);
  };

  const handleRedirect = async (projectRequestUrl: string, rowId: number) => {
    const baseUrl = window.location.origin;
    const fullUrl = `${baseUrl}/upload/${projectRequestUrl}`;
    window.open(fullUrl, '_blank');
  };

  const handleOpenUserFiles = async (requestRow: any) => {
    await navigate(`request/${RequestId}/people/${requestRow.peopleId}`);
    if (RequestId) {
      handleNavigationPath(requestRow, 'user-data');
    }
  };

  const RowColumnsWithActions = (onProjectUserClick: (requestRow: any) => void) => [
    {
      id: 'assignedto',
      header: 'Assigned to',
      accessor: 'assignedto',
      width: { type: 'auto', minWidth: 300 },
      columnType: 'text',
      cell: ({ rowData }: any) => {
        return (
          <DataTableV2.DefaultCell>
            <Tooltip placement='bottom' text={rowData.assignedto}>
              <TextEllipsis
                onClick={() => {
                  onProjectUserClick(rowData);
                }}
                truncationMode='end'
                style={{
                  cursor: 'pointer',
                }}
              >
                {rowData.assignedto}
              </TextEllipsis>
            </Tooltip>
          </DataTableV2.DefaultCell>
        );
      },
    },
    {
      id: 'updateDate',
      header: 'Last submittal',
      accessor: 'documents',
      width: { type: 'auto', minWidth: 200 },
      columnType: 'text',
      cell: ({ rowData }: any) => {
        const documents = rowData?.documents || [];

        const latestModifiedOn = documents.reduce((latest: string | null, doc: any) => {
          const docDate = doc?.updateDate ? new Date(doc.updateDate) : null;
          return docDate && (!latest || docDate > new Date(latest)) ? doc.updateDate : latest;
        }, null);

        return (
          <DataTableV2.DefaultCell>
            <TextEllipsis
              onClick={() => {
                onProjectUserClick(rowData);
              }}
              truncationMode='end'
              style={{
                cursor: 'pointer',
              }}
            >
              {latestModifiedOn ? format(parseISO(latestModifiedOn), 'MMM dd, yyyy hh:mm a') : 'Never'}
            </TextEllipsis>
          </DataTableV2.DefaultCell>
        );
      },
    },
    {
      id: 'pendingFilesCount',
      header: 'Awaiting Approval',
      accessor: 'pendingFilesCount',
      width: { type: 'auto', minWidth: 200 },
      columnType: 'number',
      cell: ({ rowData }: any) => {
        const pendingCount = rowData.documents.filter((doc: any) => doc.statusName === 'Pending Approval').length;
        return (
          <DataTableV2.DefaultCell>
            <TextEllipsis
              onClick={() => {
                onProjectUserClick(rowData);
              }}
              truncationMode='end'
              style={{
                cursor: 'pointer',
              }}
            >
              {`${pendingCount}/${rowData.documents.length}`}
            </TextEllipsis>
          </DataTableV2.DefaultCell>
        );
      },
    },
  ];

  const searchElement = (
    <Flex flexDirection='row' paddingRight={8} justifyContent='center' alignContent='center' alignItems='center'>
      <FilterBar onFilterChange={() => {}}>
        <FilterBar.Item name={'search'} label={''}>
          <TextInput placeholder={'Search'} onChange={setSearchQuery} value={searchQuery}>
            <TextInput.Suffix>
              <TextInput.Button
                aria-label='clear'
                onClick={() => {
                  setSearchQuery('');
                }}
              >
                <XmarkIcon />
              </TextInput.Button>
            </TextInput.Suffix>
          </TextInput>
        </FilterBar.Item>
      </FilterBar>
    </Flex>
  );

  const handleResend = async (item: any) => {
    const projectRequestData = projectRequestsApiData?.filter(
      (item: any) => item.projectRequestId === Number(RequestId),
    )[0];
    const userData = item;

    const payloadData = {
      uploadLink: userData.projectRequestUrl,
      RequestName: projectRequestData.projectRequestName,
      RequestDescription: projectRequestData.projectRequestDescription || 'No description provided',
      ToEmail: userData.sentTo,
      Name: userData.assignedto,
      ccEmailAddress: userData.sentcc,
    };

    try {
      // eslint-disable-next-line
      const result = await ProjectService.resendProjectRequestEmail<any>(payloadData);
      ShowSuccessNotification('Email resent successfully');
    } catch (error) {
      LoggingService.logError({
        MethodName: 'RequestsUsers.handleResend',
        Message: 'Error resending email',
        AdditionalData: error,
      });
      ShowErrorNotification('Error resending email.');
    }
  };

  return (
    <Flex flexDirection='column' style={{ overflow: 'auto' }}>
      <Flex flexDirection='row' gap={0}></Flex>
      <DataTableV2
        columns={RowColumnsWithActions(handleOpenUserFiles) as any}
        data={filteredAssignedUsers}
        variant={{ rowDensity: 'comfortable', rowSeparation: 'none', verticalDividers: false, contained: false }}
        selectableRows
        interactiveRows
        resizable
        fullWidth
        onActiveRowChange={(row: any) => {
          const filteredData = filteredAssignedUsers;
          if (row !== null) {
            showProjectDetails({
              id: filteredData?.[+row].peopleId,
              index: +row,
              name: filteredData?.[+row].projectRequestUrl,
              visibility: true,
            });
          }
        }}
      >
        <DataTableV2.TableActions>{searchElement}</DataTableV2.TableActions>
        <DataTableV2.SelectedRowsActions>
          {(rows: any) => {
            const originalRows = rows.map((item: any) => item.originalRow);
            return (
              <>
                {searchElement}
                <VerticalLine />
                <Button
                  variant='emphasized'
                  onClick={() => originalRows.map((item: any) => handleResend(item))}
                  disabled={originalRows.length !== 1 && !originalRows.some((row: any) => !!row.projectRequestUrl)}
                >
                  Resend link
                </Button>

                <Button
                  variant='emphasized'
                  onClick={() => originalRows.forEach((row: any) => handleCopy(row.projectRequestUrl, row.id))}
                  disabled={originalRows.length !== 1 && !originalRows.some((row: any) => !!row.projectRequestUrl)}
                >
                  <Button.Prefix>
                    <CopyIcon />
                  </Button.Prefix>
                  Copy link
                </Button>

                <Button
                  variant='emphasized'
                  onClick={() => originalRows.map((item: any) => handleRedirect(item.projectRequestUrl, 0))}
                  disabled={originalRows.length !== 1 && !originalRows.some((row: any) => !!row.projectRequestUrl)}
                >
                  <Button.Prefix>
                    <ExternalLinkIcon />
                  </Button.Prefix>
                  Open link
                </Button>
                <Button
                variant='emphasized'
                  onClick={() => originalRows.forEach((row: any) => openRequestReminderModal(row, row.id))}
                  disabled={originalRows.length !== 1}
                >
                  <Button.Prefix>
                    <EditIcon />
                  </Button.Prefix>
                  Edit reminder
                </Button>
              </>
            );
          }}
        </DataTableV2.SelectedRowsActions>
      </DataTableV2>
    </Flex>
  );
};

export default RequestsUsers;
