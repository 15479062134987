import React from 'react'
import { ProjectService } from '../services/ProjectService';
import { Button, Flex, Text } from '@dynatrace/strato-components';
import { DocumentIcon, DownloadIcon } from '@dynatrace/strato-icons';
import { LoggingService } from '../services/LoggingService';

const FilePreview = ({ tenantId, previewFile }: any) => {

    const downloadFileClick = function (rowInfo: any) {
        try {
            const downloadFilePath =
                rowInfo?.downloadFilePath?.includes('uploads/')
                    ? rowInfo?.downloadFilePath?.split('uploads/')[1]
                    : rowInfo?.downloadFilePath?.includes('/requestsAttachments')
                        ? rowInfo?.downloadFilePath?.split('/requestsAttachments')[1]
                        : null;

            if (!downloadFilePath) {
                console.error("Unable to determine file path for:", rowInfo.downloadFilePath);
                alert("Invalid file path. Unable to download the file.");
                return;
            }

            const downloadUrl = ProjectService.getDownloadFileHeader(downloadFilePath, tenantId);
            window.open(downloadUrl, "_blank");
        } catch (error) {
            console.error("Error in downloadFileClick:", error);
            LoggingService.logError({
                MethodName: 'FilePreview.downloadFileClick',
                Message: 'An error occurred while trying to download the file.',
                AdditionalData: error
            });
            alert("An error occurred while trying to download the file.");
        }
    };


    return (
        <div>
            {previewFile.unsupported ? (
                <Flex
                    style={{ height: "80vh" }}
                    alignItems="center"
                    flexDirection="column"
                    justifyContent="center"
                >
                    <DocumentIcon style={{ height: '100px', width: '100px' }} />
                    <Text>File type is not supported for preview.</Text>
                    <Button variant="accent" onClick={() => downloadFileClick(previewFile.rowData)}>
                        <DownloadIcon /> Download
                    </Button>
                </Flex>
            ) : (
                (previewFile.fileType === 'docx' || previewFile.fileType === 'xlsx' || previewFile.fileType === 'doc' || previewFile.fileType === 'xls') ? (
                    <Flex style={{ height: "80vh", overflow: "auto", padding: 0, width: "100%" }}>
                        <iframe
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(previewFile?.uri)}`}
                            width="1366px"
                            height="623px"
                            frameBorder="0"
                            title="Office Document Viewer"
                        >
                        </iframe>
                    </Flex>
                ) : (
                    <Flex style={{ height: "80vh", overflow: "auto", padding: 0 }}>
                        {previewFile.uri.match(/\.(jpg|jpeg|png)$/i) ? (
                            <img
                                src={previewFile.uri}
                                alt={previewFile.fileName}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                }}
                            />
                        ) : (
                            <iframe
                                src={previewFile.uri}
                                title={previewFile.fileName}
                                style={{ width: "100%", height: "100%", border: "none" }}
                            />
                        )}
                    </Flex>
                )
            )}
        </div>
    )
}

export default FilePreview