import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { Page, ToastContainer } from '@dynatrace/strato-components-preview';
import { Sidebar } from './components/Sidebar';
import { AuthenticationGuard } from './components/AuthenticationGuard';
import { ProjectsPage } from './pages/projects/ProjectsPage';
import { BusinessesPage } from './pages/business/BusinessesPage';
import { LendersPage } from './pages/lenders/LendersPage';
import { PeoplesPage } from './pages/people/PeoplesPage';
import { EmptyStatePageComponent } from './components/EmptyStatePageComponent';
import { ProjectRequestDetails } from './pages/projects/project-details/ProjectRequestDetails';
import { Header } from './components/Header';
import { useAppInfo } from './contexts/AppContext';

export function AppRoutes() {
  const { isAppLoading } = useAppInfo();

  const match = useMatch('/projects/project/:ProjectId/*');
  const isProjectRoute = Boolean(match);  
  return (
    <Page>
      {/* Header */}
      <ToastContainer />
      {(!isAppLoading || isProjectRoute) && (
        <Page.Header>
          <Header />
        </Page.Header>
      )}
      {/* Sidebar */}
      {(!isAppLoading || isProjectRoute) && (
        <Page.Sidebar preferredWidth={50}>
          {/* <Flex flexDirection='row'>
            <TitleBar>
               <TitleBar.Title>Sidebar</TitleBar.Title>
            </TitleBar>
            <TitleBar.Suffix>
              <Page.PanelControlButton />
            </TitleBar.Suffix>
          </Flex> */}
          <Sidebar />
        </Page.Sidebar>
      )}
      {/* Main Content */}
      <Page.Main>
        <Routes>
          <Route path='/' element={<Navigate to='/projects' replace />} />
          <Route path='/projects' key={'p'} element={<AuthenticationGuard component={ProjectsPage} />} />
          <Route path='/businesses' element={<AuthenticationGuard component={BusinessesPage} />} />
          <Route path='/lenders' element={<AuthenticationGuard component={LendersPage} />} />
          <Route path='/people' element={<AuthenticationGuard component={PeoplesPage} />} />
          <Route path='projects/project/:ProjectId' element={<ProjectRequestDetails />}>
            <Route path='request/:RequestId'>
              <Route path='people/:PeopleId' />
            </Route>
          </Route>
          <Route path='*' element={<AuthenticationGuard component={EmptyStatePageComponent} />} />
        </Routes>
      </Page.Main>
    </Page>
  );
}
