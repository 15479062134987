import { useMemo, useState, useEffect } from 'react';
// import { ShowErrorNotification } from '../../utils/Notifications';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { ToastContainer } from '@dynatrace/strato-components-preview/notifications';
import { Page } from '@dynatrace/strato-components-preview/layouts';
import { Strong, Text } from '@dynatrace/strato-components/typography';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { DataTableV2, FilterBar, Modal, TextInput } from '@dynatrace/strato-components-preview';
import { format, parseISO } from 'date-fns';
import { PackageHeader } from './PackageHeader';
import CreatePackage from '../projects/package/CreatePackage';
import { ProjectService } from '../../services/ProjectService';
import { PackageService } from '../../services/PackageService';
import { XmarkIcon } from '@dynatrace/strato-icons';
import styled from 'styled-components';
import { Colors } from '@dynatrace/strato-design-tokens';
import { ShowErrorNotification } from '../../utils/Notifications';
import FilePreview from '../../components/FilePreview';
import { LoggingService } from '../../services/LoggingService';

const VerticalLine = styled.div`
  background-color: ${Colors.Border.Neutral.Default};
  width: 2px;
  height: 24px;
  vertical-align: middle;
  align-content: center;
`;

export const PackageLandingPage = ({ token }: { token: string }) => {
    const [peopleId, setPeopleId] = useState(0);
    const [packageId, setPackageId] = useState(0);
    const [tenantId, setTenantId] = useState(0);
    const [projectId, setProjectId] = useState(0);


    const [searchQuery, setSearchQuery] = useState<string>('');
    const [rowData, setRowData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [createPackageSheet, setCreatePackageSheet] = useState(false);
    const [actionsData, setActionsData] = useState<any[]>([]);
    const [projectData, setProjectData] = useState<any>([]);
    const [truncateTextFlag, setTruncateTextFlag] = useState(true);
    const [previewFile, setPreviewFile] = useState<any>(null);
    const truncatedText = rowData?.packageDetails?.projectPackages[0]?.packageMessage
        ? rowData?.packageDetails?.projectPackages[0]?.packageMessage.slice(0, 1000)
        : '';

    useEffect(() => {
        if (!token) {
            console.error('Token is missing.');
            setLoading(false);
            return;
        }

        const fetchData = async () => {
            try {
                const data = await PackageService.decryptAndFetch<{
                    peopleId: number;
                    projectRequestId: number;
                    projectId: number;
                    tenantId: number;
                }>(token);
                setPeopleId(data.peopleId);
                setPackageId(data.projectRequestId)
                setProjectId(data.projectId)
                setTenantId(data.tenantId)
            } catch (error) {
                console.error('Error fetching data:', error);
                LoggingService.logError({"MethodName":"PackageLandingPage.fetchData", "Message":"Error fetching data:", "AdditionalData":error});
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [token]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const fetchPackageDetails = async () => {
            if (!packageId) {
                console.error('Package ID is missing in query parameters.');
                setLoading(false);
                return;
            }

            try {
                const data = await PackageService.getPackageDetails<any>(
                    peopleId,
                    packageId,
                    tenantId,
                    projectId
                );
                setRowData(data);
            } catch (error) {
                LoggingService.logError({"MethodName":"PackageLandingPage.fetchPackageDetails", "Message":"Error fetching package details:", "AdditionalData":error});
                console.error('Error fetching package details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPackageDetails();
    }, [packageId, peopleId, tenantId, projectId]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const fetchProjectDetails = async () => {
            if (projectId && tenantId) {
                try {
                    const response = await ProjectService.getProjectWithDetailsID(projectId, tenantId);
                    setProjectData(response);
                } catch (error) {
                    LoggingService.logError({"MethodName":"PackageLandingPage.fetchProjectDetails", "Message":"Error fetching project details:", "AdditionalData":error});
                    console.error('Error fetching project details:', error);
                }
            }
        };

        fetchProjectDetails();
    }, [projectId, tenantId]);


    const fileColumns = useMemo(() => [
        {
            id: 'fileName',
            header: 'File name',
            width: 400,
            accessor: 'fileName',
            columnType: 'string' as const
        },
        {
            id: 'fileType',
            header: 'File type',
            accessor: 'fileType',
            columnType: 'string' as const
        },
        {
            id: 'createdBy',
            header: 'Created by',
            accessor: 'createdByName',
            width: 200,
            columnType: 'string' as const
        },
        {
            id: 'modifiedOn',
            header: 'Updated date',
            accessor: 'sentOn',
            width: 200
        },
        // eslint-disable-next-line
    ], [actionsData]);

    const handleDownloadSelected = async (selectedDataRows: any) => {
        if (!selectedDataRows || selectedDataRows.length === 0) {
            console.warn('No rows selected');
            return;
        }

        const selectedUniqueLinks = selectedDataRows.map((row: any) => row.uniqueLink);

        setActionsData((prev) => [
            ...prev,
            {
                action: 'download',
                timestamp: new Date().toISOString(),
                details: selectedDataRows,
            },
        ]);
        try {
            if (selectedUniqueLinks.length === 1) {
                const downloadFilePath = selectedUniqueLinks[0].split('uploads/')[1];
                window.open(ProjectService.getDownloadFileHeader(downloadFilePath, tenantId));
            } else {
                const { blob } = await PackageService.downloadSelectedPackages(
                    selectedUniqueLinks,
                    rowData.packageDetails?.projectPackages[0].packageName
                );

                const filename = rowData.packageDetails?.projectPackages[0].packageName
                    ? rowData.packageDetails?.projectPackages[0].packageName
                    : 'package.zip';

                const fileUrl = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = fileUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();

                window.URL.revokeObjectURL(fileUrl);
            }
        } catch (error) {
            LoggingService.logError({"MethodName":"PackageLandingPage.handleDownloadSelected", "Message":"Error downloading file:", "AdditionalData":error});
            console.error('Error downloading file:', error);
        }
    };

    const transformedData = useMemo(() => {
        return rowData?.projectRequestDetails?.map((item: any) => {
            const fileName = item.uniqueLink?.split('/')?.pop() || 'N/A';
            const fileType = item.uniqueLink?.split('.')?.pop() || 'N/A';
            const createdBy = item.createdByName || 'N/A';
            const modifiedOn = item.sentOn
                ? format(parseISO(item.sentOn), 'MMM dd, yyyy hh:mm a')
                : 'N/A';

            return {
                fileName,
                fileType,
                createdBy,
                modifiedOn,
                ...item,
            };
        });
    }, [rowData]);

    const filteredProjectDetails = useMemo(() => {
        const allDetails = transformedData

        if (!searchQuery.trim()) {
            // If there's no search, return the whole array
            return allDetails;
        }

        // Otherwise, return the filtered array
        return allDetails.filter((item: any) => {
            const link = item.uniqueLink?.toLowerCase() || '';
            return (
                link.includes(searchQuery.toLowerCase()) ||
                link.split('/').pop()?.includes(searchQuery.toLowerCase())
            );
        });
        // eslint-disable-next-line
    }, [searchQuery, rowData]);


    const myRowSelectionChangedListener = (
        selectedRowsData: any,
    ) => {
        const selectedIndices = Object.keys(selectedRowsData)
            .filter((key) => selectedRowsData[key])
            .map((key) => parseInt(key, 10));

        const selectedDataRows = selectedIndices.map((index) => filteredProjectDetails[index]);

        setSelectedRows(selectedDataRows)
    };

    if (loading) {
        return <LoadingStateComponent loading={true} />;
    }

    const mimeTypes: {
        pdf: string;
        jpg: string;
        jpeg: string;
        png: string;
        doc: string;
        docx: string;
        pptx: string;
        xlsx: string;
    } = {
        pdf: 'application/pdf',
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        doc: 'application/msword',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    const getMimeType = (fileExtension: string): string | undefined => {
        return mimeTypes[fileExtension as keyof typeof mimeTypes];
    };

    const supportedExtensions = ['txt', 'pdf', 'png', 'jpg', 'sql', 'htm', 'json', 'csv', 'docx', 'jpeg', 'doc', 'xls', 'xlsx'];

    const handleDocxPreview = async (rowInfo: any, base64File: string, fileExtension: string) => {
        try {

            const fileName = rowInfo.downloadFilePath.split('/').pop();
            const contentType = getMimeType(fileExtension) || '';

            const result = await PackageService.uploadDocxPreview(base64File, fileName, contentType) as any
            setPreviewFile({
                uri: result.downloadUrl,
                fileName: fileName,
                rowData: rowInfo,
                fileType: fileExtension,
            });
        } catch (error) {
            LoggingService.logError({"MethodName":"PackageLandingPage.handleDocxPreview", "Message":"Error generating DOCX preview:", "AdditionalData":error});
            console.error("Error generating DOCX preview:", error);
            ShowErrorNotification("Error generating preview for DOCX file.");
        }
    };


    const getFileForDocViewer = async (filteredRowInfo: any) => {
        try {
            const rowInfo = filteredRowInfo.map((item: any) => ({
                downloadFilePath: item.uniqueLink,
                ...item,
              }))[0];
            setLoading(true);
            const fileExtension = rowInfo?.downloadFilePath?.split('.')?.pop()?.toLowerCase();
            // console.log(fileExtension)

            if (!supportedExtensions.includes(fileExtension || '')) {
                setPreviewFile({
                    fileName: rowInfo?.downloadFilePath?.split('/')?.pop(),
                    unsupported: true,
                    fileType: fileExtension,
                    rowData: rowInfo
                });
                return;
            }

            const downloadFilePath = rowInfo?.downloadFilePath?.includes('uploads/')
                ? rowInfo?.downloadFilePath?.split('uploads/')[1]
                : rowInfo?.downloadFilePath?.includes('/requestsAttachments')
                    ? rowInfo?.downloadFilePath?.split('/requestsAttachments')[1]
                    : null;

            if (!downloadFilePath) {
                console.error("Unable to determine file path for:", rowInfo.downloadFilePath);
                alert("Invalid file path. Unable to preview the file.");
                return;
            }

            const base64File: any = await ProjectService.getUploadFileDataForStaff(rowInfo.downloadFilePath, tenantId);

            if (!base64File) {
                ShowErrorNotification("File data could not be retrieved.");
                return;
            }

            if (fileExtension === 'docx' || fileExtension === 'doc' || fileExtension === 'xls' || fileExtension === 'xlsx') {
                await handleDocxPreview(rowInfo, base64File, fileExtension);
            } else {

                const mimeType = getMimeType(fileExtension || '');
                const doc = {
                    uri: `data:${mimeType};base64,${base64File}`,
                    fileName: rowInfo?.downloadFilePath?.split('/')?.pop(),
                    rowData: rowInfo,
                    fileType: fileExtension
                };

                setPreviewFile(doc);
            }
        } catch (error) {
            LoggingService.logError({"MethodName":"PackageLandingPage.getFileForDocViewer", "Message":"Error in getFileForDocViewer:", "AdditionalData":error});
            console.error("Error in getFileForDocViewer:", error);
            ShowErrorNotification("An error occurred while trying to preview the file.");
        } finally {
            setLoading(false);
        }
    };

    // eslint-disable-next-line
    const downloadFileClick = function (rowInfo: any) {
        try {
            const downloadFilePath =
                rowInfo?.downloadFilePath?.includes('uploads/')
                    ? rowInfo?.downloadFilePath?.split('uploads/')[1]
                    : rowInfo?.downloadFilePath?.includes('/requestsAttachments')
                        ? rowInfo?.downloadFilePath?.split('/requestsAttachments')[1]
                        : null;

            if (!downloadFilePath) {
                console.error("Unable to determine file path for:", rowInfo.downloadFilePath);
                alert("Invalid file path. Unable to download the file.");
                return;
            }

            const downloadUrl = ProjectService.getDownloadFileHeader(downloadFilePath, tenantId);
            window.open(downloadUrl, "_blank");
        } catch (error) {
            LoggingService.logError({"MethodName":"PackageLandingPage.downloadFileClick", "Message":"Error in downloadFileClick:", "AdditionalData":error});
            console.error("Error in downloadFileClick:", error);
            alert("An error occurred while trying to download the file.");
        }
    };

    const searchElement = (
        <Flex flexDirection='row' paddingRight={8} justifyContent='center' alignContent='center' alignItems='center'>
            <FilterBar onFilterChange={() => { }}>
                <FilterBar.Item name={'search'} label={''}>
                    <TextInput placeholder={'Search'} onChange={setSearchQuery} value={searchQuery}>
                        <TextInput.Suffix>
                            <TextInput.Button
                                aria-label='clear'
                                onClick={() => {
                                    setSearchQuery('');
                                }}
                            >
                                <XmarkIcon />
                            </TextInput.Button>
                        </TextInput.Suffix>
                    </TextInput>
                </FilterBar.Item>
            </FilterBar>
        </Flex>
    );



    return (
        <Flex>
            <LoadingStateComponent loading={false} />
            {previewFile && (
                <Modal
                    show={!!previewFile}
                    onDismiss={() => setPreviewFile(null)}
                    title={previewFile.fileName}
                    size="large"
                    style={{ padding: 0, margin: 0 }}
                >
                    <FilePreview previewFile={previewFile} tenantId={tenantId} />
                </Modal>
            )}
            <Page>
                {/* Header */}
                <ToastContainer />
                {rowData.length !== 0 ? <>
                    <Page.Header>
                        <PackageHeader tenantName={rowData?.tenantDetails?.tenantName} packageShareId={rowData?.packageDetails.projectPackages[0].packageShareId} />
                    </Page.Header>
                    <Page.Main>
                        <Flex flexDirection="column">
                            <Flex justifyContent='space-between'>
                                <Strong style={{ textAlign: 'right' }}>{`${rowData.packageDetails?.projectPackages[0].packageName} - Welcome ${rowData.packageDetails?.projectPackages[0].firstName + " " + rowData.packageDetails?.projectPackages[0].lastName}`}</Strong>
                                <Flex>
                                    <Flex flexDirection='column'>
                                        <Text>{`${projectData?.project?.assignedToName}`}</Text>
                                        <Text style={{ fontSize: '10px' }}>Account contact</Text>
                                    </Flex>
                                    <Text as='h3' style={{ textAlign: 'right' }}>
                                        <strong>{packageId}</strong>
                                    </Text>
                                </Flex>
                            </Flex>
                            <Text>{`Hi ${rowData.packageDetails?.projectPackages[0].firstName},`}</Text>
                            {truncateTextFlag && (

                                <Text style={{ lineHeight: '1.5', whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflowWrap: 'break-word' }} fontStyle={'text'} as='pre' >{truncatedText}
                                    {truncatedText !== rowData.packageDetails?.projectPackages[0].packageMessage && (
                                        <Button onClick={() => setTruncateTextFlag(false)}>show more</Button>
                                    )}
                                </Text>



                            )}
                            {!truncateTextFlag && (
                                <Flex>
                                    <Text style={{ lineHeight: '1.5', whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflowWrap: 'break-word' }} fontStyle={'text'} as='pre'>{rowData.packageDetails?.projectPackages[0].packageMessage} {"  "}

                                        {truncatedText !== rowData.packageDetails?.projectPackages[0].packageMessage && (
                                            <Button onClick={() => setTruncateTextFlag(true)}>show less</Button>
                                        )}
                                    </Text>

                                </Flex>
                            )}
                            <Text>{` Our secure portal will be used to download documents as we go through the lending process.`}</Text>
                        </Flex>
                        {/* <Flex
                        style={{
                            border: `1px solid ${Colors.Border.Neutral.Default}`,
                            borderRadius: '10px',
                            padding: '8px',
                            marginTop: '16px',
                            marginBottom: '16px',
                        }}
                        alignItems="center"
                        gap={4}
                    >
                        <Button variant="accent" onClick={() => setCreatePackageSheet(true)}>
                            <UploadIcon />Upload
                        </Button>
                        <Text>
                            Do you have files to share relevant to this project? Use ProShare's Secure upload capabilities to send files back to us!
                        </Text>
                    </Flex> */}
                        <CreatePackage
                            isOpen={createPackageSheet}
                            onClose={function (): void {
                                setCreatePackageSheet(!createPackageSheet);
                            }}
                            peopleList={projectData?.projectPeopleRelation ?? []}
                            projectDetails={projectData}
                            onPackageCreated={() => { }}
                        />
                        <DataTableV2
                            columns={fileColumns as any}
                            data={filteredProjectDetails}
                            selectableRows
                            onRowSelectionChange={myRowSelectionChangedListener}
                            sortable
                            variant={{ rowDensity: 'comfortable', rowSeparation: 'none', verticalDividers: false, contained: false }}
                            resizable
                            fullWidth
                        >
                            <DataTableV2.TableActions>
                                {searchElement}
                            </DataTableV2.TableActions>
                            <DataTableV2.SelectedRowsActions>
                                {(rows: any) => {
                                    const originalRows = rows.map((item: any) => item.originalRow)
                                    return (
                                        <>
                                            {searchElement}
                                            <VerticalLine />
                                            <Button onClick={() => getFileForDocViewer(originalRows)} disabled={selectedRows && (selectedRows.length === 0 || selectedRows.length > 1)}>Preview</Button>
                                            <Button onClick={() => handleDownloadSelected(originalRows)} disabled={selectedRows && selectedRows.length === 0}>Download</Button>
                                        </>
                                    );
                                }}
                            </DataTableV2.SelectedRowsActions>
                        </DataTableV2>
                    </Page.Main>
                </> : <></>}
            </Page>
        </Flex>
    );
};

export default PackageLandingPage;
