import { FormField, Label, Modal, TextInput } from '@dynatrace/strato-components-preview';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppInfo } from '../../contexts/AppContext';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { PeopleService } from '../../services/PeopleService';
import { LoggingService } from '../../services/LoggingService';
import { formatPhoneNumberForDisplay } from '../../utils/Utilities';
import { extendedProfileAttribute } from '../../types/Person';

interface NewPersonModalProps {
  onDismiss: (refresh?: boolean) => void;
  tenantExtendedAttributesList: extendedProfileAttribute[] | undefined;
}

type extendedProfile = {
  peopleId: number;
  attributeName: string;
  attributeId: number;
  tenantId: number;
  attributeValue: string;
}

export const NewPersonModal = (props: NewPersonModalProps) => {
  const { onDismiss, tenantExtendedAttributesList } = props;

  const [showModal, setShowModal] = useState(true);

  const { tenantId } = useAppInfo();
  const {
    handleSubmit: submitNewPerson,
    control: newPersonControl,
    reset: newPersonReset,
  } = useForm<{
    firstName: string;
    lastName: string;
    mobile: string;
    email: string;
    ExtendedProfileFields: extendedProfile[];
  }>({
    mode: 'all',
    defaultValues: {
      ExtendedProfileFields: tenantExtendedAttributesList
    },
  });

  const handleCancel = () => {
    setShowModal(false);
    onDismiss();
  };

  // save the information
  const savePerson = async (values: any) => {
    //console.log('Saving project details', values);
    const extendedProfileFields: extendedProfileAttribute[] = values.ExtendedProfileFields || [];
    const cleanedMobile = values.mobile.replace(/\D/g, '');
    const updateParams: {
      tenantId: number;
      firstName: string;
      lastName: string;
      phoneNumber: string;
      email: string;
      extendedProfile: extendedProfileAttribute[];
    } = {
      tenantId: tenantId,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: cleanedMobile,
      email: values.email,
      extendedProfile: [],
    };
    extendedProfileFields.forEach((attributeFields) => {
      updateParams.extendedProfile.push({
        attributeId: attributeFields.attributeId,
        attributeName: attributeFields.attributeName,
        attributeValue: attributeFields.attributeValue
          ? (typeof attributeFields.attributeValue === 'object' && 'value' in attributeFields.attributeValue
            ? (attributeFields.attributeValue as { value: string }).value
            : attributeFields.attributeValue.toString())  // Otherwise convert to string
          : "",
        peopleId: attributeFields.peopleId,
        tenantId: attributeFields.tenantId,
        attributeSection: attributeFields.attributeSection,
        attributeType: attributeFields?.attributeType,
        attributeOptions: attributeFields?.attributeOptions ?? [],
      });
    });
    try {
      await PeopleService.createPepople(updateParams, tenantId);
      ShowSuccessNotification('Business created successfully');
      // save the information and then close
      onDismiss(true);
    } catch (error) {
      LoggingService.logError({
        MethodName: 'NewPersonModal.savePerson',
        Message: 'Error creating project user',
        AdditionalData: error
      });
      ShowErrorNotification('Error creating project user', error);
      onDismiss();
      return;
    }
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    submitNewPerson(savePerson)(event);
  };

  return (
    <Modal
      show={showModal}
      title={'Add new person'}
      size={'small'}
      dismissible={false}
      footer={
        <>
          <Flex justifyContent='flex-end' gap={8}>
            <Button onClick={handleCancel} variant='default'>
              Discard
            </Button>
            <Button form='new-person-form' type='submit' variant='accent' color='primary'>
              Confirm
            </Button>
          </Flex>
        </>
      }
    >
      <form id='new-person-form' key={2} onSubmit={handleFormSubmit} onReset={() => newPersonReset()} noValidate>
        <Flex flexDirection='column' width={512} minWidth={512} gap={16}>
          <Controller
            name='firstName'
            control={newPersonControl}
            rules={{
              required: {
                value: true,
                message: 'First name is required.',
              },
              maxLength: {
                value: 200,
                message: 'Please enter a valid first name.',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormField required>
                <Label>First name</Label>
                <TextInput
                  placeholder='Enter first name'
                  controlState={{
                    state: error ? 'error' : 'valid',
                    hint: error?.message,
                  }}
                  {...field}
                />
              </FormField>
            )}
          />
          <Controller
            name='lastName'
            control={newPersonControl}
            rules={{
              required: {
                value: true,
                message: 'Last name is required.',
              },
              maxLength: {
                value: 200,
                message: 'Please enter a valid last name.',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormField required>
                <Label>Last name</Label>
                <TextInput
                  placeholder='Enter last name'
                  controlState={{
                    state: error ? 'error' : 'valid',
                    hint: error?.message,
                  }}
                  {...field}
                />
              </FormField>
            )}
          />
          <Controller
            name='email'
            control={newPersonControl}
            rules={{
              validate: (value) => /\S+@\S+\.\S+/.test(value) || 'Please enter a valid email address.',
              required: {
                value: true,
                message: 'Email is required.',
              },
              maxLength: {
                value: 200,
                message: 'Please enter a valid email.',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormField required>
                <Label>Email</Label>
                <TextInput
                  placeholder='Enter email address'
                  controlState={{
                    state: error ? 'error' : 'valid',
                    hint: error?.message,
                  }}
                  {...field}
                />
              </FormField>
            )}
          />
          <Controller
            name='mobile'
            control={newPersonControl}
            rules={{
              required: {
                value: true,
                message: 'Mobile number is required.',
              },
              validate: (value: string) => {
                const cleaned = value.replace(/\D/g, '');
                return cleaned.length === 10 || 'Please enter a valid 10-digit phone number.';
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <FormField required>
                <Label>Mobile number</Label>
                <TextInput
                  placeholder="Enter mobile number"
                  controlState={{
                    state: error ? 'error' : 'valid',
                    hint: error?.message,
                  }}
                  value={formatPhoneNumberForDisplay(field.value || '')}
                  onChange={(value: string, event: React.ChangeEvent<HTMLInputElement>) => {
                    const cleaned = value.replace(/\D/g, '').slice(0, 10);
                    field.onChange(cleaned);
                  }}
                />
              </FormField>
            )}
          />
        </Flex>
      </form>
    </Modal>
  );
};
