import { DataTableV2, DataTableV2ColumnSort } from '@dynatrace/strato-components-preview/tables';
import { TextInput } from '@dynatrace/strato-components-preview/forms';
import { Page, TitleBar } from '@dynatrace/strato-components-preview/layouts';
import { Tooltip } from '@dynatrace/strato-components-preview/overlays';
import { FilterBar } from '@dynatrace/strato-components-preview/filters';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { TextEllipsis } from '@dynatrace/strato-components/typography';
import { useMemo, useState } from 'react';
import { PlusIcon } from '@dynatrace/strato-icons';
import { BusinessSheet } from './BusinessSheet';
import { ItemInfo } from '../../types/ListItemInfo';
import { configRoutes } from '../../configs/constants';
import { useBusinessesByTenantId } from '../../hooks/use-minerva-data';
import { ShowErrorNotification } from '../../utils/Notifications';
import { useAppInfo } from '../../contexts/AppContext';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';

export interface BusinessesProps {}

export const BusinessesPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [itemDetailsVisible, setItemDetailsVisible] = useState<ItemInfo>({ refreshParent: false });
  const { isAppLoading, tenantId } = useAppInfo();
  const [sortBy, setSortBy] = useState([{ id: 'businessName', desc: false }]);
  const handleSortingChange = (sort: DataTableV2ColumnSort[]) => {
    setSortBy(sort);
  };

  const columns = useMemo<any[]>(
    () => [
      {
        id: 'businessName',
        header: 'Business name',
        accessor: 'businessName',
        width: { type: 'auto', minWidth: 300 },
        cell: ({ rowData, rowIndex }: any) => (
          <DataTableV2.DefaultCell>
            <Tooltip placement='bottom' text={rowData.businessName}>
              {<TextEllipsis truncationMode='end'>{rowData.businessName}</TextEllipsis>}
            </Tooltip>
          </DataTableV2.DefaultCell>
        ),
      },
      // {
      //   id: 'annualRevenue',
      //   header: 'Annual revenue',
      //   accessor: 'annualRevenue',
      //   minWidth: 150,
      //   autoWidth: true,
      //   columnType: 'number',
      //   formatter: { input: units.currency.usd },
      // },
      {
        id: 'businessAddress',
        header: 'Business address',
        accessor: 'businessAddress',
        width: { type: 'auto', minWidth: 900 },
      },
    ],
    [],
  );

  const { isLoading, data, error, refetch } = useBusinessesByTenantId(tenantId);
  if (error && !isAppLoading) {
    ShowErrorNotification('Error loading businesses', error);
  }

  if (itemDetailsVisible?.refreshParent) {
    itemDetailsVisible.refreshParent = false;
    refetch();
  }

  const filteredData = useMemo(() => {
    const businessData = data;
    return searchQuery.trim().length !== 0
      ? businessData?.filter(
          (item: { businessId: number; annualRevenue: number; businessName: string; businessAddress: string }) =>
            item.businessName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.businessAddress.toLowerCase().includes(searchQuery.toLowerCase()),
        )
      : businessData;
  }, [searchQuery, data]);

  return isAppLoading ? (
    <></>
  ) : (
    <>
      <TitleBar>
        <TitleBar.Prefix>
          <Page.PanelControlButton target='sidebar' />
        </TitleBar.Prefix>
        <TitleBar.Title>{configRoutes[1].friendlyName}</TitleBar.Title>
        <TitleBar.Subtitle>{configRoutes[1].desc} </TitleBar.Subtitle>
      </TitleBar>
      <Flex flexDirection='column' margin={8} padding={0} gap={0} style={{ height: `calc(100% - 85px)`, overflowY: 'hidden' }} >
        <Flex flexDirection='row' justifyContent='space-between' alignItems='end' marginBottom={16} marginTop={8}>
          <FilterBar onFilterChange={() => {}}>
            <FilterBar.Item name={'search'} label={''}>
              <TextInput placeholder={'Search'} onChange={setSearchQuery} value={searchQuery} />
            </FilterBar.Item>
            <FilterBar.Item name={'selectedType'} label={''}>
              <Button></Button>
            </FilterBar.Item>
          </FilterBar>

          <Button
            onClick={() => {
              setItemDetailsVisible({ visibility: true });
            }}
            variant='accent'
            color='primary'
            style={{ margin: '0px 0px 0px auto' }}
          >
            <Button.Prefix>
              <PlusIcon />
            </Button.Prefix>
            Business
          </Button>
        </Flex>

        <Flex flexDirection={'column'} style={{ height: 'calc(100% - 60px)' }}>
          <LoadingStateComponent loading={isLoading} />
          <DataTableV2
            columns={columns as any}
            data={filteredData ?? []}
            sortable
            resizable
            fullWidth
            variant={{ rowDensity: 'comfortable', rowSeparation: 'none', verticalDividers: false, contained: false }}
            sortBy={sortBy}
            onSortByChange={handleSortingChange}
            interactiveRows
            onActiveRowChange={(row) => {
              if (row !== null) {
                setItemDetailsVisible({
                  id: filteredData?.[+row].businessId,
                  index: +row,
                  name: filteredData?.[+row].businessName,
                  visibility: true,
                  refreshParent: false,
                });
              }
            }}
          ></DataTableV2>
        </Flex>

        {itemDetailsVisible && itemDetailsVisible.visibility && (
          <BusinessSheet
            closeDetails={(itemInfo?: ItemInfo) => {
              setItemDetailsVisible(itemInfo ?? { refreshParent: false });
            }}
            itemInfo={itemDetailsVisible}
            show
          />
        )}
      </Flex>
    </>
  );
};
