import { DataTableV2, FilterBar, Modal, Strong, TextInput } from '@dynatrace/strato-components-preview';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import React, { useEffect, useRef, useState } from 'react';
import { rowDocumentsColumns } from './table-definitions';
import { hideElement } from '../../ProjectStyles.css';
import { handleFileChange } from '../project-details-utils';
import styled from 'styled-components';
import { Colors } from '@dynatrace/strato-design-tokens';
import { useParams } from 'react-router-dom';
import { PackageService } from '../../../../services/PackageService';
import { DownloadIcon, UploadIcon, ViewIcon, XmarkIcon } from '@dynatrace/strato-icons';
import { ShowErrorNotification } from '../../../../utils/Notifications';
import { ProjectService } from '../../../../services/ProjectService';
import { useAppInfo } from '../../../../contexts/AppContext';
import FilePreview from '../../../../components/FilePreview';
import { LoggingService } from '../../../../services/LoggingService';

const VerticalLine = styled.div`
  border-left: 2px solid ${Colors.Border.Neutral.Default};
  margin: 0px;
`;

const RequestsUserFiles = ({
  requestStatusData,
  projectRequests,
  fileInputRefs,
  projectRequestsApiData,
  handleSelectChange,
  downloadFileClick,
  refetchProjectRequests,
  setProjectRequestsApiData,
  navigationPath,
  setLoading,
}: any) => {
  const { RequestId } = useParams();
  const { tenantId } = useAppInfo();
  const RequestUserData =
    projectRequestsApiData?.filter((item: any) => item.projectRequestId === Number(RequestId))[0]?.assignedList || [];
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState<string | null>(null);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [previewFile, setPreviewFile] = useState<any>(null);

  // console.log(selectedRows)
  const filterData = (data: any[], query: string) => {
    if (!query) return data;
    return data.filter((item) => {
      return Object.values(item).some(
        (value) => typeof value === 'string' && value.toLowerCase().includes(query.toLowerCase()),
      );
    });
  };

  const handleSelectedRowsStatus = async (selectedRowsInfo: any[], status: string) => {
    // console.log(selectedRowsInfo)
    selectedRowsInfo.forEach(async (row) => {
      await handleSelectChange(status, requestStatusData, row);
    });

    await refetchProjectRequests();
  };

  const unfilteredAssignedData = JSON.parse(JSON.stringify(RequestUserData[0]?.documents || []));

  const filteredAssignedData = filterData(RequestUserData[0]?.documents || [], searchQuery);

  const handleFileInputRef = (el: HTMLInputElement | null, row: any) => {
    const requestId = Number(RequestId);
    const documentIndex = Number(row?.projectRequestSentId);

    if (isNaN(requestId) || isNaN(documentIndex)) {
      console.warn('Invalid RequestId or DocumentIndex');
      return;
    }

    if (!fileInputRefs.current[requestId]) {
      fileInputRefs.current[requestId] = [];
    }

    fileInputRefs.current[requestId][documentIndex] = el;
  };

  const handleDownloadSelected = async (selectedRowsInfo: any) => {
    if (!selectedRowsInfo || selectedRowsInfo.length === 0) {
      console.warn('No rows selected');
      return;
    }

    const selectedUniqueLinks = selectedRowsInfo.map((row: any) => row.downloadFilePath);

    try {
      if (selectedUniqueLinks.length === 1) {
        selectedRowsInfo.forEach((item: any) => downloadFileClick(item));
      } else {
        const { blob } = await PackageService.downloadSelectedPackages(selectedUniqueLinks, '');

        const filename = 'package.zip';

        const fileUrl = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = fileUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();

        window.URL.revokeObjectURL(fileUrl);
      }
    } catch (error) {
      LoggingService.logError({
        MethodName: 'RequestsUserFiles.handleDownloadSelected',
        Message: 'Error downloading file',
        AdditionalData: error,
      });
      console.error('Error downloading file:', error);
    }
  };

  const openCommentModal = (comment: string | null) => {
    setSelectedComment(comment);
    setCommentModalOpen(true);
  };

  const closeCommentModal = () => {
    setCommentModalOpen(false);
    setSelectedComment(null);
  };

  const rowDocumentsColumnsWithActions = (requestId: any) => [...rowDocumentsColumns];

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSelectedRows([]);
    }, 500);

    return () => clearTimeout(timeout);
  }, [searchQuery]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [filteredAssignedData]);

  const mimeTypes: {
    pdf: string;
    jpg: string;
    jpeg: string;
    png: string;
    doc: string;
    docx: string;
    pptx: string;
    xlsx: string;
  } = {
    pdf: 'application/pdf',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  const getMimeType = (fileExtension: string): string | undefined => {
    return mimeTypes[fileExtension as keyof typeof mimeTypes];
  };

  const supportedExtensions = [
    'txt',
    'pdf',
    'png',
    'jpg',
    'sql',
    'htm',
    'json',
    'csv',
    'docx',
    'jpeg',
    'doc',
    'xls',
    'xlsx',
  ];

  const handleDocxPreview = async (rowInfo: any, base64File: string, fileExtension: string) => {
    try {
      const fileName = rowInfo.downloadFilePath.split('/').pop();
      const contentType = getMimeType(fileExtension) || '';

      const result = (await PackageService.uploadDocxPreview(base64File, fileName, contentType)) as any;
      setPreviewFile({
        uri: result.downloadUrl,
        fileName: fileName,
        rowData: rowInfo,
        fileType: fileExtension,
      });
    } catch (error) {
      console.error('Error generating DOCX preview:', error);
      LoggingService.logError({
        MethodName: 'RequestsUserFiles.handleDocxPreview',
        Message: 'Error generating preview for DOCX file',
        AdditionalData: error,
      });
      ShowErrorNotification('Error generating preview for DOCX file.');
    }
  };

  const getFileForDocViewer = async (filteredRowInfo: any) => {
    try {
      const rowInfo = filteredRowInfo.map((item: any) => ({
        downloadFilePath: item.uniqueLink,
        ...item,
      }))[0];
      setLoading(true);
      const fileExtension = rowInfo?.downloadFilePath?.split('.')?.pop()?.toLowerCase();

      if (!supportedExtensions.includes(fileExtension || '')) {
        setPreviewFile({
          fileName: rowInfo?.downloadFilePath?.split('/')?.pop(),
          unsupported: true,
          fileType: fileExtension,
          rowData: rowInfo,
        });
        return;
      }

      const downloadFilePath = rowInfo?.downloadFilePath?.includes('uploads/')
        ? rowInfo?.downloadFilePath?.split('uploads/')[1]
        : rowInfo?.downloadFilePath?.includes('/requestsAttachments')
        ? rowInfo?.downloadFilePath?.split('/requestsAttachments')[1]
        : null;

      if (!downloadFilePath) {
        console.error('Unable to determine file path for:', rowInfo.downloadFilePath);
        alert('Invalid file path. Unable to preview the file.');
        return;
      }

      const base64File: any = await ProjectService.getUploadFileDataForStaff(rowInfo.downloadFilePath, tenantId);

      if (!base64File) {
        ShowErrorNotification('File data could not be retrieved.');
        return;
      }

      if (fileExtension === 'docx' || fileExtension === 'doc' || fileExtension === 'xls' || fileExtension === 'xlsx') {
        await handleDocxPreview(rowInfo, base64File, fileExtension);
      } else {
        const mimeType = getMimeType(fileExtension || '');
        const doc = {
          uri: `data:${mimeType};base64,${base64File}`,
          fileName: rowInfo?.downloadFilePath?.split('/')?.pop(),
          rowData: rowInfo,
          fileType: fileExtension,
        };

        setPreviewFile(doc);
      }
    } catch (error) {
      console.error('Error in getFileForDocViewer:', error);
      LoggingService.logError({
        MethodName: 'RequestsUserFiles.getFileForDocViewer',
        Message: 'An error occurred while trying to preview the file.',
        AdditionalData: error,
      });
      ShowErrorNotification('An error occurred while trying to preview the file.');
    } finally {
      setLoading(false);
    }
  };

  const searchElement = React.useMemo(
    () => (
      <Flex flexDirection='row' paddingRight={8} justifyContent='center' alignContent='center' alignItems='center'>
        <FilterBar onFilterChange={() => {}}>
          <FilterBar.Item name={'search'} label={''}>
            <TextInput
              ref={searchInputRef as any}
              placeholder={'Search'}
              onChange={(e: any) => handleSearchChange(e)}
              value={searchQuery}
            >
              <TextInput.Suffix>
                <TextInput.Button
                  aria-label='clear'
                  onClick={() => {
                    setSearchQuery('');
                  }}
                >
                  <XmarkIcon />
                </TextInput.Button>
              </TextInput.Suffix>
            </TextInput>
          </FilterBar.Item>
        </FilterBar>
      </Flex>
    ),
    [searchQuery],
  );

  return (
    <Flex flexDirection='column' style={{ overflow: 'auto' }}>
      {previewFile && (
        <Modal
          show={!!previewFile}
          onDismiss={() => setPreviewFile(null)}
          title={previewFile.fileName}
          size='large'
          style={{ padding: 0, margin: 0 }}
        >
          <FilePreview previewFile={previewFile} tenantId={tenantId} />
        </Modal>
      )}
      <Flex flexDirection='row' gap={0}></Flex>
      <Modal show={commentModalOpen} title='Comments' size='small' onDismiss={closeCommentModal}>
        <Flex flexDirection='column' gap={8} width={512}>
          <Strong>{selectedComment}</Strong>
          <Flex justifyContent='flex-end'>
            <Button variant='accent' onClick={closeCommentModal} type='button'>
              Close
            </Button>
          </Flex>
        </Flex>
      </Modal>

      <DataTableV2
        columns={rowDocumentsColumnsWithActions(navigationPath.requestId) as any}
        data={filteredAssignedData}
        variant={{ rowDensity: 'comfortable', rowSeparation: 'none', verticalDividers: false, contained: false }}
        resizable
        fullWidth
        sortable
        selectableRows
        selectedRows={selectedRows}
        onRowSelectionChange={setSelectedRows}
      >
        <DataTableV2.TableActions>{searchElement}</DataTableV2.TableActions>
        <DataTableV2.SelectedRowsActions>
          {(rows: any) => {
            const originalRows = rows.map((item: any) => item.originalRow);
            // eslint-disable-next-line
            const originalRows2 = unfilteredAssignedData?.filter((item: any, index: any) => selectedRows[index]);

            return (
              <Flex>
                {searchElement}
                <VerticalLine />
                <Button
                  variant='emphasized'
                  onClick={() => getFileForDocViewer(originalRows)}
                  disabled={
                    originalRows.length === 0 ||
                    originalRows.length > 1 ||
                    originalRows.some(
                      (row: any) =>
                        row.statusName === 'New' ||
                        row.statusName === 'Rejected' ||
                        row.statusName === 'Not Applicable' ||
                        row.downloadFilePath === '' ||
                        row.downloadFilePath === null,
                    )
                  }
                >
                  Preview
                </Button>

                <Button
                  variant='emphasized'
                  onClick={() => handleDownloadSelected(originalRows)}
                  disabled={
                    originalRows.length === 0 ||
                    originalRows.some(
                      (row: any) =>
                        row.statusName === 'New' ||
                        row.statusName === 'Rejected' ||
                        row.statusName === 'Not Applicable' ||
                        row.downloadFilePath === '' ||
                        row.downloadFilePath === null,
                    )
                  }
                >
                                      <Button.Prefix>
                                        <DownloadIcon />
                                      </Button.Prefix>
                    
                  Download
                </Button>

                <Button
                  variant='emphasized'
                  style={{ margin: 0 }}
                  onClick={() => {
                    if (originalRows.length > 0) {
                      const requestId = Number(RequestId);
                      const documentIndex = originalRows[0]?.projectRequestSentId
                        ? Number(originalRows[0].projectRequestSentId)
                        : 0;

                      if (fileInputRefs.current[requestId]?.[documentIndex]) {
                        fileInputRefs.current[requestId][documentIndex]?.click();
                      } else {
                        console.warn('No input ref found for the current RequestId or DocumentIndex');
                      }
                    } else {
                      console.warn('No rows selected for upload.');
                    }
                  }}
                  disabled={
                    originalRows.length === 0 ||
                    originalRows.length !== 1 ||
                    originalRows.some((row: any) => row.statusName === 'Approved')
                  }
                >
                                      <Button.Prefix>
                                        <UploadIcon />
                                      </Button.Prefix>
                    
                  Upload
                </Button>

                <input
                  type='file'
                  accept='.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .txt, .zip'
                  ref={(el) => handleFileInputRef(el, originalRows[0])}
                  className={hideElement}
                  onChange={(event) =>
                    handleFileChange(
                      event,
                      Number(RequestId),
                      originalRows[0],
                      Number(originalRows[0].projectRequestSentId),
                      projectRequests,
                      projectRequestsApiData,
                      setProjectRequestsApiData,
                      refetchProjectRequests,
                      setLoading,
                    )
                  }
                  title='Upload file'
                />

                <Button
                  variant='emphasized'
                  onClick={() => handleSelectedRowsStatus(originalRows, 'Approved')}
                  disabled={
                    originalRows.length === 0 ||
                    originalRows.some((row: any) => row.statusName === 'Approved' || row.statusName === 'New')
                  }
                >
                  Approve
                </Button>

                <Button
                  variant='emphasized'
                  onClick={() => handleSelectedRowsStatus(originalRows, 'Rejected')}
                  disabled={
                    originalRows.length === 0 ||
                    originalRows.length !== 1 ||
                    originalRows.some(
                      (row: any) =>
                        row.statusName === 'Rejected' || row.statusName === 'Approved' || row.statusName === 'New',
                    )
                  }
                >
                  Reject
                </Button>

                <Button
                  variant='emphasized'
                  onClick={() => originalRows.length === 1 && openCommentModal(originalRows[0]?.comments)}
                  disabled={
                    originalRows.length === 0 ||
                    originalRows.length !== 1 ||
                    originalRows.some(
                      (row: any) =>
                        row.comments === null ||
                        row.comments === '' 
                        // ||
                        // (row.statusName !== 'Not Applicable' && row.statusName !== 'Rejected'),
                    )
                  }
                >
                  <Button.Prefix>
                    <ViewIcon />
                  </Button.Prefix>
                  View comment
                </Button>
              </Flex>
            );
          }}
        </DataTableV2.SelectedRowsActions>
      </DataTableV2>
    </Flex>
  );
};

export default RequestsUserFiles;
