import { DataTableV2, Modal, SelectV2, Tooltip } from '@dynatrace/strato-components-preview';
import { Text, TextEllipsis } from '@dynatrace/strato-components/typography';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { useEffect, useState } from 'react';
import { useAppInfo } from '../../../contexts/AppContext';
import { usePeopleByTenantId } from '../../../hooks/use-minerva-data';
import { ShowErrorNotification, ShowSuccessNotification, ShowValidationBanner } from '../../../utils/Notifications';
import { PersonaService } from '../../../services/PersonaService';
import { ProjectPeopleRelation } from '../../../types/Project';
import { ModalParentType, PARENT_TYPE_BUSINESS, PARENT_TYPE_NONE, PARENT_TYPE_PROJECT } from '../../../types/Types';
import { ClientPersona } from '../../../types/Business';
import { LoggingService } from '../../../services/LoggingService';

interface EditProjectPeopleRelationModalProps {
  parentType: ModalParentType;
  parentId: number;
  currentProjectPeopleList: ProjectPeopleRelation[];
  currentBusinessPeopleList: ClientPersona[];
  onDismiss: (update?: boolean) => void;
  relationToEdit?: ProjectPeopleRelation;
}

export const EditProjectPeopleRelationModal = (props: EditProjectPeopleRelationModalProps) => {
  const { onDismiss, parentId, currentProjectPeopleList, currentBusinessPeopleList, relationToEdit } = props;

  const isPrimaryContactExist =
    currentProjectPeopleList?.filter((item) => item.primaryContact === 1)?.length > 0 ||
    currentBusinessPeopleList?.filter((item) => item.primaryContact === 1)?.length > 0;

// eslint-disable-next-line
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const [selectedPerson, setSelectedPerson] = useState<any>(null);
  const { tenantId, tenantPersonaList, peoplePersonas } = useAppInfo();

  const { isLoading, data: tenantPeople, error } = usePeopleByTenantId(
    tenantId && Number(tenantId) !== 0 ? Number(tenantId) : Number(localStorage.getItem("tenantId"))
  );
  if (error) {
    ShowErrorNotification('Error loading people information', error);
  }

  const availablePeople = tenantPeople
    ? tenantPeople.filter((person) =>
      props.parentType === PARENT_TYPE_PROJECT
        ? !props.currentProjectPeopleList.some((ppr) => ppr.peopleId === person.clientId)
        : !props.currentBusinessPeopleList.some((ppr) => ppr.clientId === person.clientId)
    )
    : [];

  useEffect(() => {
    if (!selectedPerson && availablePeople.length > 0) {
      setSelectedPerson({ ...relationToEdit, isPersonSelected: true, isPrimaryContact: false });
    }
  // eslint-disable-next-line
  }, [availablePeople, selectedPerson]);

  const isValidSelection = (person: any) => {
    return person && person.personaAutoId !== undefined && person.personaAutoId !== "";
  };

  const onSelectPersona = (row: any, personaAutoId: number) => {
    if (selectedPerson && selectedPerson.clientId === row.clientId) {
      const updated = { ...selectedPerson, personaAutoId };
      setSelectedPerson(updated);
      setDisableConfirm(!isValidSelection(updated));
    }
  };

  const onPrimaryChange = (row: any, value: string) => {
    if (selectedPerson && selectedPerson.clientId === row.clientId) {
      const yesSelected = value === "true";
      setSelectedPerson({ ...selectedPerson, primaryContact: yesSelected });
    }
  };

  useEffect(() => {
    setDisableConfirm(!isValidSelection(selectedPerson));
  }, [selectedPerson]);

  const projectPeopleColumns = [
    {
      id: 'clientName',
      header: 'Name',
      accessor: 'clientName',
      minWidth: 200,
      autoWidth: true,
      cell: ({ rowData }: any) => (
        <DataTableV2.DefaultCell>
          <Tooltip placement='bottom' text={`${rowData.firstName} ${rowData.lastName}`}>
            <TextEllipsis truncationMode='end'>
              {`${rowData.firstName} ${rowData.lastName}`}
            </TextEllipsis>
          </Tooltip>
        </DataTableV2.DefaultCell>
      ),
    },
    {
      id: 'primaryContact',
      header: 'Primary Contact',
      accessor: 'primaryContact',
      width: 150,
      // Renders a dropdown for primary contact selection.
      cell: ({ rowData }: any) => {
        const isPrimary = selectedPerson?.primaryContact || false;
        return (
          <DataTableV2.DefaultCell>
            <Tooltip placement='bottom' text={isPrimaryContactExist ? "Primary contact already exists" : "Select primary contact"}>
              <SelectV2
                value={isPrimary ? "true" : "false"}
                onChange={(value) => onPrimaryChange(rowData, value as string)}
              >
                <SelectV2.Trigger placeholder="Select" />
                <SelectV2.Content style={{ maxWidth: '300px' }} width='150px'>
                  <SelectV2.Option value="true">
                    <Text>Yes</Text>
                  </SelectV2.Option>
                  <SelectV2.Option value="false">
                    <Text>No</Text>
                  </SelectV2.Option>
                </SelectV2.Content>
              </SelectV2>
            </Tooltip>
          </DataTableV2.DefaultCell>
        );
      },
    },
    {
      id: 'personaName',
      header: 'Project role',
      accessor: 'personaName',
      width: 200,
      // Renders the project role select dropdown.
      cell: ({ rowData }: any) => (
        <DataTableV2.DefaultCell>
          <Flex
            onKeyDown={(event: { key: string; preventDefault: () => void }) => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
          >
            <Button style={{ padding: 0, margin: 0 }}>
              <SelectV2
                value={selectedPerson?.personaAutoId ?? ""}
                disabled={!(selectedPerson && selectedPerson.clientId === rowData.clientId)}
                onChange={(value) => onSelectPersona(rowData, value as number)}
              >
                <SelectV2.Trigger placeholder={'Select project role'} />
                <SelectV2.Content style={{ maxWidth: '300px' }} width='150px'>
                  {tenantPersonaList &&
                    tenantPersonaList?.filter((item) => item.personaId !== 1)?.map((persona, index) => (
                      <SelectV2.Option key={index} value={persona.personaAutoId}>
                        <Text>{persona.personaName}</Text>
                      </SelectV2.Option>
                    ))}
                  {props.parentType === PARENT_TYPE_BUSINESS &&
                    peoplePersonas &&
                    peoplePersonas.map((persona, index) => (
                      <SelectV2.Option key={index} value={persona.personaId}>
                        <Text>{persona.personaName}</Text>
                      </SelectV2.Option>
                    ))}
                </SelectV2.Content>
              </SelectV2>
            </Button>
          </Flex>
        </DataTableV2.DefaultCell>
      ),
    },
    {
      id: 'email',
      header: 'Email',
      accessor: 'email',
    },
  ];

  const handleCancel = () => {
    setShowModal(false);
    onDismiss(false);
  };

  const handleConfirm = () => {
    if (props.parentType === PARENT_TYPE_PROJECT) {
      saveProjectPeopleRelation();
    }
    if (props.parentType === PARENT_TYPE_BUSINESS) {
      saveBusinessPeopleRelation();
    }
    setShowModal(false);
    onDismiss(false);
  };

  const saveProjectPeopleRelation = async () => {
    try {
      const updates = [];
  
      const newRelation = {
        projectPersonId: selectedPerson.projectPeopleId, 
        tenantId: tenantId,
        projectId: parentId,
        peopleId: selectedPerson.peopleId,
        personaAutoId: selectedPerson.personaAutoId,
        modifiedOn: new Date().toISOString(),
        primaryContact: selectedPerson.primaryContact ? 1 : 0,
      };
      updates.push(newRelation);
  
      // If the new relation is marked as primary,
      // find any other record already marked as primary and unset it.
      if (selectedPerson.primaryContact) {
        const oldPrimaries = currentProjectPeopleList.filter(
          (item) => item.primaryContact === 1 && item.peopleId !== selectedPerson.peopleId
        );

        oldPrimaries.forEach((oldPrimary:any) => {
          // Build a clean payload for the old primary record.
          updates.push({
            projectPersonId: oldPrimary.projectPersonId, // Ensure you're using the correct key (projectPersonId vs projectPeopleId)
            tenantId: oldPrimary.tenantId,
            projectId: oldPrimary.projectId,
            peopleId: oldPrimary.peopleId,
            personaAutoId: oldPrimary.personaAutoId,
            modifiedOn: new Date().toISOString(),
            primaryContact: 0, // unset primary contact
          });
        });
      }
  
      await PersonaService.updateProjectPeopleRelation(updates, tenantId);
      ShowSuccessNotification('Project people relation updated successfully');
      onDismiss(true);
    } catch (error) {
      LoggingService.logError({"MethodName":"EditProjectPeopleRelationModal.saveProjectPeopleRelation", "Message":"Error updating project people relation", "AdditionalData":error});
      ShowErrorNotification('Error updating project people relation', error);
      onDismiss();
    }
  };
  
  const saveBusinessPeopleRelation = async () => {
    try {
      const relationData = {
        businessId: parentId,
        clientBusinessId: 0,
        clientId: selectedPerson.clientId,
        personaId: selectedPerson.personaAutoId,
        primaryContact: selectedPerson.primaryContact ? 1 : 0,
      };
      await PersonaService.createClintRelation([relationData], tenantId);
      ShowSuccessNotification('Business people relation created successfully');
      onDismiss(true);
    } catch (error) {
      LoggingService.logError({"MethodName":"EditProjectPeopleRelationModal.saveProjectPeopleRelation", "Message":"Error creating Business people relation", "AdditionalData":error});
      ShowErrorNotification('Error creating Business people relation', error);
      onDismiss();
    }
  };

  return (
    <Modal
      show={showModal}
      title={`Edit '${selectedPerson?.firstName || ''} ${selectedPerson?.lastName || ''}'`}
      size={'medium'}
      dismissible={false}
      footer={
        <Flex justifyContent='right' width='100%'>
          <Flex justifyContent='flex-end' gap={8}>
            <Button onClick={handleCancel} variant='default'>
              Discard
            </Button>
            <Button
              onClick={handleConfirm}
              type='submit'
              variant='accent'
              color='primary'
              disabled={disableConfirm || props.parentType === PARENT_TYPE_NONE}
            >
              Confirm
            </Button>
          </Flex>
        </Flex>
      }
    >
      {showErrorBanner && ShowValidationBanner('Selected row must have a valid', 'Project role')}
      <DataTableV2
        loading={isLoading}
        sortable
        columns={projectPeopleColumns}
        variant={{ rowDensity: 'comfortable', rowSeparation: 'none', verticalDividers: false, contained: false }}
        data={selectedPerson ? [selectedPerson] : []}
        defaultSortBy={[{ id: 'clientName', desc: false }]}
        resizable
        fullWidth
      >
        <DataTableV2.EmptyState>No person available.</DataTableV2.EmptyState>
      </DataTableV2>
    </Modal>
  );
};
