import { Modal, Tooltip } from '@dynatrace/strato-components-preview/overlays';
import { ColumnType, DataTableV2, DataTableV2ColumnSort } from '@dynatrace/strato-components-preview/tables';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { DownloadIcon, UploadIcon, PlusIcon, FeedbackIcon } from '@dynatrace/strato-icons';
import { Dispatch, useMemo, useRef, useState } from 'react';
import { ProjectService } from '../../../../services/ProjectService';
import '../../../../App.css';
import { useAppInfo } from '../../../../contexts/AppContext';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../../utils/Notifications';
import { format, parseISO } from 'date-fns';
import { Chip, Strong } from '@dynatrace/strato-components-preview';
import { LoadingStateComponent } from '../../../../components/LoadingStateComponent';
import { useParams } from 'react-router-dom';
import CreatePackage from '../../package/CreatePackage';
import { groupPackagesByProjectId, ProjectPackageDetails } from '../project-details-utils';
import { PackageService } from '../../../../services/PackageService';
import { LoggingService } from '../../../../services/LoggingService';
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export interface RequestsProps {
    projectPackages: ProjectPackageDetails[];
    refetchProjectRequests: () => void;
    setLoading: Dispatch<React.SetStateAction<boolean>>;
    peopleList: any;
    onPackageUpdated: any;
    projectDetails: any;
    projectPackagesDrafts: any;
    refetchProjectPackagesDrafts:any;
    handleCreatePackageClick: any;
}

export const PackageView = (props: RequestsProps) => {
    const {
        onPackageUpdated,
        projectPackages,
        refetchProjectRequests,
        setLoading,
        peopleList,
        projectDetails,
        projectPackagesDrafts,
        refetchProjectPackagesDrafts,
        handleCreatePackageClick,
    } = props;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isLoading, setIsLoading] = useState(false);
    const [createPackageSheet, setCreatePackageSheet] = useState(false);
    const [commentModalOpen, setCommentModalOpen] = useState(false);
    const [selectedComment, setSelectedComment] = useState<string | null>(null);
    const [editProjectRequest, setEditProjectRequest] = useState<any>({});
    const { ProjectId } = useParams();
    // eslint-disable-next-line
    const fileInputRefs = useRef<{ [key: number]: (HTMLInputElement | null)[] }>({});
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const { tenantId, documentTypes, tenantRequestStatusList, user } = useAppInfo();
    // const [peopleId, setPeopleId] = useState<any>(null);
    // eslint-disable-next-line
    const [copiedRequestId, setCopiedRequestId] = useState<number | null>(null);
    const [sortBy, setSortBy] = useState([{ id: 'packageId', desc: true }]);
    const handleSortingChange = (sort: DataTableV2ColumnSort[]) => {
        setSortBy(sort);
      };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // useEffect(() => {
    //     const fetchUserData = async () => {
    //         try {
    //             const data = await PeopleService.getPepopleByEmail(user?.email ?? '', tenantId);
    //             setPeopleId(data);
    //         } catch (error) {
    //             console.error("Error fetching user data:", error);
    //         }
    //     };

    //     fetchUserData();
    //     // eslint-disable-next-line
    // }, [user?.email]);

    const openCommentModal = (comment: string | null) => {
        setSelectedComment(comment);
        setCommentModalOpen(true);
    };

    const closeCommentModal = () => {
        setCommentModalOpen(false);
        setSelectedComment(null);
    };

    const handlePackageEditChange = async (requestRow: any) => {
        try {
            // setLoading(true);
            //   const data: any = await ProjectService.getProjectPackageByPackageId(requestRow.projectRequestId, requestRow.projectId, tenantId );
            //   if (data) {

            //   }
            setEditProjectRequest(requestRow);
            setCreatePackageSheet(true);
        }
        catch (err) {
            LoggingService.logError({
                MethodName: 'PackagesView.handlePackageEditChange',
                Message: 'Error loading Request',
                AdditionalData: err
              });
            ShowErrorNotification('Failed to load Request ' + requestRow.packagename, err);
        }
        finally {
            // setLoading(false);
        }


    }
    const downloadPackageClick = async function (rowInfo: any) {
        try {
            const selectedUniqueLinks = rowInfo.assignedTo[0].requestPackages.map((item: any) => item.uniqueLink)
            if (selectedUniqueLinks.length === 1) {
                const downloadFilePath = selectedUniqueLinks[0].split('uploads/')[1];
                window.open(ProjectService.getDownloadFileHeader(downloadFilePath, tenantId));
            } else {
                const { blob } = await PackageService.downloadSelectedPackages(
                    selectedUniqueLinks, rowInfo?.packageName
                );

                const filename = rowInfo?.packageName
                    ? rowInfo?.packageName
                    : 'package.zip';

                const fileUrl = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = fileUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();

                window.URL.revokeObjectURL(fileUrl);
            }
        } catch (error) {
            LoggingService.logError({
                MethodName: 'PackagesView.downloadPackageClick',
                Message: 'Error downloading package',
                AdditionalData: error
              });
            console.error("Error in downloadFileClick:", error);
        }
    };

    // eslint-disable-next-line
    const downloadFileClick = async function (rowInfo: any) {
        try {
            const downloadFilePath = rowInfo.uniqueLink.split('uploads/')[1];
            window.open(ProjectService.getDownloadFileHeader(downloadFilePath, tenantId));
        } catch (error) {
            LoggingService.logError({
                MethodName: 'PackagesView.downloadFileClick',
                Message: 'Error downloading file',
                AdditionalData: error
              });
            console.error("Error in downloadFileClick:", error);
        }
    };
    // eslint-disable-next-line
    const PackagesData = [ ...groupPackagesByProjectId(projectPackages?.toReversed()), ...groupPackagesByProjectId(projectPackagesDrafts?.toReversed())];
    // const testPackage = PackagesData.find(e => e.packageId === 81);
    // if(testPackage){
    //     console.log(testPackage);
    // }

    const packageColumns = [
        {
            id: 'packageId',
            header: 'Package ID',
            width: { type: 'auto', minWidth: 150 },
            accessor: 'packageId',
            columnType: 'number' as ColumnType,
            cell: ({ rowData }: any) => {
                return (
                    <DataTableV2.DefaultCell>{rowData?.packageId}</DataTableV2.DefaultCell>
                );

            }
        },
        {
            id: 'packageName',
            header: 'Package name',
            width: { type: 'auto', minWidth: 300 },
            accessor: 'packageName',
            columnType: 'string' as ColumnType,
            cell: ({ rowData }: any) => (
                <DataTableV2.DefaultCell>
                    <Tooltip placement="bottom" text={rowData?.packageName || 'N/A'}>
                    <Flex>
                    {rowData?.packageName || 'N/A'} 
                    {rowData?.statusName === "inprogress/draft" && <Chip variant='emphasized' style={{ padding: '0px' }} color='success'>Draft</Chip> }
                    </Flex>
                        
                    </Tooltip>
                </DataTableV2.DefaultCell>
            ),
        },
        {
            id: 'modifiedOn',
            header: 'Created on',
            accessor: 'createdOn',
            width: { type: 'auto', minWidth: 200 },
            columnType: 'date' as ColumnType,
            cell: ({ rowData }: any) => (
                <DataTableV2.DefaultCell>{rowData?.createdDate !== null && rowData?.createdDate !== "" ? format(parseISO(rowData?.createdDate), 'MMM dd, yyyy hh:mm a') : 'N/A'}</DataTableV2.DefaultCell>
            ),
        },
        {
            id: 'recipientsCount',
            header: 'Recipients count',
            accessor: 'recipientsCount',
            columnType: 'number' as ColumnType,
            width: { type: 'auto', minWidth: 150 },
            cell: ({ rowData }: any) => {
                return (
                    <DataTableV2.DefaultCell>{rowData?.assignedTo[0].sentToEmailAddress !== null? rowData?.assignedTo.length: 0}</DataTableV2.DefaultCell>
                );

            },
        },
        {
            id: 'actions',
            header: 'Actions',
            width: { type: 'auto', minWidth: 200 },
            accessor: 'packageId',
            cell: ({ rowData }: any) => (
                <DataTableV2.DefaultCell>
                    <Flex justifyContent="flex-start">
                        <Tooltip text="Package comments">
                            <Button
                                onClick={() => openCommentModal(String(rowData.packageUpdateComments))}
                                disabled={
                                    rowData.packageUpdateComments === null
                                    // row.original.downloadFilePath === '' ||
                                    // row.original.downloadFilePath === null
                                    // true
                                }
                            >
                                <Button.Prefix>
                                    <FeedbackIcon />
                                </Button.Prefix>
                            </Button>
                        </Tooltip>
                        <Tooltip text="Download package">
                            <Button
                                onClick={() => downloadPackageClick(rowData)}
                                disabled={
                                    // row.original.downloadFilePath === '' ||
                                    // row.original.downloadFilePath === null
                                    false
                                }
                            >
                                <Button.Prefix>
                                    <DownloadIcon />
                                </Button.Prefix>
                            </Button>
                        </Tooltip>
                    </Flex>
                </DataTableV2.DefaultCell>
            ),
        },
    ];

    // eslint-disable-next-line
    const handleCopy = async (packageRequestURL: string, rowId: number) => {
        const baseUrl = window.location.origin;
        const fullUrl = `${baseUrl}/download/${packageRequestURL}`;
        await navigator.clipboard.writeText(fullUrl);
        setCopiedRequestId(rowId);
        setTimeout(() => setCopiedRequestId(null), 2000);
    };

    // eslint-disable-next-line
    const handleRedirect = async (packageRequestURL: string, rowId: number) => {
        const baseUrl = window.location.origin;
        const fullUrl = `${baseUrl}/download/${packageRequestURL}`;
        window.open(fullUrl, '_blank');
    };

    // console.log("PackagesData",PackagesData)

    const handleFileChange = async (event: any) => {
        const files = Array.from(event.target.files);

        if (files.length > 0) {

            try {
                await uploadFiles(files);
            } catch (error) {
                LoggingService.logError({
                    MethodName: 'PackagesView.handleFileChange',
                    Message: 'Error uploading files',
                    AdditionalData: error
                  });
                console.error('Error uploading files:', error);
            }
        }
    };

    const handleSelectFilesClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const convertFilesToBase64 = async (files: File[]): Promise<{ name: string; size: number; type: string; base64String: string }[]> => {
        return Promise.all(
            files.map((file) => {
                return new Promise<{ name: string; size: number; type: string; base64String: string }>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve({
                            name: file.name,
                            size: file.size,
                            type: file.type,
                            base64String: reader.result as string,
                        });
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            })
        );
    };

    const uploadFiles = async (files: any) => {
        if (files.length === 0) {
            ShowErrorNotification('No files selected', 'Please select files to upload.');
            return;
        }

        setLoading(true);

        try {
            const filesData = await convertFilesToBase64(files);

            const requestDetails = filesData.map((file) => ({
                label: 'Internal staff files',
                documentTypeAutoId: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeAutoId,
                documentTypeName: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeName,
                projectRequestId: 0,
                ProjectId,
                tenantId,
                instructions: 'Internal staff files',
                attachedFiles: [
                    {
                        name: file.name,
                        size: file.size,
                        type: file.type,
                        base64String: file.base64String,
                        projectRequestDetailsFilesId: 0,
                    },
                ],
            }));

            const payload = {
                projectRequestId: 0,
                requestName: 'Internal staff files',
                requestDescription: 'Internal staff files',
                reminderId: 128,
                documentTypeAutoId: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeAutoId,
                documentTypeName: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeName,
                requestDetails: requestDetails,
                document: [],
                requestSendTo: [
                    {
                        sendTo: user?.email,
                        sendCC: '',
                        status: tenantRequestStatusList?.filter((item) => item.statusDescription === "Approved")[0]?.statusAutoId,
                        label: 'Internal staff files',
                        instructions: 'Internal staff files',
                        // peopleId: peopleId,
                        documentTypeAutoId: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeAutoId,
                    },
                ],
                tenantId,
                ProjectId,
                requestUpdateComments: '',
                status: tenantRequestStatusList?.filter((item) => item.statusDescription === "Approved")[0]?.statusAutoId,
            };

            const response: any = await ProjectService.postprojectFiles(payload, tenantId, Number(ProjectId));

            if (response.code === '500') {
                ShowErrorNotification('Upload Failed', response.message || 'An error occurred during upload.');
            } else {
                ShowSuccessNotification('Files Uploaded');
                refetchProjectRequests();
            }
        } catch (error) {
            LoggingService.logError({
                MethodName: 'PackagesView.uploadFiles',
                Message: 'Error uploading files',
                AdditionalData: error
              });
            ShowErrorNotification('Upload Error');
        } finally {
            setLoading(false);
        }
    };

    const sortedPackagesData = useMemo(() => {
        return [...PackagesData]?.sort((a, b) => {
          const idA = a.packageId;
          const idB = b.packageId;
          return sortBy[0]?.desc ? idB - idA : idA - idB;
        });
      }, [PackagesData, sortBy]);
    

    // console.log(projectPackages)

    return (
        <>
            <LoadingStateComponent loading={isLoading} />
            <CreatePackage
                peopleList={peopleList}
                projectDetails={projectDetails}
                editPackageDetails={editProjectRequest}
                onPackageCreated={onPackageUpdated} isOpen={createPackageSheet} onClose={function (): void {
                    setCreatePackageSheet(false);
                }}
                projectPackages={projectPackages}
                refetchProjectPackagesDrafts={refetchProjectPackagesDrafts}
            />
            <Flex flexDirection='column' style={{ overflow: 'auto' }}>

                <Modal
                    show={commentModalOpen}
                    title="Comments"
                    size="small"
                    onDismiss={closeCommentModal}
                >
                    <Flex flexDirection="column" gap={8} width={512}>
                        <Strong>{selectedComment}</Strong>
                        <Flex justifyContent="flex-end">
                            <Button variant="accent" onClick={closeCommentModal} type="button">
                                Close
                            </Button>
                        </Flex>
                    </Flex>
                </Modal>
                <Flex justifyContent="space-between" alignItems="flex-end" style={{ marginBottom: '16px' }}>
                    {/* File Upload Section */}
                    <Flex>
                        <Button onClick={handleSelectFilesClick} style={{ 'visibility': 'hidden' }}>
                            <Button.Prefix>
                                <UploadIcon />
                            </Button.Prefix>
                            Upload
                        </Button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            multiple
                            accept="image/*,application/pdf"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                    </Flex>


                    {/* Create Package Button */}
                    <Flex>
                        <Button
                            variant="accent"
                            color='primary'
                            onClick={handleCreatePackageClick}
                        >
                            <Button.Prefix>
                                <PlusIcon />
                            </Button.Prefix>
                            Create Package
                        </Button>
                    </Flex>
                </Flex>
                <Flex height={400}>
                    <DataTableV2
                        columns={packageColumns as any}
                        data={sortedPackagesData?.length > 0 ? sortedPackagesData : []}
                        sortable
                        sortBy={sortBy}
                        onSortByChange={handleSortingChange}
                        variant={{ rowDensity: 'comfortable', rowSeparation:'none', verticalDividers: false, contained: false }}
                        style={{ marginBottom: '1rem' }}
                        resizable
                        fullWidth
                        interactiveRows
                        onActiveRowChange={(row) => {
                            if (row !== null) {

                                handlePackageEditChange(sortedPackagesData?.[+row]);
                            }
                        }}
                    >
                    </DataTableV2>
                </Flex>
            </Flex>
        </>
    );
};