import { Label } from '@dynatrace/strato-components-preview/forms';
import { Flex } from '@dynatrace/strato-components/layouts';
import React from 'react';
import { Text } from '@dynatrace/strato-components/typography';
import { Borders, Colors } from '@dynatrace/strato-design-tokens';

export interface RequestSummaryProps {
  formValues: any;
  formData: {
    requestPackages : any;
    recipients: any[];
  };
  selectedFiles: File[][];
}

export const PackageSummary = (props: RequestSummaryProps) => {
  const { formValues, formData } = props;

  return (
    <Flex flexDirection='column' gap={0}>
      <h3>Review package:</h3>
      <Flex flexDirection='column' gap={4}>
      <Flex flexDirection='row'>
          <Label>Package name:</Label>
          <Text>{formValues.packageName}</Text>
        </Flex>
        <Flex flexDirection='row'>
          <Label>Package description:</Label>
          <Text>{formValues.packageDescription}</Text>
        </Flex>
        <Flex flexDirection='row'>
          <Label style={{ minWidth: '100px', whiteSpace: 'nowrap' }}>Package message:</Label>
          <Text>{formValues.packageMessage}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection='column' columnGap={0}>
        <h3>Receipents:</h3>
        <Flex flexDirection='row' gap={8}>
          <Flex flexDirection='column' columnGap={0}>
            <Label>Name</Label>
            {formData.recipients.map((recipient, index) => (
              <Text key={'all-recipeient-' + index}>
                {recipient.firstName} {recipient.lastName}
              </Text>
            ))}
          </Flex>
          <Flex flexDirection='column' columnGap={0}>
            <Label>Email</Label>
            {formData.recipients.map((recipient, index) => (
              <Text key={'recipeient-' + index}>{recipient.email}</Text>
            ))}
          </Flex>
        </Flex>
      </Flex>

      <Flex flexDirection="column" gap={16}>
        <h3>List of selected files:</h3>
        {formData.requestPackages.map((section: any, index: any) => (
          <Flex
            key={`package-${index}`}
            flexDirection="column"
            padding={16}
            gap={8}
            style={{
              border: `1px solid ${Colors.Border.Neutral.Default}`,
              borderRadius: Borders.Radius.Field.Default,
            }}
          >
            <Flex flexDirection="row" gap={8}>
              <Label>Document ID:</Label>
              <Text>#{section.projectRequestSentId || 'N/A'}</Text>
            </Flex>
            <Flex flexDirection="row" gap={8}>
              <Label>File name:</Label>
              <Text>{section.fileName || 'N/A'}</Text>
            </Flex>
            <Flex flexDirection="row" gap={8}>
              <Label>Download path:</Label>
              <Text>{section.downloadFilePath || 'N/A'}</Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
