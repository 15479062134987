import { v4 as uuidv4 } from 'uuid';
import { DataTableV2, Modal, SelectV2, Tooltip } from '@dynatrace/strato-components-preview';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { useMemo, useState } from 'react';
import { PlusIcon } from '@dynatrace/strato-icons';
import { NewBusinessModal } from '../../business/NewBusinessModal';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../utils/Notifications';
import { ProjectService } from '../../../services/ProjectService';
import { useAppInfo } from '../../../contexts/AppContext';
import { useBusinessesByTenantId } from '../../../hooks/use-minerva-data';
import { ModalParentType, PARENT_TYPE_NONE } from '../../../types/Types';
import { Business } from '../../../types/Business';
import { ProjectBusinessRelation } from '../../../types/Project';
import { Text } from '@dynatrace/strato-components';
import { LoggingService } from '../../../services/LoggingService';

interface NewProjectBusinessRelationModalProps {
  parentType: ModalParentType;
  onDismiss: (update?: boolean) => void;
  projectId: number;
  currentProjectBusinessList: ProjectBusinessRelation[];
}

export const NewProjectBusinessRelationModal = (props: NewProjectBusinessRelationModalProps) => {
  const { onDismiss, projectId, currentProjectBusinessList } = props;
  const [showModal, setShowModal] = useState(true);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [showNewBusinessModal, setShowNewBusinessModal] = useState<boolean>(false);
  // Instead of using a ref for IDs, we now track the entire selected row with extra properties.
  const [selectedBusinesses, setSelectedBusinesses] = useState<any[]>([]);

  const { tenantId, tenantBusinessCategories } = useAppInfo();

  const { isLoading, error, data: tenantBusinesses, refetch } = useBusinessesByTenantId(tenantId);
  if (error) {
    ShowErrorNotification('Error loading business list', error);
  }

  // Map business category IDs to names.
  const businessCategoryMap = useMemo(() => {
    const map: Record<number, string> = {};
    tenantBusinessCategories?.forEach((category) => {
      map[category.businessCategoryAutoId] = category.businessCategory;
    });
    return map;
  }, [tenantBusinessCategories]);

  // Check if a primary business already exists in the project.
  const isPrimaryBusinessExist = useMemo(() => {
    return currentProjectBusinessList?.some((pbr: any) => pbr.primaryBusiness === 1);
  }, [currentProjectBusinessList]);

  // Define the columns for the data table.
  const projectBusinessColumns = useMemo(
    () => [
      {
        id: 'businessName',
        header: 'Business name',
        accessor: 'businessName',
        width: 300,
      },
      {
        id: 'businessCategory',
        header: 'Business Category',
        accessor: (row: Business) => businessCategoryMap[row.businessCategoryId] || '',
        minWidth: 200,
        autoWidth: true,
      },
      {
        id: 'primaryBusiness',
        header: 'Primary Business',
        width: 150,
        accessor: 'primaryBusiness',
        cell: ({ rowData }: any) => {
          const selected = selectedBusinesses.find((b) => b.businessId === rowData.businessId);
          const isSelected = !!selected;
          const isPrimary = selected?.isPrimaryBusiness || false;
          return (
            <DataTableV2.DefaultCell>
              <Tooltip
                text={
                  isPrimaryBusinessExist
                    ? 'Primary business already exists'
                    : 'Select primary business'
                }
              >
                <SelectV2
                  disabled={!isSelected || isPrimaryBusinessExist}
                  value={isPrimary ? 'true' : 'false'}
                  onChange={(value) => onPrimaryBusinessChange(rowData, value as string)}
                >
                  <SelectV2.Trigger placeholder="Select" />
                  <SelectV2.Content style={{ maxWidth: '300px' }} width="150px">
                    <SelectV2.Option value="true">
                      <Text>Yes</Text>
                    </SelectV2.Option>
                    <SelectV2.Option value="false">
                      <Text>No</Text>
                    </SelectV2.Option>
                  </SelectV2.Content>
                </SelectV2>
              </Tooltip>
            </DataTableV2.DefaultCell>
          );
        },
      }      
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [businessCategoryMap, selectedBusinesses, isPrimaryBusinessExist],
  );

  const handleCancel = () => {
    setShowModal(false);
    onDismiss();
  };

  const handleConfirm = () => {
    saveProjectBusinessRelation();
    setShowModal(false);
    onDismiss();
  };

  // When the dropdown value changes in a row, update the selected business objects.
  const onPrimaryBusinessChange = (row: any, value: string) => {
    const yesSelected = value === 'true';
    const updated = selectedBusinesses.map((b: any) => {
      if (b.businessId === row.businessId) {
        return { ...b, isPrimaryBusiness: yesSelected };
      }
      // If setting one row to Yes, ensure all others are set to false.
      return yesSelected ? { ...b, isPrimaryBusiness: false } : b;
    });
    setSelectedBusinesses(updated);
  };
  

  // When rows are selected/deselected, update the selectedBusinesses state.
  const onRowSelected = (selectedRowsData: any) => {
    // selectedRowsData is an object with row indices as keys and booleans as values.
    const selectedIndices = Object.keys(selectedRowsData)
      .filter((key) => selectedRowsData[key])
      .map((key) => parseInt(key, 10));

    // Filter out businesses already associated with the project.
    const availableBusinesses = tenantBusinesses
      ? tenantBusinesses.filter(
          (business: Business) =>
            !currentProjectBusinessList.some(
              (pbr: ProjectBusinessRelation) => pbr.businessId === business.businessId,
            ),
        )
      : [];

    // Map indices to business rows.
    const selectedRows = selectedIndices.map((index) => availableBusinesses[index]);

    // Retain already selected rows that remain selected.
    let updatedSelectedBusinesses = selectedBusinesses.filter((b: any) =>
      selectedRows.some((row: any) => row.businessId === b.businessId),
    );
    // Add any new selections with default primary flag set to false.
    selectedRows.forEach((row: any) => {
      if (!updatedSelectedBusinesses.find((b: any) => b.businessId === row.businessId)) {
        updatedSelectedBusinesses.push({ ...row, isPrimaryBusiness: false });
      }
    });

    setSelectedBusinesses(updatedSelectedBusinesses);
    setDisableConfirm(updatedSelectedBusinesses.length === 0);
  };

  // Save the new project–business relations, including the primary business flag.
  const saveProjectBusinessRelation = async () => {
    try {
      const requestData = selectedBusinesses.map((business: any) => ({
        projectBusinessId: 0,
        tenantId: tenantId,
        projectId: projectId,
        businessId: business.businessId,
        primaryForLoan: 0,
        primaryBusiness: business.isPrimaryBusiness ? 1 : 0,
        modifiedOn: new Date().toISOString(),
        modifiedBy: '',
      }));
      await ProjectService.postProjectBusinessesRelationWithDetails(requestData, tenantId);
      ShowSuccessNotification('Project business relation created successfully');
      onDismiss(true);
    } catch (error) {
      LoggingService.logError({"MethodName":"NewProjectBusinessRelationModal.saveProjectBusinessRelation", "Message":"Error creating project business relation", "AdditionalData":error});
      ShowErrorNotification('Error creating project business relation', error);
      onDismiss();
    }
  };

  return (
    <Modal
      show={showModal}
      title={'Add business to this project'}
      size={'medium'}
      dismissible={false}
      footer={
        <Flex justifyContent="space-between" width="100%">
          <Button
            onClick={() => {
              setShowNewBusinessModal(true);
            }}
            variant="emphasized"
            color="neutral"
          >
            <Button.Prefix>
              <PlusIcon />
            </Button.Prefix>
            New business
          </Button>
          <Flex justifyContent="flex-end" gap={8}>
            <Button onClick={handleCancel} variant="default">
              Discard
            </Button>
            <Button
              onClick={handleConfirm}
              type="submit"
              variant="accent"
              color="primary"
              disabled={disableConfirm || props.parentType === PARENT_TYPE_NONE}
            >
              Confirm
            </Button>
          </Flex>
        </Flex>
      }
    >
      <DataTableV2
        loading={isLoading}
        sortable
        columns={projectBusinessColumns}
        data={
          tenantBusinesses
            ? tenantBusinesses.filter(
                (business: Business) =>
                  !currentProjectBusinessList.some(
                    (pbr: ProjectBusinessRelation) => pbr.businessId === business.businessId,
                  ),
              )
            : []
        }
        variant={{
          rowDensity: 'comfortable',
          rowSeparation: 'none',
          verticalDividers: false,
          contained: false,
        }}
        style={{ overflowY: 'auto' }}
        fullWidth
        interactiveRows
        defaultSortBy={[{ id: 'businessName', desc: false }]}
        selectableRows
        onRowSelectionChange={onRowSelected}
      >
        <DataTableV2.EmptyState>No businesses available.</DataTableV2.EmptyState>
      </DataTableV2>
      {showNewBusinessModal && (
        <NewBusinessModal
          key={uuidv4()}
          onDismiss={(refresh) => {
            setShowNewBusinessModal(false);
            if (refresh) {
              refetch();
            }
          }}
        />
      )}
    </Modal>
  );
};
