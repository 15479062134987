import { Flex } from "@dynatrace/strato-components";
import { DateTimePicker, FormField, Label, SelectV2, TextInput } from "@dynatrace/strato-components-preview";
import { Controller } from "react-hook-form";
import { extendedProfileAttribute } from "../../types/Person";
import { formatPhoneNumberForDisplay } from "../../utils/Utilities";

export interface ProfessionalInformationProps {
    control: any;
    extendedProfile: extendedProfileAttribute[] | undefined;
}

export const ProfessionalInformation = (props: ProfessionalInformationProps) => {

    const {
        control,
        extendedProfile
    } = props
    return (
        <Flex flexDirection='column' width={512} minWidth={512} gap={16}>
            {/* <Controller
                name='jobTitle'
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <FormField>
                        <Label>Job title</Label>
                        <TextInput
                            placeholder='Enter job title'
                            controlState={{
                                state: error ? 'error' : 'valid',
                                hint: error?.message,
                            }}
                            {...field}
                        />
                    </FormField>
                )}
            />
            <Controller
                name='companyName'
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <FormField>
                        <Label>Company name</Label>
                        <TextInput
                            placeholder='Enter company name'
                            controlState={{
                                state: error ? 'error' : 'valid',
                                hint: error?.message,
                            }}
                            {...field}
                        />
                    </FormField>
                )}
            />

            <Controller
                name='department'
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <FormField>
                        <Label>Department</Label>
                        <TextInput
                            placeholder='Enter department'
                            controlState={{
                                state: error ? 'error' : 'valid',
                                hint: error?.message,
                            }}
                            {...field}
                        />
                    </FormField>
                )}
            />
            <Controller
                name='workMobile'
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <FormField>
                        <Label>Work phone number</Label>
                        <TextInput
                            pattern='/^\d{0,3}-?\d{0,2}-?\d{0,4}$/'
                            type='tel'
                            placeholder='Enter work phone number'
                            onKeyDown={(e) => {
                                !/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete'
                                    ? e.preventDefault()
                                    : void 0;
                            }}
                            controlState={{
                                state: error ? 'error' : 'valid',
                                hint: error?.message,
                            }}
                            {...field}
                        />
                    </FormField>
                )}
            />
            <Controller
                name='workEmail'
                control={control}
                rules={{
                    validate: (value) => /\S+@\S+\.\S+/.test(value) || 'Please enter a valid email address.',
                }}
                render={({ field, fieldState: { error } }) => (
                    <FormField>
                        <Label>Work email address</Label>
                        <TextInput
                            type='email'
                            // maxLength={11}
                            // onKeyDown={(e) => {
                            //   !/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete'
                            //     ? e.preventDefault()
                            //     : void 0;
                            // }}
                            placeholder='Enter work email address'
                            controlState={{
                                state: error ? 'error' : 'valid',
                                hint: error?.message,
                            }}
                            {...field}
                        />
                    </FormField>
                )}
            /> */}
            {control &&
                extendedProfile?.map((attribute, index) =>
                    attribute.attributeSection === 'ProfessionalInformation' ? (
                        <Controller
                            rules={
                                attribute.attributeType === 'email'
                                    ? {
                                        validate: (value) =>
                                            /\S+@\S+\.\S+/.test(value) || 'Please enter a valid email address.',
                                    }
                                    : attribute.attributeType === 'number'
                                        ? {
                                            validate: (value: string) => {
                                                const cleaned = value.replace(/\D/g, '');
                                                return cleaned.length === 10 || 'Please enter a valid 10-digit phone number.';
                                            }
                                        }
                                        : {}
                            }
                            key={attribute.attributeId}
                            control={control}
                            name={`ExtendedProfileFields.${index}.attributeValue`}
                            render={({ field, fieldState: { error } }) => (
                                <FormField>
                                    <Label>{attribute.attributeName}</Label>
                                    <Flex onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault(); // Prevent form submission
                                        }
                                    }}>
                                        {attribute.attributeType === 'select' ? (
                                            <SelectV2
                                                onChange={(selectedValue) => field.onChange(selectedValue)}
                                            >
                                                <SelectV2.Content>
                                                    {attribute.attributeOptions?.map((option) => (
                                                        <SelectV2.Option key={option.attributeOptionAutoId} value={option.attributeOptionAutoId.toString()}>
                                                            {option.attributeOptionName}
                                                        </SelectV2.Option>
                                                    ))}
                                                </SelectV2.Content>
                                            </SelectV2>
                                        ) : attribute.attributeType === 'dateTime' ? (
                                            <DateTimePicker type="time" {...field} />
                                        ) : attribute.attributeType === 'number' ? (
                                            <TextInput
                                                placeholder={`Enter ${attribute.attributeName}`}
                                                controlState={{
                                                    state: error ? 'error' : 'valid',
                                                    hint: error?.message,
                                                }}
                                                value={formatPhoneNumberForDisplay(field.value || '')}
                                                onChange={(value: string, event: React.ChangeEvent<HTMLInputElement>) => {
                                                    const cleaned = value.replace(/\D/g, '').slice(0, 10);
                                                    field.onChange(cleaned);
                                                }}
                                            />
                                        ) : (
                                            <TextInput
                                                placeholder={`Enter ${attribute.attributeName}`}
                                                controlState={{
                                                    state: error ? 'error' : 'valid',
                                                    hint: error?.message,
                                                }}
                                                {...field}
                                            />
                                        )}
                                    </Flex>
                                </FormField>
                            )}
                        />
                    ) : null
                )}
        </Flex>
    )
}