import {
  Chip,
  FormField,
  Label,
  Modal,
  Sheet,
  TextArea,
  TextInput,
  TitleBar,
  Tooltip,
} from '@dynatrace/strato-components-preview';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { Borders, Colors } from '@dynatrace/strato-design-tokens';
import { DeleteIcon } from '@dynatrace/strato-icons';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm, useFieldArray, useWatch } from 'react-hook-form';
import { RequestTemplate, RequestTemplateDetails } from '../../types/RequestTemplate';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { TemplateService } from '../../services/TemplateService';
import { useAppInfo } from '../../contexts/AppContext';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { hideElement } from '../projects/ProjectStyles.css';
import { LoggingService } from '../../services/LoggingService';

interface RequestTemplateSheetProps {
  onTemplateClose: (tenant: number) => void;
  close: () => void;
  show: boolean;
  selectedTemplateId?: number | null;
  templates?: any;
}

// Functional component definition
const RequestTemplateSheet: React.FC<RequestTemplateSheetProps> = ({
  close,
  show,
  selectedTemplateId,
  onTemplateClose,
  templates
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { tenantId, documentTypes, tenantRequestReminders } = useAppInfo();

  console.log(templates, 'templates');

  const [selectedFiles, setSelectedFiles] = useState<File[][]>([[]]); // Array of file arrays for each section
  const fileInputRefs = useRef<HTMLInputElement[]>([]); // Array of file input refs
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [requestTemplate, setRequestTemplate] = useState<RequestTemplate | null>(null);

  useEffect(() => {
    if (selectedTemplateId && selectedTemplateId !== null) {
      setLoading(true);
      TemplateService.getRequestDetailsById<RequestTemplate>(selectedTemplateId, tenantId)
        .then((data) => {
          setRequestTemplate(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching template:', error);
          ShowErrorNotification('Error fetching template', error);
          LoggingService.logError({
            MethodName: 'RequestTemplateSheet.useEffect',
            Message: 'Error fetching template',
            AdditionalData: error,
          });
          setLoading(false);
        })
    }
  }, [selectedTemplateId, tenantId]);

  const otherDocumentTypeId = documentTypes?.find(
    (docType) => docType.documentTypeId === -1 && docType.tenantId === tenantId,
  )?.documentTypeAutoId;

  const { handleSubmit, control, reset } = useForm<{
    requestTemplateName: string;
    requestTemplateDescription: string;
    requestMessage: string;
    requestDescription: string;
    reminder: number;
    requestDetails: RequestTemplateDetails[] | any;
  }>({
    mode: undefined,
    defaultValues: {
      requestDetails: [
        {
          requestTemplateDetailsId: -1,
          requestTemplateId: -1,
          label: '',
          documentTypeAutoId: otherDocumentTypeId,
          tenantId: -1,
          documentTypeName: '',
          instructions: '',
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'requestDetails',
  });

  const updatedFields = useWatch({
    control,
    name: 'requestDetails',
  });

  // UseEffect to update form values when externalData changes
  useEffect(
    () => {
      if (requestTemplate) {
        updateRequestTemplate();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requestTemplate],
  );

  const findSize = (base64: string) => {
    // Calculate the padding
    const padding = (base64.match(/=*$/) || [])[0]?.length;

    // Calculate the size in bytes
    const fileSizeInBytes = (base64.length * 3) / 4 - (padding ? padding : 0);
    return fileSizeInBytes;
  };

  const updateRequestTemplate = () => {
    if (requestTemplate) {
      // initialize values from the RequestTemplate
      reset({
        requestTemplateName: requestTemplate.requestTemplateName,
        requestTemplateDescription: requestTemplate.requestTemplateDescription,
        requestMessage: requestTemplate.requestMessage,
        requestDescription: requestTemplate.requestDescription,
        reminder: requestTemplate.remindersAutoId,
        requestDetails: requestTemplate.requestTemplateDetails?.map((detail: any) => ({
          label: detail.label,
          documentTypeAutoId: detail.documentTypeAutoId,
          requestTemplateDetailsId: detail.requestTemplateDetailsId, // Ensure this is included
          requestTemplateId: detail.requestTemplateId,
          tenantId: detail.tenantId,
          documentTypeName: detail.documentTypeName,
          instructions: detail.instructions || '',
        })),
      });

      requestTemplate.requestTemplateDetails?.forEach((details: any, index: number) => {
        // Update instructions if they exist
        if (details.instructions) {
          // Handle the logic to update the instruction in your UI here
        }

        // Check if attachedFiles exist
        if (details.requestTemplateDetailsFiles && details.requestTemplateDetailsFiles.length > 0) {
          // Update selectedFiles based on the attached files for this section
          const attachedFiles = details.requestTemplateDetailsFiles.map((file: any) => ({
            name: file.filePath.split(details.requestTemplateDetailsId + '/')[1],
            size: findSize(file.fileData),
            type: file.type,
            base64String: file.fileData,
          }));

          setSelectedFiles((prevSelectedFiles) => {
            const updatedSelectedFiles = [...prevSelectedFiles];
            updatedSelectedFiles[index] = attachedFiles;
            return updatedSelectedFiles;
          });
        } else {
          // If no attached files, set an empty array
          setSelectedFiles((prevSelectedFiles) => {
            const updatedSelectedFiles = [...prevSelectedFiles];
            updatedSelectedFiles[index] = [];
            return updatedSelectedFiles;
          });
        }
      });
    } else {
    }
  };

  const handleTemplateDetailsOpen = async (values: any) => {
    setTemplateModalOpen(true)
  };

  const handleAddMore = () => {
    append({
      label: '',
      instructions: '',
      requestTemplateDetailsId: -1,

      requestTemplateId: selectedTemplateId !== null && selectedTemplateId !== undefined ? selectedTemplateId : -1,
      documentTypeAutoId: otherDocumentTypeId,
      tenantId: tenantId,
      documentTypeName: '',
    });
    setSelectedFiles((prevFiles) => [...prevFiles, []]); // Add an empty array for new section files
  };

  const handleAddDuplicate = (index: number) => {
    append({
      ...updatedFields[index],
    });

    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.push([...prevFiles[index]]);
      return updatedFiles;
    });
  };

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const saveDetails = async (values: any) => {
    const { requestTemplateName, requestTemplateDescription, requestMessage, requestDescription, reminder, requestDetails } = values;

    const base64FilesPromises = selectedFiles.map((fileList) =>
      Promise.all(
        fileList.map(async (file: any) => {
          let base64 = '';
          if (file.base64String) {
            base64 = file.base64String;
          } else {
            base64 = await getBase64(file);
          }

          return {
            name: file.name,
            size: file.size,
            type: file.type,
            base64String: base64, // base64 encoded string
            requestTemplateDetailsId: file.requestTemplateDetailsId,
            requestTemplateDetailsFilesId: file.requestTemplateDetailsFilesId,
            filePath: file.filePath,
          };
        }),
      ),
    );

    const base64Files = await Promise.all(base64FilesPromises);

    let formInit = {
      requestTemplateId: 0,
      requestTemplateName: '',
      requestTemplateDescription: '',
      requestMessage: '',
      requestDescription: '',
      tenantId: 0,
      remindersAutoId: tenantRequestReminders?.find((reminder) => reminder.details === '7d')?.remindersAutoId,
      requestTemplateDetails: [
        {
          documentTypeAutoId: 1,
          label: '',
          instructions: '',
          requestTemplateId: 0,
          requestTemplateDetailsId: 0,
          tenantId: tenantId,
          attachedFiles: [],
        },
      ],
    };
    // let isValid = true;
    for (let i = 0; i < requestDetails.length; i++) {
      if (!requestDetails[i].label || requestDetails[i].label.trim() === '') {
        // isValid = false;
        ShowErrorNotification('Invalid Document Types');
        return;
      } else if (requestDetails[i].documentTypeAutoId === 1) {
        requestDetails[i].documentTypeAutoId = otherDocumentTypeId;
      }
    }
    formInit.requestTemplateName = requestTemplateName;
    formInit.requestTemplateDescription = requestTemplateDescription;
    formInit.requestMessage = requestMessage;
    formInit.requestDescription = requestDescription;
    formInit.remindersAutoId = reminder;
    formInit.tenantId = tenantId;

    if (requestTemplate !== undefined && requestTemplate?.requestTemplateId !== undefined) {
      try {
        setLoading(true);
        const newRequestDetails = requestDetails.map((rd: any, index: number) => ({
          ...rd, // Spread the existing properties of the requestDetails object
          attachedFiles: base64Files[index] || [], // Assign the base64 files for this particular index
        }));
        formInit.requestTemplateId = requestTemplate?.requestTemplateId;

        formInit.requestTemplateDetails = newRequestDetails.map((rd: any, index: number) => ({
          label: rd.label,
          documentTypeAutoId: rd.documentTypeAutoId,
          instructions: rd.instructions,
          requestTemplateId: rd.requestTemplateId,
          requestTemplateDetailsId: rd.requestTemplateDetailsId,
          attachedFiles: base64Files[index] || [], // Attach base64 files
        }));

        // Update the request template itself
        const rtData: any = await TemplateService.updateRequestTemplates(formInit, tenantId);
        if (rtData) {
        }

        ShowSuccessNotification(values.requestTemplateName + ' successfully updated.');
        onTemplateClose(tenantId);
      } catch (error) {
        LoggingService.logError({ "MethodName": "RequestTemplateSheet.saveDetails", "Message": "Error updating template", "AdditionalData": error });
        ShowErrorNotification('Error updating template', error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        formInit.requestTemplateDetails = requestDetails.map((rd: any, index: number) => ({
          label: rd.label,
          documentTypeAutoId: rd.documentTypeAutoId,
          instructions: rd.instructions,
          attachedFiles: base64Files[index] || [], // Attach base64 files
        }));
        const data: any = await TemplateService.postRequestTemplates(formInit, tenantId);
        if (data) {
          reset({
            requestTemplateName: '',
            requestTemplateDescription: '',
            requestMessage: '',
            requestDescription: '',
            reminder: 0,
            requestDetails: [
              {
                label: '',
                documentTypeAutoId: otherDocumentTypeId,
                instructions: '',
              },
            ],
          });
        }
        ShowSuccessNotification(values.requestTemplateName + ' successfully created.');
        onTemplateClose(tenantId);
      } catch (error) {
        LoggingService.logError({ "MethodName": "RequestTemplateSheet.saveDetails", "Message": "Error creating template", "AdditionalData": error });
        ShowErrorNotification('Error creating template', error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Handle file selection
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const files = Array.from(event.target.files || []);
    setSelectedFiles((prevFiles) => {
      const updatedFiles = prevFiles.map((fileList, i) => (i === index ? [...fileList, ...files] : fileList));
      return updatedFiles; // Return the updated state
    });
  };

  // Handle file removal
  const handleFileRemove = (fileToRemove: File, index: number) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.map((fileList, i) => (i === index ? fileList.filter((file: File) => file !== fileToRemove) : fileList)),
    );
  };

  const removeFileSection = (sectionIndex: number) => {
    setSelectedFiles((prevSelectedFiles) => {
      // Create a copy of `selectedFiles`
      const updatedFiles = [...prevSelectedFiles];

      // Remove the entire section's files based on `sectionIndex`
      updatedFiles.splice(sectionIndex, 1);

      // Return the updated array without the section's files
      return updatedFiles;
    });
  };

  // Open file browser for specific section
  const handleBrowseClick = (index: number) => {
    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index].click();
    }
  };

  return (
    <Sheet show={show} onDismiss={close}>
      <LoadingStateComponent loading={loading} />
      <Modal show={templateModalOpen} title="Save as template" size="small" onDismiss={() => setTemplateModalOpen(false)}>
        <form onSubmit={handleSubmit(saveDetails)} noValidate>
          <Flex flexDirection="column" gap={16} width={512}>
            <Controller
              name="requestTemplateName"
              control={control}
              rules={{
                required: 'Template name is required',
                maxLength: {
                  value: 45,
                  message: 'Template name cannot have more than 45 characters.',
                },
                validate: (value: string) => {
                  const isDuplicate = templates?.some((template: any) => {
                    if (requestTemplate && template.requestTemplateId === requestTemplate.requestTemplateId) {
                      return false;
                    }
                    return template.requestTemplateName.toLowerCase() === value.toLowerCase();
                  });
                  return isDuplicate ? 'Template name must be unique' : true;
                },
              }}
              render={({ field, fieldState }) => (
                <FormField required>
                  <Label>Template name</Label>
                  <TextInput
                    {...field}
                    placeholder="Enter your template name"
                    controlState={{
                      state: fieldState.error ? 'error' : 'valid',
                      hint: fieldState.error?.message,
                    }}
                  />
                </FormField>
              )}
            />


            <Controller
              name="requestTemplateDescription"
              control={control}
              rules={{
                maxLength: {
                  value: 2000,
                  message: 'Template description cannot have more than 2000 characters.',
                },
              }}
              render={({ field, fieldState }) => (
                <FormField>
                  <Label>Template description</Label>
                  <TextInput
                    {...field}
                    placeholder="Enter your template description"
                    controlState={{
                      state: fieldState.error ? 'error' : 'valid',
                      hint: fieldState.error?.message,
                    }}
                  />
                </FormField>
              )}
            />

            <Flex justifyContent="flex-end" gap={8}>
              <Button variant="default" onClick={() => setTemplateModalOpen(false)} type="button">
                Cancel
              </Button>
              <Button variant="emphasized" type="submit">
                Save
              </Button>
            </Flex>
          </Flex>
        </form>
      </Modal>
      <LoadingStateComponent loading={loading} />
      <form onSubmit={handleSubmit(handleTemplateDetailsOpen)} onReset={() => reset()} noValidate>
        <TitleBar>
          <TitleBar.Title>
            {requestTemplate ? '' + requestTemplate.requestTemplateName : 'Create Request Template'}
          </TitleBar.Title>
          <TitleBar.Subtitle>
            {requestTemplate ? 'Edit and manage tenant template' : 'Create a new tenant template'}
          </TitleBar.Subtitle>
          <TitleBar.Action style={{ display: 'flex', alignItems: 'center' }}>
            <Flex flexDirection='row' gap={8}>
              <Button
                width='75px'
                onClick={() => {
                  close();
                  // dismissDetails();
                }}
                variant='default'
              >
                Discard
              </Button>
              <Button width='60px' type='submit' variant='accent' color='primary' disabled={false}>
                Save
              </Button>
            </Flex>
          </TitleBar.Action>
        </TitleBar>

        <Flex flexDirection='column' margin={8} padding={0} gap={8}>
          <Flex flexDirection='column' width={'90%'} minWidth={325} gap={16}>
            <Controller
              name='requestDescription'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Request name is required',
                },
                minLength: {
                  value: 1,
                  message: '',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <FormField required>
                  <Label>Request name</Label>
                  <TextInput
                    {...field}
                    value={field.value}
                    style={{ width: '80%' }}
                    placeholder='Enter your request name'
                    // onChange={(value: any) => handleChange('requestName', value)}

                    controlState={{
                      state: error ? 'error' : 'valid',
                      hint: error?.message || '',
                    }}
                  />
                </FormField>
              )}
            />
            <Controller
              name='requestMessage'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Message is required',
                },
                minLength: {
                  value: 1,
                  message: '',
                },
                maxLength: {
                  value: 4000,
                  message: 'Request message cannot have more than 4000 characters.',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <FormField required>
                  <Label>Request message</Label>
                  <TextArea
                    {...field}
                    value={field.value}
                    placeholder='Enter your message'
                    // onChange={(value: any) => handleChange('requestDescription', value)}
                    width={'80%'}
                    controlState={{
                      state: error ? 'error' : 'valid',
                      hint: error?.message || '',
                    }}
                  />
                </FormField>
              )}
            />
            {/* 
            <Controller
              name='reminder'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Please select a reminder.',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <FormField required>
                  <Label>Reminder</Label>
                  <Flex
                    onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                      if (event.key === 'Enter') {
                        event.preventDefault(); // Prevent form submission
                      }
                    }}
                  >
                    <SelectV2
                      aria-label='Reminder'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: isArray(error) ? error.join('\n') : error?.message,
                      }}
                      {...field}
                    >
                      <SelectV2.Trigger placeholder={'Reminder'} />

                      <SelectV2.Content style={{ maxWidth: '500px' }} width='max-content'>
                        {tenantRequestReminders?.map((option) => (
                          <SelectV2.Option key={option.remindersAutoId} value={option.remindersAutoId}>
                            {option.details}
                          </SelectV2.Option>
                        ))}
                        <SelectV2.Option
                          // key={index}
                          value={'none'}
                        >
                          none
                        </SelectV2.Option>
                      </SelectV2.Content>
                    </SelectV2>
                  </Flex>
                </FormField>
              )}
            /> */}
            {fields.map((field, index) => (
              <Flex
                key={field.id}
                flexDirection='row'
                width={'100%'}
                style={{
                  border: `1px solid ${Colors.Border.Neutral.Default}`,
                  borderRadius: Borders.Radius.Field.Default,
                }}
                gap={12}
                padding={12}
              >
                <Controller
                  name={`requestDetails.${index}.requestTemplateDetailsId`}
                  control={control}
                  defaultValue={field.id}
                  render={({ field }) => <input type='hidden' {...field} />}
                />
                <Flex flexDirection='column' width={'100%'} gap={16}>
                  <Controller
                    name={`requestDetails.${index}.label`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Document Name is required',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField required>
                        <Label>File Name</Label>
                        <TextInput
                          {...field}
                          value={field.value}
                          style={{ width: '100%' }}
                          placeholder='Enter Document name'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message || '',
                          }}
                        />
                      </FormField>
                    )}
                  />

                  <Controller
                    name={`requestDetails.${index}.instructions`}
                    control={control}
                    // rules={{
                    //   required: {
                    //     value: true,
                    //     message: 'Instruction is required',
                    //   },
                    // }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField>
                        <Label>Instructions</Label>
                        <TextArea
                          {...field}
                          value={field.value}
                          width={'100%'}
                          placeholder='Enter instructions'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message || '',
                          }}
                        />
                      </FormField>
                    )}
                  />
                  {/* <Controller
                        name={`requestDetails.${index}.documentTypeAutoId`}
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Please choose an option.',
                          },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <FormField required>
                            <Label>File type</Label>
                            <Flex
                              onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                                if (event.key === 'Enter') {
                                  event.preventDefault(); // Prevent form submission
                                }
                              }}
                            >
                              <SelectV2
                                {...field}
                                value={field.value}
                                controlState={{
                                  state: error ? 'error' : 'valid',
                                  hint: error?.message || '',
                                }}
                              >
                                <SelectV2.Content width={'auto'}>
                                  {documentTypes.current.map((option) => (
                                    <SelectV2.Option key={option.documentTypeAutoId} value={option.documentTypeAutoId}>
                                      {option.documentTypeName}
                                    </SelectV2.Option>
                                  ))}
                                </SelectV2.Content>
                              </SelectV2>
                            </Flex>
                          </FormField>
                        )}
                      /> */}
                  {selectedFiles[index] && selectedFiles[index].length > 0 && (
                    <Flex flexDirection='row' rowGap={8} flexWrap='wrap'>
                      {selectedFiles[index].map((file) => (
                        <Tooltip text={file.name}>
                          <Chip variant='emphasized' color='primary'>
                            {`${file.name} (${(file.size / 1024).toFixed(2)} kB)`}
                            <Chip.DeleteButton onClick={() => handleFileRemove(file, index)} />
                          </Chip>
                        </Tooltip>
                      ))}
                    </Flex>
                  )}
                  <Flex flexDirection='column'>
                    {/* Render selected files for the current index */}
                    <Flex justifyContent="flex-end">
                      <Button
                        variant='emphasized'
                        aria-label='Duplicate'
                        onClick={() => handleAddDuplicate(index)}
                      >
                        Duplicate
                      </Button>
                      <Button variant='emphasized' color='primary' onClick={() => handleBrowseClick(index)}>
                        Attach files
                      </Button>
                    </Flex>
                    <input
                      type='file'
                      ref={(el) => (fileInputRefs.current[index] = el!)} // Use refs for each input
                      className={hideElement}
                      onChange={(event) => handleFileChange(event, index)}
                      multiple // Allow multiple file selection
                      title='Attach files'
                    />
                  </Flex>
                </Flex>
                {index !== 0 && (
                  <Flex justifyContent='flex-end' padding={16} gap={4}>
                    <Tooltip text={'Delete Document'}>
                      <Button
                        variant='emphasized'
                        aria-label='Remove'
                        onClick={() => {
                          remove(index);
                          removeFileSection(index);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  </Flex>
                )}
              </Flex>
            ))}
            <Flex flexDirection='column' gap={8}>
              <Button style={{ color: Colors.Text.Primary.Default }} onClick={handleAddMore}>
                Add more
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </form>
      <Flex></Flex>
    </Sheet>
  );
};

export default RequestTemplateSheet;
