import { v4 as uuidv4 } from 'uuid';
import {
  DataTableV2,
  DataTableV2ColumnSort,
  DateTimePicker,
  FormField,
  Label,
  SelectV2,
  Sheet,
  Tab,
  TableRowActions,
  Tabs,
  TextInput,
  TitleBar
} from '@dynatrace/strato-components-preview';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ItemInfo } from '../../types/ListItemInfo';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { extendedProfileAttribute, Person } from '../../types/Person';
import { PeopleService } from '../../services/PeopleService';
import { Business, BusinessPersona } from '../../types/Business';
import { useAppInfo } from '../../contexts/AppContext';
import {
  BusinessAnalyticsSignetIcon,
  CallIcon,
  ClockIcon,
  DeleteIcon,
  DescriptionIcon,
  FeedbackIcon,
  LinkIcon,
  PlusIcon,
  UnfoldMoreIcon,
} from '@dynatrace/strato-icons';
import { groupHeaderFontStyle } from '../GeneralStyles.css';
import { PARENT_TYPE_PERSON } from '../../types/Types';
import { NewPeopleBusinessRelationModal } from './NewPeopleBusinessRelationModal';
import { ProfileDetails } from './ProfileDetails';
import { ContactDetails } from './ContactDetails';
import { ProfessionalInformation } from './ProfessionalInformation';
import { CommunicationPreferences } from './CommunicationPreferences';
import { SocialMediaPresence } from './SocialMediaPresence';
import { LoggingService } from '../../services/LoggingService';
import { CommentsSection } from '../CommentSection';
export interface PersonSheetProps {
  closeDetails: (itemInfo?: ItemInfo) => void;
  itemInfo: ItemInfo | undefined;
  show: boolean;
  tenantExtendedAttributesList: extendedProfileAttribute[] | undefined;
}
type extendedProfile = {
  peopleId: number;
  attributeName: string;
  attributeId: number;
  tenantId: number;
  attributeValue: string;
}
type PeopleFormValues = {
  firstName: string;
  lastName: string;
  middleName: string;
  mobile: string;
  secondaryMobile: string;
  email: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: undefined | number;
  zip: string;
  dob: string;
  ssn: string;
  gender: string;

  maritalStatus: number;
  jobTitle: string;
  companyName: string;
  department: string;
  workMobile: string;
  workEmail: string;
  contactMethod: string;
  contactTime: string;
  linkedInProfile: string;
  ExtendedProfileFields: extendedProfile[];
};

export const PersonSheet = (props: PersonSheetProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingBusinessRelations, setLoadingBusinessRelations] = useState<boolean>(false);
  const workingOnCall = useRef<boolean>(false);
  const { user } = useAppInfo();
  const [showExistingBusinessModal, setShowExistingBusinessModal] = useState<boolean>(false);

  const { closeDetails, show, itemInfo, tenantExtendedAttributesList } = props;

  const [itemVisible, setItemVisible] = useState(show);
  const personDetails = useRef<BusinessPersona>();
  const [businessList, setBusinessList] = useState<Business[] | undefined>([]);
  const person = useRef<Person>();
  const { tenantId, userId, tenantStateList, tenantBusinessCategories } = useAppInfo();
  const [sortBy, setSortBy] = useState([{ id: 'businessName', desc: false }]);
  const handleSortingChange = (sort: DataTableV2ColumnSort[]) => {
    setSortBy(sort);
  };

  const businessCategoryMap = useMemo(() => {
    const map: Record<number, string> = {};
    tenantBusinessCategories?.forEach((category) => {
      map[category.businessCategoryAutoId] = category.businessCategory;
    });
    return map;
  }, [tenantBusinessCategories]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const peopleBusinessColumns = useMemo(
    () => [
      {
        id: 'businessName',
        header: 'Business name',
        accessor: 'businessName',
        width: { type: 'auto', minWidth: 300 },
      },
      {
        id: 'businessCategory',
        header: 'Business category',
        accessor: (row: Business) =>
          businessCategoryMap[row.businessCategoryId] || '',
        width: { type: 'auto', minWidth: 200 }
      },
      {
        id: 'personaName',
        header: 'Business role',
        accessor: 'personaName',
        width: { type: 'auto', minWidth: 200 },
        cell: ({ rowData }: any) => <DataTableV2.DefaultCell>{rowData.personas.personaName}</DataTableV2.DefaultCell>,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tenantBusinessCategories],
  );

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    setValue,
    formState: { isSubmitSuccessful },
  } = useForm<PeopleFormValues>({
    mode: 'all',
    defaultValues: {
      ExtendedProfileFields: tenantExtendedAttributesList
    },
  });



  // discarded or closed the sheet from escape key
  const dismissDetails = () => {
    closeDetails();
  };

  // get all the other required data for the edit
  // and creating new for loading dropdown data
  const getRequiredData = async () => {
    setLoading(true);
    try {
      // if not its a new project
      if (itemInfo && itemInfo.id) {
        personDetails.current = await PeopleService.getBusinessDetailsById<BusinessPersona>(itemInfo.id, tenantId);
        setBusinessList(personDetails.current.businesses);
        person.current = personDetails.current.client;
      }
    } catch (error) {
      ShowErrorNotification('Error fetching business person data', error);
      LoggingService.logError({
        MethodName: 'PersonSheet.getRequiredData',
        Message: 'Error fetching business person data',
        AdditionalData: error
      });
      setLoading(false);
    } finally {
      if (person.current) {
        reset({
          firstName: person.current.firstName,
          middleName: person.current.middleName,
          lastName: person.current.lastName,
          email: person.current.email,
          mobile: person.current.phoneNumber,
          secondaryMobile: person.current.secondaryPhoneNumber,
          streetAddress1: person.current.clientAddress,
          streetAddress2: person.current.clientAddress1,
          city: person.current.city,
          state: person.current.stateid,
          gender: person.current.gender,
          zip: person.current.postalnumber,
          dob: person.current.dob ? person.current.dob.split("T")[0] : undefined,
          ssn: person.current.socialsecuritynumber,
          maritalStatus: person.current.marriedStatus,
        });
        setValue('ExtendedProfileFields', Array.isArray(person?.current?.extendedProfile)
          ? person?.current.extendedProfile.map(({ attributeId, peopleId, attributeName, attributeValue, tenantId, attributeType, attributeOptions }) => ({
            attributeId,
            peopleId,
            attributeName,
            attributeValue,
            attributeType,
            attributeOptions,
            tenantId
          }))
          : []);
      } else {
        reset({});
      }
      setLoading(false);
    }
  };

  useEffect(() => {

    const samplePerson = {
      tenantId: tenantId,
      clientId: 0,
      userId: "",
      clientName: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      socialsecuritynumber: "",
      postalnumber: "",
      stateid: 0,
      clientAddress: "",
      clientAddress1: "",
      phoneNumber: "",
      secondaryPhoneNumber: "",
      email: "",
      preferredContact: "",
      creditScore: "",
      lendabilityScore: "",
      marriedStatus: 0,
      spouseClientId: 0,
      rootFolder: "",
      gender: "",
      city: "",
      createdTime: "",
      modifiedTime: "",
      createdBy: "",
      modifiedBy: ",",
      extendedProfile: tenantExtendedAttributesList,
    }
    person.current = samplePerson;
    // eslint-disable-next-line
  }, [tenantExtendedAttributesList && tenantExtendedAttributesList.length > 0, itemInfo?.id === undefined]);
  useEffect(() => {
    // load other data from API
    if (workingOnCall.current === false) {
      workingOnCall.current = true;
      getRequiredData();
    }
    // eslint-disable-next-line
  }, [itemInfo?.id, show]);

  useEffect(() => {
    if (itemInfo?.id) {
      trigger();
    }
    // eslint-disable-next-line
  }, [loading]);

  // updates project business relations post creating a new one or deleting
  const updatePeopleBusinessRelations = async () => {
    try {
      const data: any = await PeopleService.getBusinessDetailsById<BusinessPersona>(itemInfo?.id ?? 0, tenantId);
      setBusinessList(data.businesses);
    } catch (error) {
      LoggingService.logError({
        MethodName: 'PersonSheet.updatePeopleBusinessRelations',
        Message: 'Error updating people business relation',
        AdditionalData: error
      });
      ShowErrorNotification('Error updating people business relation', error);
    }
    setLoadingBusinessRelations(false);
  };

  // save the information
  const saveDetails = async (values: any) => {
    //console.log('Saving project details', values);
    const extendedProfileFields: extendedProfileAttribute[] = values.ExtendedProfileFields || [];
    const updateParams: {
      clientId?: number;
      tenantId: number;
      firstName: string;
      middleName: string;
      lastName: string;
      dob: string;
      socialsecuritynumber: string;
      postalnumber: string;
      stateid?: number;
      clientAddress: string;
      phoneNumber: string;
      secondaryPhoneNumber: string;
      email: string;
      preferredContact: string;
      creditScore: number;
      lendabilityScore: number;
      marriedStatus: string;
      spouseClientId?: number;
      rootFolder: string;
      createdTime?: string;
      modifiedTime: string;
      createdBy?: number | string;
      modifiedBy: string | undefined;
      clientAddress1: string;
      city: string;
      gender: string;
      extendedProfile: extendedProfileAttribute[];
    } = {
      clientId: itemInfo?.id,
      tenantId: tenantId,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      dob: values.dob,
      socialsecuritynumber: values.ssn,
      postalnumber: values.zip,
      stateid: values.state === 0 ? undefined : values.state,
      clientAddress: values.streetAddress1,
      phoneNumber: values.mobile,
      secondaryPhoneNumber: values.secondaryMobile,
      email: values.email,
      preferredContact: values.preferredContact,
      creditScore: values.creditScore,
      lendabilityScore: values.lendabilityScore,
      marriedStatus: values.maritalStatus,
      spouseClientId: values.spouseClientId,
      rootFolder: values.rootFolder,
      createdTime: itemInfo?.id ? undefined : new Date().toISOString(),
      modifiedTime: new Date().toISOString(),
      createdBy: itemInfo?.id ? undefined : userId,
      modifiedBy: userId,
      clientAddress1: values.streetAddress2,
      city: values.city,
      gender: values.gender,
      extendedProfile: [], // Explicitly define as an empty array of ExtendedProfileAttribute[]
    };
    extendedProfileFields.forEach((attributeFields) => {
      updateParams.extendedProfile.push({
        attributeId: attributeFields.attributeId,
        attributeName: attributeFields.attributeName,
        attributeValue: attributeFields.attributeValue
          ? (typeof attributeFields.attributeValue === 'object' && 'value' in attributeFields.attributeValue
            ? (attributeFields.attributeValue as { value: string }).value
            : attributeFields.attributeValue.toString())  // Otherwise convert to string
          : "",
        peopleId: attributeFields.peopleId,
        tenantId: attributeFields.tenantId,
        attributeSection: attributeFields.attributeSection,
        attributeType: attributeFields?.attributeType,
        attributeOptions: attributeFields?.attributeOptions ?? [],
      });
    });
    if (itemInfo?.id) {
      try {
        await PeopleService.updatePeople(updateParams, tenantId);
        ShowSuccessNotification('User information updated successfully');
      } catch (error) {
        LoggingService.logError({
          MethodName: 'PersonSheet.saveDetails',
          Message: 'Error updating user information',
          AdditionalData: error
        });
        ShowErrorNotification('Error updating user information', error);
        closeDetails();
        return;
      }
    } else {
      try {
        await PeopleService.createPepople(updateParams, tenantId);
        ShowSuccessNotification('User information created successfully');
      } catch (error) {
        LoggingService.logError({
          MethodName: 'PersonSheet.saveDetails',
          Message: 'Error creating project user',
          AdditionalData: error
        });
        ShowErrorNotification('Error creating project user', error);
        closeDetails();
        return;
      }
    }

    // save the information and then close
    closeDetails({ refreshParent: true });
  };

  const title = itemInfo?.name ? itemInfo.name : 'New Person';
  const subTitle = itemInfo?.name ? 'Editing personal information' : 'Creating new personal information';
  return (
    <Sheet show={itemVisible} onDismiss={dismissDetails}>
      <form onSubmit={handleSubmit(saveDetails)} onReset={() => reset()} noValidate>
        <Flex flexDirection='column' margin={8} gap={8}>
          <TitleBar>
            <TitleBar.Title> {title} </TitleBar.Title>
            <TitleBar.Subtitle> {subTitle}</TitleBar.Subtitle>
            <TitleBar.Action style={{ display: 'flex', alignItems: 'center' }}>
              <Flex flexDirection='row' gap={8}>
                <Button
                  width='75px'
                  onClick={() => {
                    setItemVisible(false);
                    dismissDetails();
                  }}
                  variant='default'
                >
                  Discard
                </Button>
                <Button width='60px' type='submit' variant='accent' color='primary' disabled={isSubmitSuccessful}>
                  Save
                </Button>
              </Flex>
            </TitleBar.Action>
          </TitleBar>
          <LoadingStateComponent loading={loading} />

          {!loading && personDetails && (
            <Tabs defaultIndex={0}>

              <Tab title={'Profile'} prefixIcon={<DescriptionIcon />}>

                <ProfileDetails
                  control={control}
                  extendedProfile={person?.current?.extendedProfile ? person?.current?.extendedProfile : []}
                />

              </Tab>
              <Tab title={'Contact details'} prefixIcon={<CallIcon />}>
                <ContactDetails
                  control={control}
                  tenantStateList={tenantStateList}
                  extendedProfile={person?.current?.extendedProfile ? person?.current?.extendedProfile : []}
                />
              </Tab>
              <Tab title={'Professional information'} prefixIcon={<UnfoldMoreIcon />}>
                <ProfessionalInformation
                  control={control}
                  extendedProfile={person?.current?.extendedProfile ? person?.current?.extendedProfile : []}
                />
              </Tab>
              <Tab title={'Communication preferences'} prefixIcon={<ClockIcon />}>
                <CommunicationPreferences
                  control={control}
                  extendedProfile={person?.current?.extendedProfile ? person?.current?.extendedProfile : []}
                />
              </Tab>
              <Tab title={'Social media and online presence'} prefixIcon={<LinkIcon />}>
                <SocialMediaPresence
                  control={control}
                  extendedProfile={person?.current?.extendedProfile ? person?.current?.extendedProfile : []}
                />
              </Tab>
              <Tab title={'Extended profile'} prefixIcon={<LinkIcon />}>
                <Flex flexDirection='column' width={512} minWidth={512} gap={16}>
                  {control && Array.isArray(person?.current?.extendedProfile) &&
                    person?.current?.extendedProfile.map((attribute, index) =>
                      attribute.attributeSection === 'ExtendedProfile' ? (
                        <Controller
                          rules={attribute.attributeType === 'email' ? {
                            validate: (value) => /\S+@\S+\.\S+/.test(value) || 'Please enter a valid email address.',
                          } : {}}
                          key={attribute.attributeId}
                          control={control}
                          name={`ExtendedProfileFields.${index}.attributeValue`}
                          render={({ field, fieldState: { error } }) => (
                            <FormField>
                              <Label>{attribute.attributeName}</Label>

                              {attribute.attributeType === 'select' ? (
                                <SelectV2
                                  onChange={(selectedValue) => field.onChange(selectedValue)}
                                >
                                  <SelectV2.Content>
                                    {attribute.attributeOptions?.map((option) => (
                                      <SelectV2.Option key={option.attributeOptionAutoId} value={option.attributeOptionAutoId.toString()}>
                                        {option.attributeOptionName}
                                      </SelectV2.Option>
                                    ))}
                                  </SelectV2.Content>
                                </SelectV2>
                              ) : attribute.attributeType === 'dateTime' ? (
                                <DateTimePicker type="time" {...field} />
                              ) : attribute.attributeType === 'number' ? (
                                <TextInput
                                  pattern='/^\d{0,3}-?\d{0,2}-?\d{0,4}$/'
                                  placeholder={`Enter ${attribute.attributeName}`}
                                  onKeyDown={(e) => {
                                    !/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete'
                                      ? e.preventDefault()
                                      : void 0;
                                  }}
                                  controlState={{
                                    state: error ? 'error' : 'valid',
                                    hint: error?.message,
                                  }}
                                  {...field}
                                />
                              ) : (
                                <TextInput
                                  placeholder={`Enter ${attribute.attributeName}`}
                                  controlState={{
                                    state: error ? 'error' : 'valid',
                                    hint: error?.message,
                                  }}
                                  {...field}
                                />
                              )}
                            </FormField>
                          )}
                        />
                      ) : null
                    )}
                </Flex>
              </Tab>
              <Tab
                title={'Associated businesses'}
                prefixIcon={<BusinessAnalyticsSignetIcon />}
                disabled={itemInfo?.id === undefined}
              >
                <Flex flexDirection='column' width={'50%'} minWidth={325} gap={8}>
                  <Flex flexDirection='row' justifyContent='space-between' alignItems='end' marginTop={8}>
                    <Label className={groupHeaderFontStyle}>Businesses associated with this project</Label>
                    <Flex>
                      <Button
                        onClick={() => {
                          setShowExistingBusinessModal(true);
                        }}
                        variant='accent'
                        color='primary'
                        style={{ margin: '0px 0px 0px auto' }}
                      >
                        <Button.Prefix>
                          <PlusIcon />
                        </Button.Prefix>
                        Add business
                      </Button>
                      {showExistingBusinessModal && (
                        <NewPeopleBusinessRelationModal
                          key={uuidv4()}
                          parentType={PARENT_TYPE_PERSON}
                          personId={itemInfo?.id ?? 0}
                          currentPeopleBusinessList={businessList ?? []}
                          onDismiss={(refresh) => {
                            setShowExistingBusinessModal(false);
                            if (refresh) {
                              setLoadingBusinessRelations(true);
                              updatePeopleBusinessRelations();
                            }
                          }}
                        />
                      )}
                    </Flex>
                  </Flex>
                  <DataTableV2
                    loading={loadingBusinessRelations}
                    columns={peopleBusinessColumns as any}
                    data={businessList ?? []}
                    sortable
                    resizable
                    fullWidth
                    variant={{ rowDensity: 'comfortable', rowSeparation: 'none', verticalDividers: false, contained: false }}
                    sortBy={sortBy}
                    onSortByChange={handleSortingChange}
                  >
                    <DataTableV2.EmptyState>No businesses are associated with this project.</DataTableV2.EmptyState>
                    <DataTableV2.RowActions>
                      {(row: any) => (
                        <TableRowActions.Group>
                          <TableRowActions.Item
                            onClick={async () => {
                              // deleting the buisness from the project
                              try {
                                await PeopleService.deleteBusinessDetailsById(row.clientBusinessId, tenantId);
                                setLoadingBusinessRelations(true);
                                ShowSuccessNotification('Business association with the project deleted successfully');
                                updatePeopleBusinessRelations();
                              } catch (error) {
                                LoggingService.logError({
                                  MethodName: 'PersonSheet.deleteBusinessDetailsById',
                                  Message: 'Error deleting business association with the project',
                                  AdditionalData: error
                                });
                                ShowErrorNotification('Error deleting business association with the project', error);
                              }
                            }}
                            prefixIcon={<DeleteIcon />}
                          ></TableRowActions.Item>
                        </TableRowActions.Group>
                      )}
                    </DataTableV2.RowActions>
                  </DataTableV2>
                </Flex>
              </Tab>
              {(itemInfo && itemInfo?.id) && 
              (<Tab title={'Comments'} prefixIcon={<FeedbackIcon />}>
                 <CommentsSection itemId={(itemInfo?.id)?.toString() || ''} type='people' user={user} /> 
              </Tab>)}
            </Tabs>
          )}
        </Flex>
      </form>
    </Sheet>
  );
};
