import { PropsWithChildren, createContext, useContext, useRef, useState } from 'react';
import { AppInfo } from '../types/AppInfo';

// Define a new type for the context value
interface AppContextType {
  appInfo: AppInfo;
  updateAppInfo: (newAppInfo: AppInfo) => void;
}

export const AppContext = createContext<AppContextType | null>(null);


export function AppContextProvider(props: PropsWithChildren<{ appInfo: AppInfo }>) {
  const [appInfo, setAppInfo] = useState<AppInfo>(props.appInfo);

  const updateAppInfo = (newAppInfo: AppInfo) => {
    setAppInfo(newAppInfo);
  };
  
  return <AppContext.Provider value={{appInfo, updateAppInfo}}>{props.children}</AppContext.Provider>;
}

export function useAppInfo() {
  const context = useContext<AppContextType | null>(AppContext);
  
  if (!context) {
    throw new Error('useAppInfo must be used within an AppContextProvider');
  }

  const { appInfo, updateAppInfo } = context;

  const hasInitialized = () => {
    return appInfo !== null && appInfo.userInfo !== undefined && appInfo.userInfo.userData !== undefined;
  };

  const isLoading = useRef<boolean>(!hasInitialized());

  isLoading.current = !hasInitialized();
return {
    updateAppInfo,
    isAppLoading: isLoading.current,
    appInfo: appInfo ?? undefined,
    tenantPersonaList: appInfo?.tenantPersonaList ?? undefined,
    tenantIndustries: appInfo?.tenantIndustries,
    tenantLoanTypes: appInfo?.tenantLoanTypes,
    tenantBusinessCategories: appInfo?.tenantBusinessCategories,
    tenantBusinessIndustries: appInfo?.tenantBusinessIndustries,
    tenantProjectStatusList: appInfo?.tenantProjectStatusList,
    tenantRequestStatusList: appInfo?.tenantRequestStatusList,
    tenantProjectRequestStatusList: appInfo?.tenantProjectRequestStatusList,
    tenantStateList: appInfo?.tenantStateList,
    tenantExtendedAttributesList: appInfo?.tenantExtendedAttributes,
    tenantId: appInfo?.tenantId ?? 0,
    userId: appInfo?.userInfo?.userData?.userId,
    loginToken: appInfo?.loginToken,
    user: appInfo?.userInfo?.userData,
    users: appInfo?.users,
    documentTypes: appInfo?.documentTypes,
    peoplePersonas: appInfo?.peoplePersonas,
    tenantRequestReminders: appInfo?.tenantRequestReminders,
    assetTypes: appInfo?.assetTypes
  };
}
