import { LenderService } from '../services/LenderService';
import { PeopleService } from '../services/PeopleService';
import { TenantService } from '../services/TenantService';
import { Lender } from '../types/Lender';
import { Person } from '../types/Person';
import { Project, ProjectDetail } from '../types/Project';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { UserService } from '../services/UsersService';
import { Tenant, TenantUsers } from '../types/Tenant';
import { Business } from '../types/Business';
import { User } from '../types/User';
import { ProjectService } from '../services/ProjectService';
import { TemplateService } from '../services/TemplateService';
import { UploadService } from '../services/UploadService';
import { RequestTemplate } from '../types/RequestTemplate';
import { UploadDetails } from '../types/UploadDetails';

export const TEN_MINUTES_IN_MILLISECONDS = 100;

export function useProjectsByTenantId(id: number): UseQueryResult<Project[]> {
  return useQuery({
    queryKey: ['getProjectsByTenantId'],
    queryFn: () => TenantService.getProjectTenantById(id),
    refetchOnWindowFocus: false,
    retry: 0,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}

export function useBusinessesByTenantId(id: number): UseQueryResult<Business[]> {
  return useQuery({
    queryKey: ['getBusinessesByTenantId'],
    queryFn: () => PeopleService.getBusinessesDetailsByTenantId(id),
    refetchOnWindowFocus: false,
    retry: 0,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}

export function useLendersByTenantId(id: number): UseQueryResult<Lender[]> {
  return useQuery({
    queryKey: ['getLendersByTenantId'],
    queryFn: () => LenderService.getLenders(id),
    refetchOnWindowFocus: false,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}

export function usePeopleByTenantId(id: number): UseQueryResult<Person[]> {
  return useQuery({
    queryKey: ['getPeopleByTenantId'],
    queryFn: () => PeopleService.getPeopleByTenantId(id),
    refetchOnWindowFocus: false,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}

export function useUserByEmailId(email: string): UseQueryResult<any> {
  return useQuery({
    queryKey: ['getUserByEmailId'],
    queryFn: () => UserService.getUserByUserName(email),
    refetchOnWindowFocus: true,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}

export function useTenantByTenantId(id: number): UseQueryResult<Tenant> {
  return useQuery({
    queryKey: ['getTenantById'],
    queryFn: () => TenantService.getTenantById(id),
    refetchOnWindowFocus: true,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}

export function useUsersByTenantId(id: number, tenantId: number): UseQueryResult<TenantUsers> {
  return useQuery({
    queryKey: ['getUsersByTenantId'],
    queryFn: () => UserService.getUserListByTenantId(id, tenantId),
    refetchOnWindowFocus: false,
    retry: 0,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}

export function useUsers(tenantId: number): UseQueryResult<TenantUsers> {
  return useQuery({
    queryKey: ['getUsers'],
    queryFn: () => UserService.getUsers(tenantId),
    refetchOnWindowFocus: false,
    retry: 0,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}

export function useTenants(): UseQueryResult<Tenant[]> {
  return useQuery({
    queryKey: ['getTenants'],
    queryFn: () => TenantService.getTenants(),
    refetchOnWindowFocus: false,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}

export function useUserById(id: string, tenantId: number): UseQueryResult<User> {
  return useQuery({
    queryKey: ['getUserById'],
    queryFn: () => UserService.getUserById(id, tenantId),
    refetchOnWindowFocus: false,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}

export function useProjectWithDetailsID(id: number, tenantId: number): UseQueryResult<ProjectDetail> {
  return useQuery({
    queryKey: ['getProjectWithDetailsID'],
    queryFn: () => ProjectService.getProjectWithDetailsID(id, tenantId),
    refetchOnWindowFocus: false,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}
export function useProjectRequestById(id: number, tenantId: number): UseQueryResult<any> {
  return useQuery({
    queryKey: ['getProjectRequestById'],
    queryFn: () => ProjectService.getProjectRequestById(id, tenantId),
    refetchOnWindowFocus: false,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}
export function useProjectRequestDraftsById(id: number, tenantId: number): UseQueryResult<any> {
  return useQuery({
    queryKey: ['getProjectRequestDraftsById'],
    queryFn: () => ProjectService.getProjectRequestDraftsById(id, tenantId),
    refetchOnWindowFocus: false,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}
export function useProjectPackagesById(id: number, tenantId: number): UseQueryResult<any> {
  return useQuery({
    queryKey: ['getProjectPackagesById'],
    queryFn: () => ProjectService.getProjectPackagesById(id, tenantId),
    refetchOnWindowFocus: false,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}

export function useProjectPackagesDraftsById(id: number, tenantId: number): UseQueryResult<any> {
  return useQuery({
    queryKey: ['getProjectPackagesDraftsById'],
    queryFn: () => ProjectService.getProjectPackagesDraftsById(id, tenantId),
    refetchOnWindowFocus: false,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}
export function useStatusByTenant(id:number, projectRequestStatus: number): UseQueryResult<any> {
  return useQuery({
    queryKey: ['getStatusByTenant'],
    queryFn: () => TenantService.getStatusByTenant(id, projectRequestStatus),
    refetchOnWindowFocus: false,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  })
}

export function useDocumentTypeList(id: number): UseQueryResult<any> {
  return useQuery({
    queryKey: ['getDocumentListByTenantId'],
    queryFn: () => TemplateService.getDocumentListByTenantId(id),
    refetchOnWindowFocus: false,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}
export function useRequestTemplates(id: number): UseQueryResult<RequestTemplate[]> {
  return useQuery({
    queryKey: ['getProjectDocumentTemplates'],
    queryFn: () => TemplateService.getProjectDocumentTemplates(id),
    refetchOnWindowFocus: false,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}
export function useMinimalRequestTemplates(id: number): UseQueryResult<RequestTemplate[]> {
  return useQuery({
    queryKey: ['getMinimalProjectDocumentTemplates'],
    queryFn: () => TemplateService.getMinimalProjectDocumentTemplates(id),
    refetchOnWindowFocus: false,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}
export function useReminderList(id: number): UseQueryResult<any> {
  return useQuery({
    queryKey: ['getReminderListByTenantId'],
    queryFn: () => TemplateService.getReminderListByTenantId(id),
    refetchOnWindowFocus: false,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}

export function useUploadDetails(id: string): UseQueryResult<UploadDetails> {
  return useQuery({
    queryKey: ['postProjectRequestUplodDetails'],
    queryFn: () => UploadService.postProjectRequestUploadDetails(id),
    refetchOnWindowFocus: false,
    staleTime: TEN_MINUTES_IN_MILLISECONDS,
  });
}

