import React, { useState, useEffect, useCallback } from 'react';
import { Text } from '@dynatrace/strato-components/typography';
import { Button, Flex, ProgressCircle } from '@dynatrace/strato-components';
import { Avatar, TextArea } from '@dynatrace/strato-components-preview';
import { useAppInfo } from '../contexts/AppContext';
import { CommentsService } from '../services/CommentsService';
import { ShowSuccessNotification } from '../utils/Notifications';
import { LoggingService } from '../services/LoggingService';
import { DeleteIcon, EditIcon } from '@dynatrace/strato-icons';
import { format } from 'date-fns';

interface CommentsSectionProps {
    user: any;
    itemId: string;
    type: string;
}

export const CommentsSection: React.FC<CommentsSectionProps> = ({ type, itemId }) => {
    const { user, assetTypes } = useAppInfo();
    const assetTypeAutoId = assetTypes?.find((item: any) => item.assetTypeName === type)?.assetTypeAutoId;
    const tenantId = user?.tenantId;

    const [comments, setComments] = useState<any[]>([]);
    const [newComment, setNewComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [editingCommentId, setEditingCommentId] = useState<number | null>(null);
    const [updatedText, setUpdatedText] = useState('');

    // Define fetchComments outside useEffect so that it can be reused
    const fetchComments = useCallback(async () => {
        if (!tenantId) return;
        setLoading(true);
        try {
            const data = await CommentsService.getCommentsByAssetTypeId(
                assetTypeAutoId,
                Number(itemId),
                Number(tenantId)
            ) as any;
            if (data) {
                setComments(
                    data.map((c: any) => ({
                        id: c.commentsId,
                        userId: c.userId,
                        name: c.name,
                        CommentText: c.commentText,
                        timestamp: new Date(c.createdDate).toISOString()
                    }))
                );
                setError(null);
            }
        } catch (err: any) {
            setError('Failed to load comments');
            console.error(err);
            LoggingService.logError({
                MethodName: 'CommentsSection.fetchComments',
                Message: 'Error fetching comments',
                AdditionalData: err
            });
        } finally {
            setLoading(false);
        }
    }, [tenantId, assetTypeAutoId, itemId]);

    // Fetch comments on mount or when dependencies change
    useEffect(() => {
        fetchComments();
    }, [fetchComments]);

    const handlePostComment = async () => {
        if (newComment.trim() === '' || !tenantId) return;

        const commentPayload = {
            tenantId: tenantId,
            userId: user?.userId,
            AssetTypeAutoId: assetTypeAutoId,
            AssetTypeCommonId: itemId,
            CommentText: newComment,
        };

        try {
            await CommentsService.saveComment(commentPayload, tenantId);
            // After posting a comment, re-fetch the comments from the server.
            await fetchComments();
            setNewComment('');
            ShowSuccessNotification('Comment posted successfully');
        } catch (err) {
            console.error('Error saving comment:', err);
            LoggingService.logError({
                MethodName: 'CommentsSection.handlePostComment',
                Message: 'Error saving comment',
                AdditionalData: err
            });
        }
    };

    const handleDeleteComment = async (commentId: number) => {
        try {
            await CommentsService.deleteComment(commentId.toString(), tenantId ?? 0);
            // After deleting, re-fetch the comments.
            await fetchComments();
            ShowSuccessNotification('Comment deleted successfully');
        } catch (err) {
            console.error('Error deleting comment:', err);
            LoggingService.logError({
                MethodName: 'CommentsSection.handleDeleteComment',
                Message: 'Error deleting comment',
                AdditionalData: err
            });
        }
    };

    const handleUpdateComment = (comment: any) => {
        setEditingCommentId(comment.id);
        setUpdatedText(comment.CommentText);
    };

    const handleSaveUpdatedComment = async (comment: any) => {
        const updatedComment = {
            ...comment,
            CommentsId: comment.id,
            CommentText: updatedText,
        };
        try {
            await CommentsService.updateComment(updatedComment, tenantId ?? 0);
            await fetchComments();
            setEditingCommentId(null);
            setUpdatedText('');
            ShowSuccessNotification('Comment updated successfully');
        } catch (err) {
            console.error('Error updating comment:', err);
            LoggingService.logError({
                MethodName: 'CommentsSection.handleSaveUpdatedComment',
                Message: 'Error updating comment',
                AdditionalData: err
            });
        }
    };

    return (
        <Flex flexDirection="column" gap={24} mt={16}>
            <Text style={{ fontWeight: 'bolder', fontSize: '20px' }}>Comments</Text>
            <Flex flexDirection="column" gap={16} padding={4}>
                <TextArea
                    width="100%"
                    rows={2}
                    placeholder="Add comment"
                    value={newComment}
                    onChange={(e) => setNewComment(e)}
                />
                <Flex justifyContent="flex-end">
                    <Button variant="accent" disabled={!user} onClick={handlePostComment}>
                        Post comment
                    </Button>
                </Flex>
            </Flex>

            {loading && (<ProgressCircle>Fetching comments...</ProgressCircle>)}
            {error && <Text color="red">{error}</Text>}

            {/* Comments List */}
            <Flex flexDirection="column" gap={16}>
                {comments.map((comment: any) => (
                    <Flex
                        key={comment.id}
                        flexDirection="column"
                        style={{ backgroundColor: '#ffffff', borderRadius: '8px' }}
                        gap={8}
                        padding={16}
                    >
                        {/* Comment Header */}
                        <Flex justifyContent="space-between" alignItems="center">
                            <Avatar abbreviation={comment.name ? comment.name.split(' ').map((part: any) => part[0].toUpperCase()).join('') : ''}>
                                <Avatar.Label style={{ fontWeight: 'bold' }}>{comment.name}</Avatar.Label>
                                <Avatar.Subtitle>{format(new Date(comment.timestamp), 'MMM dd, yyyy hh:mm a')}</Avatar.Subtitle>
                            </Avatar>
                            <Flex alignItems="center" gap={8}>
                                {comment.userId === user?.userId && (
                                    <>
                                        <Button onClick={() => handleUpdateComment(comment)}>
                                            <EditIcon />
                                        </Button>
                                        <Button onClick={() => handleDeleteComment(comment.id)}>
                                            <DeleteIcon />
                                        </Button>
                                    </>
                                )}
                            </Flex>
                        </Flex>
                        {/* Comment Body or Edit Mode */}
                        {editingCommentId === comment.id ? (
                            <>
                                <TextArea
                                    width="100%"
                                    rows={2}
                                    value={updatedText}
                                    onChange={(e) => setUpdatedText(e)}
                                />
                                <Flex justifyContent="flex-end" gap={8}>
                                    <Button onClick={() => handleSaveUpdatedComment(comment)}>
                                        Save
                                    </Button>
                                    <Button onClick={() => setEditingCommentId(null)}>
                                        Cancel
                                    </Button>
                                </Flex>
                            </>
                        ) : (
                            <Text style={{ marginLeft: 40 }}>{comment.CommentText}</Text>
                        )}
                    </Flex>
                ))}
            </Flex>
        </Flex >
    );
};
