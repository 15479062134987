import axios from 'axios';
import { getAuthHeader, getBaseURL, handleApiError } from './AxiosAuthService';

export const PackageService = {
    decryptAndFetch: async <T>(token: string): Promise<T> => {
        try {
            const response = await axios.post(
                `${getBaseURL()}/Package/DecryptAndFetch`,
                token,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getPackageDetails: async <T>(
        peopleId: number,
        packageId: number,
        tenantId: number,
        projectId: number
    ): Promise<T> => {
        try {
            const url = `${getBaseURL()}/project/getpackagedetails?peopleId=${peopleId}&packageId=${packageId}&tenantId=${tenantId}&projectId=${projectId}`;

            const response = await axios.get(url, {
                headers: {
                    ...getAuthHeader(),
                    'Accept': 'application/json',
                },
            });

            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    downloadSelectedPackages: async (
        selectedUniqueLinks: string[],
        packageName: string
    ): Promise<any> => {
        try {
            const payload = {
                selectedUniqueLinks,
                packageName,
            };
            const response = await axios.post(`${getBaseURL()}/Package/DownloadSelectedPackages`,
                payload,
                {
                    headers: {
                        ...getAuthHeader(),
                        'Content-Type': 'application/json',
                    },
                    responseType: 'blob',
                }
            );

            return { blob: response.data, headers: response.headers };
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getNotificationsUsingPackageShareId: async <T>(projectShareId: number): Promise<T> => {
        try {
            const response = await axios.get(
                `${getBaseURL()}/package/getNotificationsUsingPackageShareId/${projectShareId}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    saveDraftPackageDetails: async <T>(request: any, tenantId: number, projectId: number): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/package/saveProjectPackageAsDraft?tenantId=${tenantId}&projectId=${projectId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    updateDraftPackageDetails: async <T>(request: any, tenantId: number, projectId: number, requestId: number): Promise<T> => {
        try {
            const response: any = await axios.put(`${getBaseURL()}/package/updateProjectPackageAsDraft?tenantId=${tenantId}&projectId=${projectId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    deleteProjectPackageDraftById: async <T>(id: number, tenantId: number, projectId: number): Promise<T> => {
        try {
            const response: any = await axios.delete(`${getBaseURL()}/package/deletePackageByPackageId/${id}?tenantId=${tenantId}&projectId=${projectId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    uploadDocxPreview: async <T>(
        base64File: string,
        fileName: string,
        contentType: string
      ): Promise<T> => {
        try {
          const payload = {
            Base64Data: base64File,
            FileName: fileName,
            ContentType: contentType,
          };
    
          const response = await axios.post(
            `${getBaseURL()}/api/file/upload`,
            payload,
            {
              headers: {
                'Content-Type': 'application/json',
                ...getAuthHeader(),
              },
            }
          );
    
          return response.data;
        } catch (error: any) {
          handleApiError(error);
          throw error;
        }
      },
};
