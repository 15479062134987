import { Flex } from "@dynatrace/strato-components";
import { FormField, Label, TextInput, SelectV2, DatePicker, DateTimePicker } from "@dynatrace/strato-components-preview";
import { Controller } from "react-hook-form";
import { State } from "../../types/State";
import { extendedProfileAttribute } from "../../types/Person";
import { formatPhoneNumberForDisplay } from "../../utils/Utilities";

export interface ContactDetailsProps {
    control: any;
    tenantStateList: State[] | undefined;
    extendedProfile: extendedProfileAttribute[] | undefined;
}

export const ContactDetails = (props: ContactDetailsProps) => {

    const {
        control,
        tenantStateList,
        extendedProfile
    } = props

    return (
        <Flex flexDirection='column' width={512} minWidth={512} gap={16}>
            <Controller
                name='secondaryMobile'
                control={control}
                rules={{
                    validate: (value: string) => {
                        const cleaned = value.replace(/\D/g, '');
                        return cleaned.length === 10 || 'Please enter a valid 10-digit phone number.';
                    }
                }}
                render={({ field, fieldState: { error } }) => (
                    <FormField>
                        <Label>Secondary mobile number</Label>
                        <TextInput
                            placeholder='Enter Secondary mobile number'
                            controlState={{
                                state: error ? 'error' : 'valid',
                                hint: error?.message,
                            }}
                            value={formatPhoneNumberForDisplay(field.value || '')}
                            onChange={(value: string, event: React.ChangeEvent<HTMLInputElement>) => {
                                const cleaned = value.replace(/\D/g, '').slice(0, 10);
                                field.onChange(cleaned);
                            }}
                        />
                    </FormField>
                )}
            />
            <Controller
                name='streetAddress1'
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <FormField>
                        <Label>Street address 1</Label>
                        <TextInput
                            placeholder='Enter street address 1'
                            controlState={{
                                state: error ? 'error' : 'valid',
                                hint: error?.message,
                            }}
                            {...field}
                        />
                    </FormField>
                )}
            />
            <Controller
                name='streetAddress2'
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <FormField>
                        <Label>Street address 2</Label>
                        <TextInput
                            placeholder='Enter street address 2'
                            controlState={{
                                state: error ? 'error' : 'valid',
                                hint: error?.message,
                            }}
                            {...field}
                        />
                    </FormField>
                )}
            />
            <Controller
                name='city'
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <FormField>
                        <Label>City</Label>
                        <TextInput
                            placeholder='Enter city'
                            controlState={{
                                state: error ? 'error' : 'valid',
                                hint: error?.message,
                            }}
                            {...field}
                        />
                    </FormField>
                )}
            />
            <Controller
                name='state'
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <FormField>
                        <Label>State</Label>
                        <Flex
                            onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault(); // Prevent form submission
                                }
                            }}
                        >
                            <SelectV2
                                controlState={{
                                    state: error ? 'error' : 'valid',
                                    hint: Array.isArray(error) ? error.join('\n') : error?.message,
                                }}
                                {...field}
                            >
                                <SelectV2.Trigger placeholder={'Select state'} />

                                <SelectV2.Content style={{ width: 'max-content', minWidth: '200px' }}>
                                    {tenantStateList &&
                                        tenantStateList.map((state, index) => (
                                            <SelectV2.Option key={index} value={state.id}>
                                                {state.name}
                                            </SelectV2.Option>
                                        ))}
                                </SelectV2.Content>
                            </SelectV2>
                        </Flex>
                    </FormField>
                )}
            />
            <Controller
                name='zip'
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <FormField>
                        <Label>Zip</Label>
                        <Flex width={150}>
                            <TextInput
                                maxLength={5}
                                onKeyDown={(e) => {
                                    if (!/[0-9]/.test(e.key) && 
                                        !["Backspace", "Delete", "Tab", "Shift"].includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                placeholder='Enter zip code'
                                controlState={{
                                    state: error ? 'error' : 'valid',
                                    hint: error?.message,
                                }}
                                {...field}
                            />
                        </Flex>
                    </FormField>
                )}
            />
            <Controller
                name='dob'
                control={control}
                rules={{
                    validate(value) {
                        return value && new Date(value).getTime() < new Date().getTime();
                    },
                    required: {
                        value: false,
                        message: 'Please choose a desired closing date.',
                    },
                }}
                render={({ field, fieldState: { error } }) => (
                    <FormField>
                        <Label>Date of birth</Label>
                        <Flex width={150}>
                            <DatePicker
                                controlState={{
                                    state: error ? 'error' : 'valid',
                                    hint: error?.message,
                                }}
                                {...field}
                                onChange={({ value }) => field.onChange(value)}
                            />
                        </Flex>
                    </FormField>
                )}
            />
            <Controller
                name='ssn'
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <FormField>
                        <Label>SSN</Label>
                        <Flex width={150}>
                            <TextInput
                                pattern='/^\d{0,3}-?\d{0,2}-?\d{0,4}$/'
                                maxLength={11}
                                onKeyDown={(e) => {
                                    if (!/[0-9]/.test(e.key) && 
                                        !["Backspace", "Delete", "Tab", "Shift"].includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                placeholder='Enter Social security number'
                                controlState={{
                                    state: error ? 'error' : 'valid',
                                    hint: error?.message,
                                }}
                                {...field}
                            />
                        </Flex>
                    </FormField>
                )}
            />

            <Controller
                name='gender'
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <FormField>
                        <Label>Gender</Label>
                        <Flex
                            onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                }
                            }}
                        >
                            <SelectV2
                                controlState={{
                                    state: error ? 'error' : 'valid',
                                    hint: Array.isArray(error) ? error.join('\n') : error?.message,
                                }}
                                {...field}
                            >
                                <SelectV2.Content>
                                    <SelectV2.Option value="male">Male</SelectV2.Option>
                                    <SelectV2.Option value="female">Female</SelectV2.Option>
                                </SelectV2.Content>
                            </SelectV2>
                        </Flex>
                    </FormField>
                )}
            />
            <Controller
                name="maritalStatus"
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <FormField>
                        <Label>Marital status</Label>
                        <Flex
                            onKeyDown={(event: any) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault(); // Prevent form submission
                                }
                            }}
                        >
                            <SelectV2
                                controlState={{
                                    state: error ? 'error' : 'valid',
                                    hint: Array.isArray(error) ? error.join('\n') : error?.message,
                                }}
                                {...field} // field already contains value and onChange
                            >
                                <SelectV2.Content>
                                    <SelectV2.Option value={0}>Single</SelectV2.Option>
                                    <SelectV2.Option value={1}>Married</SelectV2.Option>
                                </SelectV2.Content>
                            </SelectV2>
                        </Flex>
                    </FormField>
                )}
            />

            {control &&
                extendedProfile?.map((attribute, index) =>
                    attribute.attributeSection === 'ContactDetails' ? (
                        <Controller
                            rules={
                                attribute.attributeType === 'email'
                                    ? {
                                        validate: (value) =>
                                            /\S+@\S+\.\S+/.test(value) || 'Please enter a valid email address.',
                                    }
                                    : attribute.attributeType === 'number'
                                        ? {
                                            validate: (value: string) => {
                                                const cleaned = value.replace(/\D/g, '');
                                                return cleaned.length === 10 || 'Please enter a valid 10-digit phone number.';
                                            }
                                        }
                                        : {}
                            }
                            key={attribute.attributeId}
                            control={control}
                            name={`ExtendedProfileFields.${index}.attributeValue`}
                            render={({ field, fieldState: { error } }) => (
                                <FormField>
                                    <Label>{attribute.attributeName}</Label>
                                    <Flex onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault(); // Prevent form submission
                                        }
                                    }}>
                                        {attribute.attributeType === 'select' ? (
                                            <SelectV2
                                                onChange={(selectedValue) => field.onChange(selectedValue)}
                                            >
                                                <SelectV2.Content>
                                                    {attribute.attributeOptions?.map((option) => (
                                                        <SelectV2.Option key={option.attributeOptionAutoId} value={option.attributeOptionAutoId.toString()}>
                                                            {option.attributeOptionName}
                                                        </SelectV2.Option>
                                                    ))}
                                                </SelectV2.Content>
                                            </SelectV2>
                                        ) : attribute.attributeType === 'dateTime' ? (
                                            <DateTimePicker type="time" {...field} />
                                        ) : attribute.attributeType === 'number' ? (
                                            <TextInput
                                                placeholder={`Enter ${attribute.attributeName}`}
                                                controlState={{
                                                    state: error ? 'error' : 'valid',
                                                    hint: error?.message,
                                                }}
                                                value={formatPhoneNumberForDisplay(field.value || '')}
                                                onChange={(value: string, event: React.ChangeEvent<HTMLInputElement>) => {
                                                    const cleaned = value.replace(/\D/g, '').slice(0, 10);
                                                    field.onChange(cleaned);
                                                }}
                                            />
                                        ) : (
                                            <TextInput
                                                placeholder={`Enter ${attribute.attributeName}`}
                                                controlState={{
                                                    state: error ? 'error' : 'valid',
                                                    hint: error?.message,
                                                }}
                                                {...field}
                                            />
                                        )}
                                    </Flex>
                                </FormField>
                            )}
                        />
                    ) : null
                )}

        </Flex>
    )
}