import { DataTableV2, Modal, SelectV2, Tooltip } from '@dynatrace/strato-components-preview';
import { Text, TextEllipsis } from '@dynatrace/strato-components/typography';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { useEffect, useMemo, useState } from 'react';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../utils/Notifications';
import { ProjectService } from '../../../services/ProjectService';
import { useAppInfo } from '../../../contexts/AppContext';
import { ProjectBusinessRelation } from '../../../types/Project';
import { LoggingService } from '../../../services/LoggingService';

export interface EditProjectBusinessRelationsModalProps {
  parentId: number;
  currentProjectBusinessList: ProjectBusinessRelation[];
  businessRelationToEdit: ProjectBusinessRelation | null;
  onDismiss: (refresh?: boolean) => void;
}

export const EditProjectBusinessRelationsModal = (props: EditProjectBusinessRelationsModalProps) => {
  console.log('EditProjectBusinessRelationModal');
  const { parentId, currentProjectBusinessList, businessRelationToEdit, onDismiss } = props;
  const { tenantId, tenantBusinessCategories } = useAppInfo();

  const [showModal, setShowModal] = useState(true);

  const [disableConfirm, setDisableConfirm] = useState(true);
  const [selectedBusiness, setSelectedBusiness] = useState<any>(businessRelationToEdit);

  // Check if any primary business relation exists in the current list.
  const isPrimaryBusinessExist =
    currentProjectBusinessList?.filter((item) => item.primaryBusiness === 1)?.length > 0;

  const businessCategoryMap = useMemo(() => {
    const map: Record<number, string> = {};
    tenantBusinessCategories?.forEach((category) => {
      map[category.businessCategoryAutoId] = category.businessCategory;
    });
    return map;
  }, [tenantBusinessCategories]);

  // Validate that a valid business category is selected.
  const isValidSelection = (relation: any) => {
    return relation && relation.businessCategoryId !== undefined && relation.businessCategoryId !== "";
  };

  // const onSelectBusinessCategory = (row: any, categoryId: number) => {
  //   if (selectedBusiness && selectedBusiness.businessId === row.businessId) {
  //     const updated = { ...selectedBusiness, businessCategoryId: categoryId };
  //     setSelectedBusiness(updated);
  //     setDisableConfirm(!isValidSelection(updated));
  //   }
  // };

  const onPrimaryChange = (row: any, value: string) => {
    if (selectedBusiness && selectedBusiness.businessId === row.businessId) {
      const yesSelected = value === "true" ? 1 : 0;
      setSelectedBusiness({ ...selectedBusiness, primaryBusiness: yesSelected });
    }
  };

  useEffect(() => {
    setDisableConfirm(!isValidSelection(selectedBusiness));
  }, [selectedBusiness]);

  // Define the table columns.
  const businessColumns = [
    {
      id: 'businessName',
      header: 'Business name',
      accessor: 'businessName',
      minWidth: 200,
      autoWidth: true,
      cell: ({ rowData }: any) => (
        <DataTableV2.DefaultCell>
          <Tooltip placement='bottom' text={rowData.businessName}>
            <TextEllipsis truncationMode='end'>{rowData.businessName}</TextEllipsis>
          </Tooltip>
        </DataTableV2.DefaultCell>
      ),
    },
    {
      id: 'primaryBusiness',
      header: 'Primary business',
      accessor: 'primaryBusiness',
      width: 150,
      cell: ({ rowData }: any) => {
        const isPrimary = selectedBusiness?.primaryBusiness || false;
        return (
          <DataTableV2.DefaultCell>
            <Tooltip
              placement='bottom'
              text={isPrimaryBusinessExist ? "Primary business already exists" : "Select primary business"}
            >
              <SelectV2
                value={isPrimary ? "true" : "false"}
                onChange={(value) => onPrimaryChange(rowData, value as string)}
              >
                <SelectV2.Trigger placeholder="Select" />
                <SelectV2.Content style={{ maxWidth: '300px' }} width='150px'>
                  <SelectV2.Option value="true">
                    <Text>Yes</Text>
                  </SelectV2.Option>
                  <SelectV2.Option value="false">
                    <Text>No</Text>
                  </SelectV2.Option>
                </SelectV2.Content>
              </SelectV2>
            </Tooltip>
          </DataTableV2.DefaultCell>
        );
      },
    },
    {
      id: 'businessCategory',
      header: 'Business Category',
      accessor: (row: any) => businessCategoryMap[row.businessCategoryId] || '',
      minWidth: 200,
      autoWidth: true,
    },
  ];

  const handleCancel = () => {
    setShowModal(false);
    onDismiss(false);
  };

  const handleConfirm = () => {
    saveProjectBusinessRelation();
    setShowModal(false);
    onDismiss(false);
  };

  // Build an update payload and send changes.
  const saveProjectBusinessRelation = async () => {
    try {
      const updates = [];
      const updatedRelation = {
        projectBusinessId: selectedBusiness.projectBusinessId,
        tenantId: tenantId,
        projectId: parentId,
        businessId: selectedBusiness.businessId,
        modifiedOn: new Date().toISOString(),
        primaryBusiness: selectedBusiness.primaryBusiness ? 1 : 0,
      };
      updates.push(updatedRelation);

      // If this relation is now primary, unset any other primary business relations.
      if (selectedBusiness.primaryBusiness) {
        const oldPrimaries = currentProjectBusinessList.filter(
          (item) => item.primaryBusiness === 1 && item.businessId !== selectedBusiness.businessId
        );
        oldPrimaries.forEach((oldPrimary: any) => {
          updates.push({
            projectBusinessId: oldPrimary.projectBusinessId,
            tenantId: oldPrimary.tenantId,
            projectId: oldPrimary.projectId,
            businessId: oldPrimary.businessId,
            businessCategoryId: oldPrimary.businessCategoryId,
            modifiedOn: new Date().toISOString(),
            primaryBusiness: 0,
            primaryForLoan: 0,
          });
        });
      }

      await ProjectService.updateProjectBusinessesRelationWithDetails(updates, tenantId);
      ShowSuccessNotification('Project business relation updated successfully');
      onDismiss(true);
    } catch (error) {
      LoggingService.logError({"MethodName":"EditProjectBusinessRelationsModal.saveProjectBusinessRelation", "Message":"Error updating project business relation", "AdditionalData":error});
      ShowErrorNotification('Error updating project business relation', error);
    }
  };

  return (
    <Modal
      show={showModal}
      title={`Edit '${selectedBusiness?.businessName || ''}'`}
      size={'medium'}
      dismissible={false}
      footer={
        <Flex justifyContent='right' width='100%'>
          <Flex justifyContent='flex-end' gap={8}>
            <Button onClick={handleCancel} variant='default'>
              Discard
            </Button>
            <Button onClick={handleConfirm} type='submit' variant='accent' color='primary' disabled={disableConfirm}>
              Confirm
            </Button>
          </Flex>
        </Flex>
      }
    >
      <DataTableV2
        sortable
        columns={businessColumns}
        variant={{ rowDensity: 'comfortable', rowSeparation: 'none', verticalDividers: false, contained: false }}
        data={selectedBusiness ? [selectedBusiness] : []}
        defaultSortBy={[{ id: 'businessName', desc: false }]}
        resizable
        fullWidth
      >
        <DataTableV2.EmptyState>No business available.</DataTableV2.EmptyState>
      </DataTableV2>
    </Modal>
  );
};