import axios, { AxiosError } from 'axios';
import { getAuthHeader, getBaseURL} from './AxiosAuthService';

export const LoggingService = {
    logEvent: async <T>(request: any): Promise<T> => {
        try {
          const response: any = await axios.post(`${getBaseURL()}/logging/event`, request, getAuthHeader());
          return response.data;
        } catch (error: any) {
          handleApiError(error);
          throw error;
        }
      },
      
      // Log a debug message
      logDebug: async <T>(request: any): Promise<T> => {
        try {
          const response: any = await axios.post(`${getBaseURL()}/logging/debug`, request, getAuthHeader());
          return response.data;
        } catch (error: any) {
          handleApiError(error);
          throw error;
        }
      },
      
      // Log a warning message
      logWarning: async <T>(request: any): Promise<T> => {
        try {
          const response: any = await axios.post(`${getBaseURL()}/logging/warning`, request, getAuthHeader());
          return response.data;
        } catch (error: any) {
          handleApiError(error);
          throw error;
        }
      },
      
      // Log an error message
      logError: async <T>(request: any): Promise<T> => {
        try {
          const response: any = await axios.post(`${getBaseURL()}/logging/error`, request, getAuthHeader());
          return response.data;
        } catch (error: any) {
          handleApiError(error);
          throw error;
        }
      },
      
      // Log a fatal message
      logFatal: async <T>(request: any): Promise<T> => {
        try {
          const response: any = await axios.post(`${getBaseURL()}/logging/fatal`, request, getAuthHeader());
          return response.data;
        } catch (error: any) {
          handleApiError(error);
          throw error;
        }
      }
};

const handleApiError = (error: AxiosError) => {
  if (error.response) {
    console.error('API Error Response:', error.response.data);
  } else if (error.request) {
    console.error('API No Response:', error.request);
  } else {
    console.error('API Error:', error.message);
  }
};
