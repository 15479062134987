import { Modal, Tooltip } from '@dynatrace/strato-components-preview/overlays';
import { ColumnType, DataTableV2 } from '@dynatrace/strato-components-preview/tables';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { Dispatch, useMemo, useRef, useState } from 'react';
import { RequestDetails } from '../../../../types/Request';
import { ProjectService } from '../../../../services/ProjectService';
import '../../../../App.css';
import { useAppInfo } from '../../../../contexts/AppContext';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../../utils/Notifications';
import { format, parseISO } from 'date-fns';
import { UploadService } from '../../../../services/UploadService';
import { FilterBar, TextInput } from '@dynatrace/strato-components-preview';
import { LoadingStateComponent } from '../../../../components/LoadingStateComponent';
import { useParams } from 'react-router-dom';
import { DescriptionIcon } from '@dynatrace/strato-icons';
import { PackageService } from '../../../../services/PackageService';
import FilePreview from '../../../../components/FilePreview';
import { LoggingService } from '../../../../services/LoggingService';
// import { PeopleService } from '../../../../services/PeopleService';

export interface RequestsProps {
  projectRequests: RequestDetails[];
  refetchProjectRequests: () => void;
  setLoading: Dispatch<React.SetStateAction<boolean>>;
  onRequestUpdated: any;
  handleSelectedRows: any;
  selectedFilesRowsIds: any;
}

export const PackageFilesView = (props: RequestsProps) => {
  const {
    onRequestUpdated,
    projectRequests,
    refetchProjectRequests,
    setLoading,
    handleSelectedRows,
    selectedFilesRowsIds
  } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fileToRename, setFileToRename] = useState<any>(null);
  const [newFileName, setNewFileName] = useState<string>('');
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { ProjectId } = useParams();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { tenantId, documentTypes, tenantRequestStatusList, user } = useAppInfo();
  // const [peopleId, setPeopleId] = useState<any>(null);
  // eslint-disable-next-line
  const [selectedRows, setSelectedRows] = useState<any>();
  const [previewFile, setPreviewFile] = useState<any>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     try {
  //       const data = await PeopleService.getPepopleByEmail(user?.email ?? '', tenantId);
  //       setPeopleId(data);
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //     }
  //   };

  //   fetchUserData();
  //   // eslint-disable-next-line
  // }, [user?.email]);

  const handleRenameConfirm = async () => {
    if (!fileToRename || !newFileName.trim()) {
      ShowErrorNotification('File name cannot be empty.');
      return;
    }

    setIsLoading(true);
    try {
      const updatedFile = {
        ...fileToRename,
        downloadFilePathNew: fileToRename?.downloadFilePath?.replace(/[^/]*$/, newFileName?.trim()) + "." + fileToRename?.downloadFilePath?.split(".")?.pop(),
      };

      await UploadService.UpdateFileNameForStaff(updatedFile, tenantId, updatedFile.projectId);
      await onRequestUpdated(updatedFile);
      ShowSuccessNotification(`File renamed to ${newFileName}.`);
    } catch (error) {
      LoggingService.logError({
        MethodName: 'PackageFilesView.handleRenameConfirm',
        Message: 'Error renaming file',
        AdditionalData: error
      });
      ShowErrorNotification('Failed to rename file. Please try again.');
    } finally {
      setIsLoading(false);
      setRenameModalOpen(false);
    }
  };

  const transformedProjectRequests = projectRequests.map((item) => ({
    ...item,
    fileName: item.downloadFilePath?.split('/')?.pop() || 'N/A',
    fileType: item.downloadFilePath?.split('.')?.pop() || 'N/A',
    createdBy: item.assignedto || 'N/A',
    modifiedOn:
      item?.updateDate && item?.updateDate !== ""
        ? format(parseISO(item?.updateDate), 'MMM dd, yyyy hh:mm a')
        : item?.createdOn && item?.createdOn !== ""
          ? format(parseISO(item?.createdOn), 'MMM dd, yyyy hh:mm a')
          : 'N/A',
    source: item.projectRequestName || 'N/A',
  }));
  const FilteredfileData = transformedProjectRequests.filter((item) => item.downloadFilePath !== "" && item.downloadFilePath !== null)?.toReversed();
  const fileData = FilteredfileData.filter((item: any) =>
    !selectedFilesRowsIds.has(item.projectRequestSentId),
  );

  const filteredData = useMemo(() => {
    return searchQuery.trim().length !== 0
      ? fileData?.filter(
        (item: any) =>
          item.downloadFilePath.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.downloadFilePath?.split('/')?.pop().toLowerCase().includes(searchQuery.toLowerCase()),
      )
      : fileData;
  }, [searchQuery, fileData]);

  const fileColumns = [
    {
      id: 'fileName',
      header: 'File Name',
      width: { type: 'auto', minWidth: 400 },
      accessor: 'fileName',
      columnType: 'string' as ColumnType
    },
    {
      id: 'fileType',
      header: 'File Type',
      accessor: 'fileType',
      width: { type: 'auto', minWidth: 200 },
      columnType: 'string' as ColumnType
    },
    {
      id: 'createdby',
      header: 'Created by',
      accessor: 'createdBy',
      width: { type: 'auto', minWidth: 200 },
      columnType: 'string' as ColumnType
    },
    {
      id: 'modifiedOn',
      header: 'Updated Date',
      accessor: 'createdOn',
      width: { type: 'auto', minWidth: 200 }
    },
    {
      id: 'source',
      header: 'Source',
      accessor: 'projectRequestName',
      width: { type: 'auto', minWidth: 200 },
      columnType: 'string' as ColumnType,
    },
        {
          id: 'actions',
          header: 'Actions',
          width: { type: 'auto', minWidth: 300 },
          accessor: 'downloadFilePath',
          cell: ({ rowData }: any) => (
            <DataTableV2.DefaultCell>
              <Flex justifyContent="flex-start">
                <Tooltip text="Preview">
                  <Button onClick={() => getFileForDocViewer(rowData)}>
                    <Button.Prefix>
                      <DescriptionIcon />
                    </Button.Prefix>
                  </Button>
                </Tooltip>
              </Flex>
            </DataTableV2.DefaultCell>
          ),
        },
  ];


  const handleFileChange = async (event: any) => {
    const files = Array.from(event.target.files);

    if (files.length > 0) {

      try {
        await uploadFiles(files);
      } catch (error) {
        LoggingService.logError({
          MethodName: 'PackageFilesView.handleFileChange',
          Message: 'Error uploading files',
          AdditionalData: error
        });
        console.error('Error uploading files:', error);
      }
    }
  };

  const convertFilesToBase64 = async (files: File[]): Promise<{ name: string; size: number; type: string; base64String: string }[]> => {
    return Promise.all(
      files.map((file) => {
        return new Promise<{ name: string; size: number; type: string; base64String: string }>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              name: file.name,
              size: file.size,
              type: file.type,
              base64String: reader.result as string,
            });
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      })
    );
  };

  const uploadFiles = async (files: any) => {
    if (files.length === 0) {
      ShowErrorNotification('No files selected', 'Please select files to upload.');
      return;
    }

    setLoading(true);

    try {
      const filesData = await convertFilesToBase64(files);

      const requestDetails = filesData.map((file) => ({
        label: 'Internal staff files',
        documentTypeAutoId: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeAutoId,
        documentTypeName: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeName,
        projectRequestId: 0,
        ProjectId,
        tenantId,
        instructions: 'Internal staff files',
        attachedFiles: [
          {
            name: file.name,
            size: file.size,
            type: file.type,
            base64String: file.base64String,
            projectRequestDetailsFilesId: 0,
          },
        ],
      }));

      const payload = {
        projectRequestId: 0,
        requestName: 'Internal staff files',
        requestDescription: 'Internal staff files',
        reminderId: 128,
        documentTypeAutoId: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeAutoId,
        documentTypeName: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeName,
        requestDetails: requestDetails,
        document: [],
        requestSendTo: [
          {
            sendTo: user?.email,
            sendCC: '',
            status: tenantRequestStatusList?.filter((item) => item.statusDescription === "Approved")[0]?.statusAutoId,
            label: 'Internal staff files',
            instructions: 'Internal staff files',
            // peopleId: peopleId,
            documentTypeAutoId: (documentTypes?.filter((item) => item.documentTypeName === "Project Documents")[0])?.documentTypeAutoId,
          },
        ],
        tenantId,
        ProjectId,
        requestUpdateComments: '',
        status: tenantRequestStatusList?.filter((item) => item.statusDescription === "Approved")[0]?.statusAutoId,
      };

      const response: any = await ProjectService.postprojectFiles(payload, tenantId, Number(ProjectId));

      if (response.code === '500') {
        ShowErrorNotification('Upload Failed', response.message || 'An error occurred during upload.');
      } else {
        ShowSuccessNotification('Files Uploaded');
        refetchProjectRequests();
      }
    } catch (error) {
      LoggingService.logError({
        MethodName: 'PackageFilesView.uploadFiles',
        Message: 'Error uploading files',
        AdditionalData: error
      });
      ShowErrorNotification('Upload Error');
    } finally {
      setLoading(false);
    }
  };

  const mimeTypes: {
    pdf: string;
    jpg: string;
    jpeg: string;
    png: string;
    doc: string;
    docx: string;
    pptx: string;
    xlsx: string;
  } = {
    pdf: 'application/pdf',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  const getMimeType = (fileExtension: string): string | undefined => {
    return mimeTypes[fileExtension as keyof typeof mimeTypes];
  };

  const supportedExtensions = ['txt', 'pdf', 'png', 'jpg', 'sql', 'htm', 'json', 'csv', 'docx', 'jpeg', 'doc', 'xls', 'xlsx'];

  const handleDocxPreview = async (rowInfo: any, base64File: string, fileExtension: string) => {
    try {

      const fileName = rowInfo.downloadFilePath.split('/').pop();
      const contentType = getMimeType(fileExtension) || '';

      const result = await PackageService.uploadDocxPreview(base64File, fileName, contentType) as any
      setPreviewFile({
        uri: result.downloadUrl,
        fileName: fileName,
        rowData: rowInfo,
        fileType: fileExtension,
      });
    } catch (error) {
      LoggingService.logError({
        MethodName: 'PackageFilesView.handleDocxPreview',
        Message: 'Error generating DOCX preview',
        AdditionalData: error
      });
      console.error("Error generating DOCX preview:", error);
      ShowErrorNotification("Error generating preview for DOCX file.");
    }
  };


  const getFileForDocViewer = async (rowInfo: any) => {
    try {
      setIsLoading(true);
      const fileExtension = rowInfo?.downloadFilePath?.split('.')?.pop()?.toLowerCase();

      if (!supportedExtensions.includes(fileExtension || '')) {
        setPreviewFile({
          fileName: rowInfo?.downloadFilePath?.split('/')?.pop(),
          unsupported: true,
          fileType: fileExtension,
          rowData: rowInfo
        });
        return;
      }

      const downloadFilePath = rowInfo?.downloadFilePath?.includes('uploads/')
        ? rowInfo?.downloadFilePath?.split('uploads/')[1]
        : rowInfo?.downloadFilePath?.includes('/requestsAttachments')
          ? rowInfo?.downloadFilePath?.split('/requestsAttachments')[1]
          : null;

      if (!downloadFilePath) {
        console.error("Unable to determine file path for:", rowInfo.downloadFilePath);
        alert("Invalid file path. Unable to preview the file.");
        return;
      }

      const base64File: any = await ProjectService.getUploadFileDataForStaff(rowInfo.downloadFilePath, tenantId);

      if (!base64File) {
        ShowErrorNotification("File data could not be retrieved.");
        return;
      }

      if (fileExtension === 'docx' || fileExtension === 'doc' || fileExtension === 'xls' || fileExtension === 'xlsx') {
        await handleDocxPreview(rowInfo, base64File, fileExtension);
      } else {

        const mimeType = getMimeType(fileExtension || '');
        const doc = {
          uri: `data:${mimeType};base64,${base64File}`,
          fileName: rowInfo?.downloadFilePath?.split('/')?.pop(),
          rowData: rowInfo,
          fileType: fileExtension
        };

        setPreviewFile(doc);
      }
    } catch (error) {
      LoggingService.logError({
        MethodName: 'PackageFilesView.getFileForDocViewer',
        Message: 'Error previewing file',
        AdditionalData: error
      });
      console.error("Error in getFileForDocViewer:", error);
      ShowErrorNotification("An error occurred while trying to preview the file.");
    } finally {
      setIsLoading(false);
    }
  };


  // eslint-disable-next-line
  const downloadFileClick = function (rowInfo: any) {
    try {
      const downloadFilePath =
        rowInfo?.downloadFilePath?.includes('uploads/')
          ? rowInfo?.downloadFilePath?.split('uploads/')[1]
          : rowInfo?.downloadFilePath?.includes('/requestsAttachments')
            ? rowInfo?.downloadFilePath?.split('/requestsAttachments')[1]
            : null;

      if (!downloadFilePath) {
        console.error("Unable to determine file path for:", rowInfo.downloadFilePath);
        alert("Invalid file path. Unable to download the file.");
        return;
      }

      const downloadUrl = ProjectService.getDownloadFileHeader(downloadFilePath, tenantId);
      window.open(downloadUrl, "_blank");
    } catch (error) {
      LoggingService.logError({
        MethodName: 'PackageFilesView.downloadFileClick',
        Message: 'Error downloading file',
        AdditionalData: error
      });
      console.error("Error in downloadFileClick:", error);
      alert("An error occurred while trying to download the file.");
    }
  };

  const myRowSelectionChangedListener = (selectedRowsData: any) => {
    const selectedIndices = Object.keys(selectedRowsData)
      .filter((key) => selectedRowsData[key])
      .map((key) => parseInt(key, 10));

    const selectedRows = selectedIndices.map((index) => filteredData[index]);

    // console.log(selectedRows);

    handleSelectedRows(
      selectedRows.map((item: any) => ({
        ...item,
        fileName: item.downloadFilePath?.split('/')?.pop() || 'N/A',
      }))
    );
  };



  return (
    <>
      {/* <FileRender /> */}
      <LoadingStateComponent loading={isLoading} />
      <Flex flexDirection='column' style={{ overflow: 'auto' }}>

        <>
          <input
            ref={fileInputRef}
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </>

        {previewFile && (
          <Modal
            show={!!previewFile}
            onDismiss={() => setPreviewFile(null)}
            title={previewFile.fileName}
            size="large"
            style={{ padding: 0, margin: 0 }}
          >
            <FilePreview previewFile={previewFile} tenantId={tenantId} />
          </Modal>
        )}
        <FilterBar onFilterChange={() => { }}>
          <FilterBar.Item name={'search'} label={''}>
            <TextInput placeholder={'Search'} onChange={setSearchQuery} value={searchQuery} />
          </FilterBar.Item>
          <FilterBar.Item name={'selectedType'} label={''}>
            <Button></Button>
          </FilterBar.Item>
        </FilterBar>

        {/* {selectedFiles.length > 0 && (
          <Surface style={{ padding: '16px' }}>
            <Text style={{ fontWeight: 'bold', marginBottom: '8px' }}>Selected Files:</Text>
            <List style={{ marginBottom: '16px' }}>
              {selectedFiles.map((file, index) => (
                <Text key={index} style={{ padding: '4px 0' }}>{file.name}</Text>
              ))}
            </List>
            <Flex justifyContent="flex-end">
              <Button variant="accent" onClick={uploadFiles} style={{ width: '200px' }}>
                Upload Files
              </Button>
            </Flex>
          </Surface>
        )} */}
        <Flex height={400}>
          <DataTableV2
            columns={fileColumns as any}
            data={filteredData}
            sortable
            variant={{ rowDensity: 'comfortable', rowSeparation: 'none', verticalDividers: false, contained: false }}
            resizable
            fullWidth
            selectableRows
            selectedRows={selectedRows}
            onRowSelectionChange={myRowSelectionChangedListener}
          />
        </Flex>
        <Modal
          show={renameModalOpen}
          onDismiss={() => setRenameModalOpen(false)}
          title={"Rename '" + fileToRename?.downloadFilePath?.split('/')?.pop() + "' file"}
          size={'small'}
        >
          <Flex flexDirection="column" columnGap={0}>
            <TextInput
              type="text"
              value={newFileName}
              onChange={(e) => setNewFileName(e)}
              placeholder="Enter new file name"
              style={{ margin: '1rem 0', padding: '0.5rem', width: '100%' }}

            />
            <Flex flexDirection='row' justifyContent='flex-end' rowGap={2}>
              <Button width='80px' variant='default' onClick={() => setRenameModalOpen(false)}>
                Cancel
              </Button>
              <Button width='80px' variant='accent' color='primary' onClick={handleRenameConfirm}>
                Confirm
              </Button>
            </Flex>
          </Flex>
        </Modal>
      </Flex>
    </>
  );
};

