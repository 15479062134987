import axios from 'axios';
import { getAuthHeader, getBaseURL, handleApiError } from './AxiosAuthService';

export const CommentsService = {
  // Fetch comments by project ID
  getCommentsByProjectId: async <T>(projectId: string, tenantId: number): Promise<T> => {
    try {
      const response = await axios.get(
        `${getBaseURL()}/comments/${projectId}?tenantId=${tenantId}`,
        getAuthHeader()
      );
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },


  getAssetTypes: async <T>(tenantId: number): Promise<T> => {
    try {
      const response = await axios.get(
        `${getBaseURL()}/comments/assetTypesByTenantId?tenantId=${tenantId}`,
        getAuthHeader()
      );
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },

  
  // Fetch comments by asset type common ID
  getCommentsByAssetTypeId: async <T>(assetTypeAutoId: number, assetTypeCommonId: number, tenantId: number): Promise<T> => {
    try {
      const response = await axios.get(
        `${getBaseURL()}/comments/assetTypeAutoId/${assetTypeAutoId}/assetTypeCommonId/${assetTypeCommonId}?tenantId=${tenantId}`,
        getAuthHeader()
      );
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },

  // Save a new comment
  saveComment: async <T>(comment: any, tenantId: number): Promise<T> => {
    try {
      const response = await axios.post(
        `${getBaseURL()}/comments/saveComment?tenantId=${tenantId}`,
        comment,
        getAuthHeader()
      );
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },

  // Update an existing comment
  updateComment: async <T>(comment: any, tenantId: number): Promise<T> => {
    try {
      const response = await axios.put(
        `${getBaseURL()}/comments/updateComment?tenantId=${tenantId}`,
        comment,
        getAuthHeader()
      );
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },

  // Delete a comment by its ID
  deleteComment: async <T>(commentId: string, tenantId: number): Promise<T> => {
    try {
      const response = await axios.delete(
        `${getBaseURL()}/comments/deleteComment/${commentId}?tenantId=${tenantId}`,
        getAuthHeader()
      );
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  }
};

// Extend this service further for any additional endpoints or asset types as needed.
