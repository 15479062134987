import { useState } from "react";
import { NotesDetails } from "../../../types/Notes";
import { ShowErrorNotification, ShowSuccessNotification } from "../../../utils/Notifications";
import { NotesService } from "../../../services/NotesService";
import { Flex } from "@dynatrace/strato-components/layouts";
import { Tooltip } from "@dynatrace/strato-components-preview/overlays";
import { LoadingStateComponent } from "../../../components/LoadingStateComponent";
import { Button } from "@dynatrace/strato-components/buttons";
import { DeleteIcon, EditIcon, SaveIcon } from "@dynatrace/strato-icons";
import { TextArea } from "@dynatrace/strato-components-preview/forms";
import { Surface } from "@dynatrace/strato-components/layouts";
import { Avatar } from "@dynatrace/strato-components-preview/content";
import { Text } from "@dynatrace/strato-components/typography";
import { Colors } from "@dynatrace/strato-design-tokens";
import { format, parseISO } from "date-fns";
import { useAppInfo } from "../../../contexts/AppContext";
import { LoggingService } from "../../../services/LoggingService";

export interface NotesProps {
    projectId: number;
    notes: NotesDetails[];
    refetch: () => void;
}

export const Notes = (props: NotesProps) => {
  let { projectId, notes, refetch } = props;

  const [addNewNotes, setAddNewNotes] = useState('');

  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editDescription, setEditDescription] = useState('');

  const [loading, setloading] = useState(false);
  const [expandedNoteIndex, setExpandedNoteIndex] = useState<number | null>(null);

  const {user, userId, tenantId} = useAppInfo();
  
  const handleEdit = async (projectNotes: NotesDetails, index: number) => {
    setEditIndex(index);
    setEditDescription(notes[index].notes);
  };

  const truncateNotesText = (text: string, index: number) => {
    const maxLength = 100; // Set your desired truncation length
    if (text.length > maxLength && expandedNoteIndex !== index) {
      return (
        <>
          {text.substring(0, maxLength)}...
          <Button
            variant='default'
            color='neutral'
            style={{ fontSize: '11px' }}
            onClick={() => handleExpandClick(index)}
          >
            Expand
          </Button>
        </>
      );
    } else {
      return (
        <>
          {text}
          {text.length > maxLength && (
            <Button
              variant='default'
              color='neutral'
              style={{ fontSize: '11px' }}
              onClick={() => handleExpandClick(index)}
            >
              Collapse
            </Button>
          )}
        </>
      );
    }
  };

  const handleExpandClick = (index: number) => {
    if (expandedNoteIndex === index) {
      setExpandedNoteIndex(null); // Collapse if already expanded
    } else {
      setExpandedNoteIndex(index); // Expand the clicked note
    }
  };
  const handleSave = async (projectNotes: NotesDetails, index: number) => {
    try {
      setloading(true);
      const updatedNotes = [...notes];
      updatedNotes[index].notes = editDescription;
      projectNotes.notes = editDescription;
      const data: any = await NotesService.updateStaffNotes<NotesDetails>(projectNotes, tenantId);
      if (data) {
        ShowSuccessNotification('Note saved successfully');
        setEditIndex(null);
      }
      setloading(false);
    } catch (error) {
      LoggingService.logError({
        MethodName: 'Notes.handleSave',
        Message: 'Error editing the note',
        AdditionalData: error
      });
      ShowErrorNotification('Error occurred while editing the note', error);
      setloading(false);
    }
  };

  const handleNotesIndexCancel = () => {
    setEditIndex(null);
  };

  const handlenewNotesCancel = () => {
    setAddNewNotes('');
  };

  const handleDelete = async (projectNotesId: any) => {
    try {
      setloading(true);
      await NotesService.deleteStaffNotes<NotesDetails>(projectNotesId, tenantId, Number(projectId));
      ShowSuccessNotification('Note deleted successfully');
      refetch();
      setloading(false);
    } catch (error) {
      LoggingService.logError({
        MethodName: 'Notes.handleDelete',
        Message: 'Error deleting the note',
        AdditionalData: error
      });
      ShowErrorNotification('Error occurred while deleting the note', error);
      setloading(false);
    }
  };

  const AddNewNotesClick = async () => {
    const requestBody: NotesDetails = {
      projectNotesId: 0,
      projectId: projectId,
      tenantId: tenantId,
      notes: addNewNotes,
      createdByUserId: userId ?? '',
      createdOn: new Date().toISOString(),
      createdByName: user?.firstName + ' ' + user?.lastName,
    };
    try {
      const data: any = await NotesService.createStaffNotes<NotesDetails>(requestBody, tenantId);
      ShowSuccessNotification('Notes added successfully');
      requestBody.projectNotesId = data?.projectNotesId;
      notes = [requestBody, ...notes];
      setAddNewNotes('');
      refetch();
    } catch (error) {
      LoggingService.logError({
        MethodName: 'Notes.AddNewNotesClick',
        Message: 'Error adding notes',
        AdditionalData: error
      });
      ShowErrorNotification('Error occurred while adding notes', error);
    } finally {
    }
  };

  return (
    <Flex flexDirection='column'>
      {loading && <LoadingStateComponent loading />}
      <Flex flexDirection='column' padding={0} gap={2}>
        <Flex flexDirection='row' alignItems='flex-end'>
          <Tooltip text={'Cancel Note'}>
            <Button onClick={handlenewNotesCancel} variant='default' color='neutral' disabled={addNewNotes === ''}>
              Discard
            </Button>
          </Tooltip>
          <Tooltip text={'Save Note'}>
            <Button
              onClick={AddNewNotesClick}
              variant='default'
              color='neutral'
              style={{ marginRight: '8px' }}
              disabled={addNewNotes === ''}
            >
              <Button.Prefix>
                <SaveIcon />
              </Button.Prefix>
              Save
            </Button>
          </Tooltip>
        </Flex>
        <TextArea
          width={'100%'}
          rows={5}
          placeholder={'Add a new note'}
          value={addNewNotes}
          onChange={(e) => setAddNewNotes(e)}
          style={{ marginBottom: '8px'}}
        />
      </Flex>
      <Surface style={{ overflowY: 'auto' }}>
        {notes?.map((person, index) => (
          <Surface key={index}>
            <Flex key={index} padding={2} flexDirection='column' gap={2}>
              <Flex flexDirection='row' justifyContent='space-between' alignItems='center'>
                <Flex flexDirection='row'>
                  <Avatar size={'default'} style={{}} abbreviation={person?.createdByName[0]?.toUpperCase()} />
                  <Flex flexDirection='column' gap={0}>
                    <Text style={{ fontSize: '14px' }} color={Colors.Text.Neutral.Default}>
                      <b>{person?.createdByName}</b>
                    </Text>
                    <Text color={Colors.Text.Neutral.Disabled}>
                      {person?.createdOn ? format(parseISO(person?.createdOn), 'MMM dd, yyyy hh:mm a') : 'N/A'}                    
                    </Text>
                  </Flex>
                </Flex>
                {editIndex === index ? (
                  <Flex flexDirection='row' alignItems='flex-end'>
                    <Button
                      onClick={() => handleSave(person, index)}
                      variant='default'
                      color='neutral'
                      style={{ marginRight: '8px' }}
                    >
                      Save
                    </Button>
                    <Button onClick={handleNotesIndexCancel} variant='default' color='neutral'>
                      Cancel
                    </Button>
                  </Flex>
                ) : (
                  <Flex flexDirection='row'>
                    <Tooltip text={'Edit note'}>
                      <Button
                        onClick={() => handleEdit(person, index)}
                        variant='default'
                        color='neutral'
                        style={{ marginRight: '8px' }}
                      >
                        <Button.Prefix>
                          <EditIcon />
                        </Button.Prefix>
                      </Button>
                    </Tooltip>

                    <Tooltip text={'Delete Note'}>
                      <Button onClick={() => handleDelete(person.projectNotesId)} variant='default' color='neutral'>
                        <Button.Prefix>
                          <DeleteIcon />
                        </Button.Prefix>
                      </Button>
                    </Tooltip>
                  </Flex>
                )}
              </Flex>
              {editIndex === index ? (
                <TextArea
                  value={editDescription}
                  width={'100%'}
                  rows={5}
                  onChange={(e) => setEditDescription(e)}
                  style={{ marginTop: '8px' }}
                />
              ) : (
                <Flex flexDirection='column'>
                  <Text color={Colors.Text.Neutral.Default}>
                    {truncateNotesText(person.notes, index)}
                    <Text></Text>
                  </Text>
                </Flex>
              )}
            </Flex>
          </Surface>
        ))}
      </Surface>
    </Flex>
  );
};
