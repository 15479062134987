import { format, parseISO } from 'date-fns';
import { Chip, ColumnType, DataTableV2, TextEllipsis, Tooltip } from '@dynatrace/strato-components-preview';
import { Colors } from '@dynatrace/strato-design-tokens';
import { Flex } from '@dynatrace/strato-components';
import { LoggingService } from '../../../../services/LoggingService';

// table main columns
export const columns = (onProjectRequestClick: (requestRow: any) => void) => [
  {
    id: 'projectRequestId',
    header: 'Request Id',
    accessor: 'projectRequestId',
    width: { type:'auto', minWidth: 200},
    columnType: 'number' as ColumnType,
    cell: ({ rowData, value }: any) => {
      return (<DataTableV2.DefaultCell>
        <TextEllipsis
          onClick={() => {
            onProjectRequestClick(rowData);
          }}
        >{value}
        </TextEllipsis></DataTableV2.DefaultCell>)
    }
  },
  {
    id: 'projectRequestName',
    header: 'Request name',
    accessor: 'projectRequestName',
    width: { type:'auto', minWidth: 300},
    columnType: 'string' as ColumnType,
    cell: ({ rowData }: any) => {
      return (
        <DataTableV2.DefaultCell>
          <Tooltip placement="bottom" text={rowData.projectRequestName}>
            {rowData.statusName === "inprogress/draft" ? (
              
              <Flex onClick={() => {
                onProjectRequestClick(rowData);
              }}>
                
              {rowData.statusName === "inprogress/draft"? rowData.projectRequestName : rowData.projectRequestName}
              {rowData.statusName === "inprogress/draft" && <Chip variant='emphasized' style={{ padding: '0px' }} color='success'>Draft</Chip>} 
              </Flex>
            ):(
              <TextEllipsis
              onClick={() => {
                onProjectRequestClick(rowData);
              }}
              style={{
                cursor: 'pointer',
              }}
              truncationMode="end"
            >
              {rowData.projectRequestName}
            </TextEllipsis>
            )}
          
            
          </Tooltip>
        </DataTableV2.DefaultCell>
      );
    },
  },
  {
    id: 'createdOn',
    header: 'Created on',
    accessor: 'createdOn',
    columnType: 'date' as ColumnType,
    width: { type:'auto', minWidth: 200},
    cell: ({ rowData, value }: any) => {
      try {
        const date = parseISO(value);
        return (<DataTableV2.DefaultCell>
          <TextEllipsis
            onClick={() => {
              onProjectRequestClick(rowData);
            }}
            truncationMode="end"
            style={{
              cursor: 'pointer',
            }}
          >{format(date, 'MMM dd, yyyy HH:mm')}
          </TextEllipsis></DataTableV2.DefaultCell>);
      } catch (error) {
        LoggingService.logError({"MethodName":"table-definitions.columns", "Message":"Invalid Date error", "AdditionalData":error});
        return (
          <DataTableV2.DefaultCell>{'Invalid date'}</DataTableV2.DefaultCell>);
      }
    },
  },
  {
    id: 'assignedCount',
    header: 'Receipients count',
    accessor: 'assignedCount',
    width: { type:'auto', minWidth: 200},
    columnType: 'number' as ColumnType,
    cell: ({ row, value }: any) => {
      const rowData = row;
      return (<DataTableV2.DefaultCell>
        <TextEllipsis
          onClick={() => {
            onProjectRequestClick(rowData);
          }}
          truncationMode="end"
          style={{
            cursor: 'pointer',
          }}
        >{value}
        </TextEllipsis></DataTableV2.DefaultCell>)
    }
  },
];

export const requestFilesColumns = (onProjectRequestFolderClick: (requestRow: any) => void) => [
  {
    id: 'projectRequestId',
    header: 'Request Id',
    accessor: 'projectRequestId',
    autoWidth: true,
    columnType: 'number' as ColumnType,
  },
  {
    id: 'projectRequestName',
    header: 'Request name',
    accessor: 'projectRequestName',
    width: 400,
    columnType: 'string' as ColumnType,
    cancelIdleCallbackell: ({ row }: any) => {
      const rowData = row.original;
      return (
        <DataTableV2.DefaultCell>
          <Tooltip placement="bottom" text={rowData.projectRequestName}>
            <TextEllipsis
              onClick={() => {
                onProjectRequestFolderClick(rowData);
              }}
              truncationMode="end"
              style={{
                color: Colors.Text.Neutral.Default,
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }}
            >
              {rowData.projectRequestName}
            </TextEllipsis>
          </Tooltip>
        </DataTableV2.DefaultCell>
      );
    },
  },
  {
    id: 'createdOn',
    header: 'Created on',
    accessor: 'createdOn',
    columnType: 'date' as ColumnType,
    width: 200,
    cell: ({ value }: any) => {
      try {
        const date = parseISO(value);
        return format(date, 'MMM dd, yyyy HH:mm');
      } catch (error) {
        LoggingService.logError({"MethodName":"table-definitions.requestFilesColumns", "Message":"Invalid date", "AdditionalData":error});
        return 'Invalid date';
      }
    },
  },
  // {
  //   id: 'assignedCount',
  //   header: 'Receipients count',
  //   accessor: 'assignedCount',
  //   autoWidth: true,
  //   columnType: 'number' as ColumnType,
  // },
];

// table sub-row columns
export const rowColumns = [
  {
    id: 'assignedto',
    header: 'Assigned to',
    accessor: 'assignedto',
    minWidth: 300,
    columnType: 'text',
  },
  {
    id: 'completed',
    header: 'Completed',
    accessor: 'completed',
    width: 100,
    columnType: 'number',
  },
  {
    id: 'inProgress',
    header: 'In-complete',
    accessor: 'inProgress',
    width: 100,
    columnType: 'number',
  },
];

export const rowDocumentsColumns = [
  {
    id: 'label',
    header: 'File name',
    accessor: 'label',
    width: { type:'auto', minWidth: 250},
  },
  // {
  //   id: 'instructions',
  //   header: 'Instructions',
  //   accessor: 'instructions',
  //   width: { type:'auto', minWidth: 350},
  // },
  {
    id: 'statusName',
    header: 'Status',
    accessor: 'statusName',
    width: { type:'auto', minWidth: 200},
  },
];
