import { Flex } from "@dynatrace/strato-components";
import {
  DateTimePicker,
  FormField,
  Label,
  SelectV2,
  TextInput,
} from "@dynatrace/strato-components-preview";
import { Controller } from "react-hook-form";
import { extendedProfileAttribute } from "../../types/Person";
import { formatPhoneNumberForDisplay } from "../../utils/Utilities";

export interface IProfileDetailsProps {
  control: any;
  extendedProfile: extendedProfileAttribute[] | undefined;
}

export const ProfileDetails = (props: IProfileDetailsProps) => {
  const {
    control,
    extendedProfile
  } = props

  return (
    <Flex flexDirection='column' width={512} minWidth={512} gap={16}>
      <Controller
        name='firstName'
        control={control}
        rules={{
          required: {
            value: true,
            message: 'First name is required.',
          },
          maxLength: {
            value: 200,
            message: 'Please enter a valid first name.',
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <FormField required>
            <Label>First name</Label>
            <TextInput
              placeholder='Enter first name'
              controlState={{
                state: error ? 'error' : 'valid',
                hint: error?.message,
              }}
              {...field}
            />
          </FormField>
        )}
      />
      <Controller
        name="middleName"
        control={control}
        rules={{
          maxLength: {
            value: 250,
            message: "Please enter a valid last name.",
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <FormField>
            <Label>Middle name</Label>
            <TextInput
              placeholder="Enter middle name"
              controlState={{
                state: error ? "error" : "valid",
                hint: error?.message,
              }}
              {...field}
            />
          </FormField>
        )}
      />
      <Controller
        name="lastName"
        control={control}
        rules={{
          required: {
            value: true,
            message: "Last name is required.",
          },
          maxLength: {
            value: 200,
            message: "Please enter a valid last name.",
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <FormField required>
            <Label>Last name</Label>
            <TextInput
              placeholder="Enter last name"
              controlState={{
                state: error ? "error" : "valid",
                hint: error?.message,
              }}
              {...field}
            />
          </FormField>
        )}
      />

      <Controller
        name="email"
        control={control}
        rules={{
          validate: (value) =>
            /\S+@\S+\.\S+/.test(value) || "Please enter a valid email address.",
        }}
        render={({ field, fieldState: { error } }) => (
          <FormField required>
            <Label>Email</Label>
            <TextInput
              placeholder="Enter email address"
              controlState={{
                state: error ? "error" : "valid",
                hint: error?.message,
              }}
              {...field}
            />
          </FormField>
        )}
      />
      <Controller
        name="mobile"
        control={control}
        rules={{
          required: {
            value: true,
            message: "Mobile number is required.",
          },
          validate: (value: string) => {
            const cleaned = value.replace(/\D/g, '');
            return cleaned.length === 10 || 'Please enter a valid 10-digit phone number.';
          }
        }}
        render={({ field, fieldState: { error } }) => (
          <FormField required>
            <Label>Primary mobile number</Label>
            <TextInput
              placeholder="Enter primary mobile number"
              value={formatPhoneNumberForDisplay(field.value || "")}
              controlState={{
                state: error ? "error" : "valid",
                hint: error?.message,
              }}
              onChange={(value: string, event: React.ChangeEvent<HTMLInputElement>) => {
                const cleaned = value.replace(/\D/g, '').slice(0, 10);
                field.onChange(cleaned);
              }}
            />
          </FormField>
        )}
      />

      {control &&
        extendedProfile?.map((attribute, index) =>
          attribute.attributeSection === "Profile" ? (
            <Controller
              rules={
                attribute.attributeType === 'email'
                  ? {
                    validate: (value) =>
                      /\S+@\S+\.\S+/.test(value) || 'Please enter a valid email address.',
                  }
                  : attribute.attributeType === 'number'
                    ? {
                      validate: (value: string) => {
                        const cleaned = value.replace(/\D/g, '');
                        return cleaned.length === 10 || 'Please enter a valid 10-digit phone number.';
                      }
                    }
                    : {}
              }
              key={attribute.attributeId}
              control={control}
              name={`ExtendedProfileFields.${index}.attributeValue`}
              render={({ field, fieldState: { error } }) => (
                <FormField>
                  <Label>{attribute.attributeName}</Label>
                  <Flex onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                    if (event.key === 'Enter') {
                      event.preventDefault(); // Prevent form submission
                    }
                  }}>
                    {attribute.attributeType === 'select' ? (
                      <SelectV2
                        onChange={(selectedValue) => field.onChange(selectedValue)}
                      >
                        <SelectV2.Content>
                          {attribute.attributeOptions?.map((option) => (
                            <SelectV2.Option key={option.attributeOptionAutoId} value={option.attributeOptionAutoId.toString()}>
                              {option.attributeOptionName}
                            </SelectV2.Option>
                          ))}
                        </SelectV2.Content>
                      </SelectV2>
                    ) : attribute.attributeType === 'dateTime' ? (
                      <DateTimePicker type="time" {...field} />
                    ) : attribute.attributeType === 'number' ? (
                      <TextInput
                        placeholder={`Enter ${attribute.attributeName}`}
                        controlState={{
                          state: error ? 'error' : 'valid',
                          hint: error?.message,
                        }}
                        value={formatPhoneNumberForDisplay(field.value || '')}
                        onChange={(value: string, event: React.ChangeEvent<HTMLInputElement>) => {
                          const cleaned = value.replace(/\D/g, '').slice(0, 10);
                          field.onChange(cleaned);
                        }}
                      />
                    ) : (
                      <TextInput
                        placeholder={`Enter ${attribute.attributeName}`}
                        controlState={{
                          state: error ? 'error' : 'valid',
                          hint: error?.message,
                        }}
                        {...field}
                      />
                    )}
                  </Flex>
                </FormField>
              )}
            />
          ) : null
        )}
    </Flex>
  );
};
