import {
  DataTableV2,
  FilterBar,
  Page,
  TableColumn,
  TextInput,
  TitleBar,
  Tooltip,
} from '@dynatrace/strato-components-preview';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { TextEllipsis } from '@dynatrace/strato-components/typography';
import {useMemo, useState } from 'react';
import { PlusIcon } from '@dynatrace/strato-icons';
import { LenderSheet } from './LenderSheet';
import { ItemInfo } from '../../types/ListItemInfo';
import { configRoutes } from '../../configs/constants';
import { useLendersByTenantId } from '../../hooks/use-minerva-data';
import { ShowErrorNotification } from '../../utils/Notifications';
import { useAppInfo } from '../../contexts/AppContext';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';

export interface LendersProps {}

export const LendersPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [itemDetailsVisible, setItemDetailsVisible] = useState<ItemInfo | undefined>();

  const { isAppLoading, tenantId } = useAppInfo();

  const columns: TableColumn[] = useMemo(
    () => [
      {
        id: 'name',
        header: 'Lender name',
        accessor: 'name',
        width: 300,
        cell: ({ rowData, rowIndex}: any) => (
          <DataTableV2.DefaultCell>
            <Tooltip placement='bottom' text={rowData.name}>
              {
                <TextEllipsis
                  onClick={() => {
                    setItemDetailsVisible({
                      id: rowData.lenderID,
                      index: rowIndex,
                      name: rowData.name,
                      visibility: true,
                    });
                  }}
                  truncationMode='end'
                >
                  {rowData.name}
                </TextEllipsis>
              }
            </Tooltip>
          </DataTableV2.DefaultCell>
        ),
      },
      {
        id: 'address',
        header: 'Business address',
        accessor: 'address',
      },
    ],
    [],
  );

  const { isLoading, data, error, refetch } = useLendersByTenantId(tenantId);
  if (error && !isAppLoading) {
    ShowErrorNotification('Error loading projects', error);
  }

  if (itemDetailsVisible?.refreshParent) {
    itemDetailsVisible.refreshParent = false;
    refetch();
  }

  const filteredData = useMemo(() => {
    return searchQuery.trim().length !== 0
      ? data?.filter(
          (item: { contactAddress: string; name: string }) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.contactAddress.toLowerCase().includes(searchQuery.toLowerCase()),
        )
      : data;
  }, [searchQuery, data]);

  return (
    isAppLoading ? <></> : 
    <>
      <TitleBar>
        <TitleBar.Prefix>
          <Page.PanelControlButton target='sidebar' />
        </TitleBar.Prefix>
        <TitleBar.Title>{configRoutes[2].friendlyName}</TitleBar.Title>
        <TitleBar.Subtitle>{configRoutes[2].desc} </TitleBar.Subtitle>
      </TitleBar>

      <Flex flexDirection='column' margin={8} padding={0} gap={8}>
        <Flex flexDirection='row' justifyContent='space-between' alignItems='end' marginBottom={16} marginTop={8}>
          <FilterBar
            onFilterChange={() => {
              //console.log('Filter changed!');
            }}
          >
            <FilterBar.Item name={'search'} label={''}>
              <TextInput placeholder={'Search'} onChange={setSearchQuery} value={searchQuery} />
            </FilterBar.Item>
            <FilterBar.Item name={'selectedType'} label={''}>
              <Button></Button>
            </FilterBar.Item>
          </FilterBar>

          <Button
            onClick={() => {
              //setSelectedEndpoint(null);
              setItemDetailsVisible({ visibility: true });
            }}
            variant='accent'
            color='primary'
            style={{ margin: '0px 0px 0px auto' }}
          >
            <Button.Prefix>
              <PlusIcon />
            </Button.Prefix>
            Lender
          </Button>
        </Flex>
        <Flex flexDirection={'column'}>
          <LoadingStateComponent loading={isLoading} />

          <DataTableV2
            columns={columns as any}
            data={filteredData ?? []}
            sortable
            variant={{ rowDensity: 'comfortable', rowSeparation:'none', verticalDividers: false, contained:false }}
            defaultSortBy={[{ id: 'name', desc: false }]}
            interactiveRows
            onActiveRowChange={(row: any) => {
              if (row !== null) {
                setItemDetailsVisible({
                  id: filteredData?.[+row].lenderID,
                  index: +row,
                  name: filteredData?.[+row].name,
                  visibility: true,
                });
              }
            }}
          ></DataTableV2>
        </Flex>

        {itemDetailsVisible && itemDetailsVisible.visibility && (
          <LenderSheet
            closeDetails={(itemInfo?: ItemInfo) => {
              setItemDetailsVisible(itemInfo);
            }}
            itemInfo={itemDetailsVisible}
            show
          />
        )}
      </Flex>
    </>
  );
};
