import axios, { AxiosError } from 'axios';
import { getAuthHeader, getBaseURL } from './AxiosAuthService'; // Replace with your API endpoint

export const PersonaService = {
  getpersona: async <T>(tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/persona?tenantId=${tenantId}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getPeoplePersona: async <T>(tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/persona/getPeoplePersona/${tenantId}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getProjectPersona: async <T>(tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/persona/getProjectPersona?tenantId=${tenantId}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  createProjectPeopleRelation: async <T>(details: any[], tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.post(`${getBaseURL()}/projectPeopleRelation?tenantId=${tenantId}`, details, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  updateProjectPeopleRelation: async <T>(details: any[], tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.put(`${getBaseURL()}/projectPeopleRelation?tenantId=${tenantId}`, details, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  createClintRelation: async <T>(details: any[], tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.post(`${getBaseURL()}/peopleBusinessRelation?tenantId=${tenantId}`, details, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getclientPersonas: async <T>(businessId: number, tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.get(
        `${getBaseURL()}/peopleBusinessRelation/businessRelation/${businessId}?tenantId=${tenantId}`,
        getAuthHeader(),
      );
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  deleteClientRelation: async <T>(businessId: string, tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.delete(`${getBaseURL()}/peopleBusinessRelation/${businessId}?tenantId=${tenantId}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getPersonaList: async <T>(tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/persona?tenantId=${tenantId}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getPersonaByTenantList: async <T>(tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/tenant/personasByTenant/${tenantId}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
};

const handleApiError = (error: AxiosError) => {
  if (error.response) {
    console.error('API Error Response:', error.response.data);
  } else if (error.request) {
    console.error('API No Response:', error.request);
  } else {
    console.error('API Error:', error.message);
  }
};
