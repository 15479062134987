import { Flex } from "@dynatrace/strato-components";
import { DateTimePicker, FormField, Label, SelectV2, TextInput } from "@dynatrace/strato-components-preview";
import { Controller } from "react-hook-form";
import { extendedProfileAttribute } from "../../types/Person";
import { formatPhoneNumberForDisplay } from "../../utils/Utilities";

export interface ISocialMediaPresenceProps {
  control: any;
  extendedProfile: extendedProfileAttribute[] | undefined;
}

export const SocialMediaPresence = (props: ISocialMediaPresenceProps) => {

  const {
    control,
    extendedProfile
  } = props
  return (
    <Flex flexDirection='column' width={512} minWidth={512} gap={16}>
      {/* <Controller
        name='linkedInProfile'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormField>
            <Label>LinkedIn profile url</Label>
            <TextInput
              placeholder='Enter LinkedIn profile url '
              type='url'
              controlState={{
                state: error ? 'error' : 'valid',
                hint: error?.message,
              }}
              {...field}
            />
          </FormField>
        )}
      /> */}

      {control &&
        extendedProfile?.map((attribute, index) =>
          attribute.attributeSection === 'SocialMedia' ? (
            <Controller
              rules={attribute.attributeType === 'email' ? {
                validate: (value) => /\S+@\S+\.\S+/.test(value) || 'Please enter a valid email address.',
              } : {}}
              key={attribute.attributeId}
              control={control}
              name={`ExtendedProfileFields.${index}.attributeValue`}
              render={({ field, fieldState: { error } }) => (
                <FormField>
                  <Label>{attribute.attributeName}</Label>
                  <Flex onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                    if (event.key === 'Enter') {
                      event.preventDefault(); // Prevent form submission
                    }
                  }}>
                    {attribute.attributeType === 'select' ? (
                      <SelectV2
                        onChange={(selectedValue) => field.onChange(selectedValue)}
                      >
                        <SelectV2.Content>
                          {attribute.attributeOptions?.map((option) => (
                            <SelectV2.Option key={option.attributeOptionAutoId} value={option.attributeOptionAutoId.toString()}>
                              {option.attributeOptionName}
                            </SelectV2.Option>
                          ))}
                        </SelectV2.Content>
                      </SelectV2>
                    ) : attribute.attributeType === 'dateTime' ? (
                      <DateTimePicker type="time" {...field} />
                    ) : attribute.attributeType === 'number' ? (
                      <TextInput
                        placeholder={`Enter ${attribute.attributeName}`}
                        controlState={{
                          state: error ? 'error' : 'valid',
                          hint: error?.message,
                        }}
                        value={formatPhoneNumberForDisplay(field.value || '')}
                        onChange={(value: string, event: React.ChangeEvent<HTMLInputElement>) => {
                          const cleaned = value.replace(/\D/g, '').slice(0, 10);
                          field.onChange(cleaned);
                        }}
                      />
                    ) : (
                      <TextInput
                        placeholder={`Enter ${attribute.attributeName}`}
                        controlState={{
                          state: error ? 'error' : 'valid',
                          hint: error?.message,
                        }}
                        {...field}
                      />
                    )}
                  </Flex>
                </FormField>
              )}
            />
          ) : null
        )}
    </Flex>
  )

}